import e_ScreenSize from '@appfarm/common/enums/e_ScreenSize'
import { e_DrawerType } from '@appfarm/common/enums/e_PropertyTypes'

import { setAccountDialogOpenState } from '#actions/appStateActions'
import { getMainDrawer } from '#selectors/metadataSelectors'

const p_openAccountDialog = ({ dispatch, getState, appController }) =>
	new Promise((resolve) => {
		dispatch(setAccountDialogOpenState(true))

		const state = getState()

		// Close drawer if open and temporary
		const appVariablesDataSource = appController.getAppVariablesDataSource()
		const currentDrawerState = appVariablesDataSource.getDrawerState()

		if (currentDrawerState) {
			const screenSize = appVariablesDataSource.getClientScreenSize()
			const drawer = getMainDrawer(state)
			if (
				drawer &&
				(drawer.drawerType === e_DrawerType.TEMPORARY ||
					((!drawer.drawerType || drawer.drawerType === e_DrawerType.RESPONSIVE) &&
						[e_ScreenSize.EXTRA_SMALL, e_ScreenSize.SMALL].includes(screenSize)))
			)
				appVariablesDataSource.setDrawerState(false)
		}

		resolve()
	})

export default p_openAccountDialog
