import e_GoogleAnalyticsTrackingType from '@appfarm/common/enums/e_GoogleAnalyticsTrackingType'

const trackingId = window.AF_PARAMS && window.AF_PARAMS.googleAnalyticsId

const p_logGoogleAnalyticsEvent = ({ actionNode, contextData, appController, actionNodeLogger }) =>
	new Promise((resolve) => {
		const gtag = window.gtag

		if (!gtag || !trackingId) {
			actionNodeLogger.warning('Google Analytics not configured for this environment')
			return resolve()
		}

		switch (actionNode.trackingType) {
			case e_GoogleAnalyticsTrackingType.PAGEVIEW: {
				const pageTitle = appController.getDataFromDataValue(actionNode.pageTitle, contextData)
				const pagePath = appController.getDataFromDataValue(actionNode.pagePath, contextData)
				gtag('config', trackingId, { page_title: pageTitle, page_path: pagePath })
				actionNodeLogger.debug('Send virtual pageview -> title: ' + pageTitle + ', path: ' + pagePath)
				break
			}

			default: {
				const eventCategory = appController.getDataFromDataValue(actionNode.eventCategory, contextData)
				const eventAction = appController.getDataFromDataValue(actionNode.eventAction, contextData)
				const eventLabel = appController.getDataFromDataValue(actionNode.eventLabel, contextData)
				const eventValue = appController.getDataFromDataValue(actionNode.eventValue, contextData)

				actionNodeLogger.debug(
					'Send event to Google Analytics - action: ' +
						eventAction +
						', category: ' +
						eventCategory +
						', label: ' +
						eventLabel +
						', value: ' +
						eventValue
				)

				gtag('event', eventAction, {
					event_category: eventCategory,
					event_label: eventLabel,
					value: eventValue,
				})
			}
		}

		resolve()
	})

export default p_logGoogleAnalyticsEvent
