import { combineReducers } from 'redux'

import metaData from './metaDataReducer'
import appState from './appStateReducer'
import runtimeState from './runtimeStateReducer'
import authState from './authStateReducer'
import resourceReadyState from './resourceReadyStateReducer'

const rootReducer = combineReducers({
	resourceReadyState,
	metaData,
	appState,
	runtimeState,
	authState,
})

export type State = ReturnType<typeof rootReducer>

export default rootReducer
