import { isUndefined, isNil, isArray, isEqual, sortBy } from 'lodash'

import e_FilterNodeType from '@appfarm/common/enums/e_FilterNodeType'
import e_FilterTargetType from '@appfarm/common/enums/e_FilterTargetType'
import e_FilterTargetSelectionMode from '@appfarm/common/enums/e_FilterTargetSelectionMode'
import e_FilterOperator from '@appfarm/common/enums/e_FilterOperator'
import e_ObjectClassDataType from '@appfarm/common/enums/e_ObjectClassDataType'

import {
	evaluateContains,
	evaluateContainsAll,
	evaluateContainsAllWords,
	evaluateContainsAny,
} from './containsEvaluatorUtils'

const {
	EQUALS,
	NOT_EQUALS,
	LESS_THAN,
	LESS_THAN_OR_EQUAL,
	GREATER_THAN,
	GREATER_THAN_OR_EQUAL,
	EXISTS,
	NOT_EXISTS,
	IN,
	NOT_IN,
	AND,
	OR,
	NOR,
	HAS_VALUE,
	HAS_NO_VALUE,
	CONTAINS,
	CONTAINS_ALL,
	CONTAINS_ALL_WORDS,
	CONTAINS_ANY,
	NOT_CONTAINS,
	NOT_CONTAINS_ALL,
	NOT_CONTAINS_ALL_WORDS,
	NOT_CONTAINS_ANY,
	// NOT,
	ALL,
	SIZE,
} = e_FilterOperator

export let evaluateCondition
// leftValue === propertyValue, rightValue === filterValue
export const twoSideEvaluator = (leftValue, operator, rightValue) => {
	// Handle wierd js quirk
	/* eslint-disable no-fallthrough */
	switch (operator) {
		case LESS_THAN:
		case LESS_THAN_OR_EQUAL:
			if (leftValue === null) return false
		case GREATER_THAN:
		case GREATER_THAN_OR_EQUAL:
			if (rightValue === null) return false
	}

	switch (operator) {
		case EQUALS: {
			// MultiProperties
			if (isArray(leftValue) && isArray(rightValue)) return isEqual(sortBy(leftValue), sortBy(rightValue))

			return leftValue === rightValue
		}
		case NOT_EQUALS: {
			// MultiProperty
			if (isArray(leftValue) && isArray(rightValue)) return !isEqual(sortBy(leftValue), sortBy(rightValue))

			return leftValue !== rightValue
		}
		case LESS_THAN:
			return leftValue < rightValue
		case LESS_THAN_OR_EQUAL:
			return leftValue <= rightValue
		case GREATER_THAN:
			return leftValue > rightValue
		case GREATER_THAN_OR_EQUAL:
			return leftValue >= rightValue
		case ALL:
			return isArray(leftValue) && rightValue.every((subValue) => leftValue.includes(subValue))

		case IN: {
			// MultiProperty
			if (isArray(leftValue)) return rightValue.some((subValue) => leftValue.includes(subValue))

			// Single Property
			return rightValue.includes(leftValue)
		}

		case NOT_IN: {
			if (isArray(leftValue)) return rightValue.every((subValue) => !leftValue.includes(subValue))

			// Single Property
			return !rightValue.includes(leftValue)
		}

		case CONTAINS:
			return evaluateContains(leftValue, rightValue)
		case CONTAINS_ALL:
			return evaluateContainsAll(leftValue, rightValue)
		case CONTAINS_ALL_WORDS:
			return evaluateContainsAllWords(leftValue, rightValue)
		case CONTAINS_ANY:
			return evaluateContainsAny(leftValue, rightValue)

		case NOT_CONTAINS:
			return !evaluateContains(leftValue, rightValue)
		case NOT_CONTAINS_ALL:
			return !evaluateContainsAll(leftValue, rightValue)
		case NOT_CONTAINS_ALL_WORDS:
			return !evaluateContainsAllWords(leftValue, rightValue)
		case NOT_CONTAINS_ANY:
			return !evaluateContainsAny(leftValue, rightValue)

		// should not happen
		case SIZE:
			return leftValue.length === rightValue

			// should not happen
			// case NOT:

		default:
			return false
	}
}

const getValueFromItem = (item, conditionNode, appController) => {
	if (conditionNode.targetValueComponentId)
		return appController.getValueFromValueComponentId(
			item[conditionNode.targetNodeName],
			conditionNode.targetValueComponentId
		)

	return item[conditionNode.targetNodeName]
}

export const evaluateEdgeCondition = ({ appController, conditionNode, contextData }) => {
	/**
	 * Get Left Value
	 */

	const leftValue = appController.getDataFromDataValue(conditionNode.sourceDataValue, contextData)
	/**
	 * Static Operators
	 */
	if (conditionNode.operator === EXISTS) return !isUndefined(leftValue)
	if (conditionNode.operator === NOT_EXISTS) return isUndefined(leftValue)

	if (conditionNode.operator === HAS_VALUE) {
		if (
			isUndefined(leftValue) ||
			leftValue === null ||
			leftValue === '' ||
			(isArray(leftValue) && leftValue.length === 0)
		)
			return false
		return true
	}

	if (conditionNode.operator === HAS_NO_VALUE) {
		if (
			isUndefined(leftValue) ||
			leftValue === null ||
			leftValue === '' ||
			(isArray(leftValue) && leftValue.length === 0)
		)
			return true
		return false
	}

	/**
	 * Get Right Value
	 */
	let rightValue

	if (conditionNode.targetDataValue) {
		// TODO: Diff'e på targetType === DATA_VALUE
		// DataValue -> just get value
		rightValue = appController.getDataFromDataValue(conditionNode.targetDataValue, contextData)
	} else if (conditionNode.isSourceCardinalityMany) {
		// Multi Property
		if (conditionNode.targetType === e_FilterTargetType.CONSTANT) {
			rightValue = conditionNode.targetConstantList || []
		} else {
			// Need to get data explicitly
			const rightDataSource = appController.getDataSource(conditionNode.targetDataSourceId)
			rightValue = rightDataSource.getObjectsBySelectionType({
				selectionType: conditionNode.targetSelectionMode || e_FilterTargetSelectionMode.ALL,
				filterDescriptor: conditionNode.targetFilterDescriptor,
				contextData: contextData,
			})

			rightValue = rightValue
				? rightValue
					.map((item) => getValueFromItem(item, conditionNode, appController))
					.filter((value) => !isNil(value))
				: []
		}
	} else {
		// Single Property
		if (conditionNode.targetType === e_FilterTargetType.CONSTANT) {
			if (conditionNode.operator === IN || conditionNode.operator === NOT_IN) {
				rightValue = conditionNode.targetConstantList || []
			} else {
				rightValue = conditionNode.targetConstant
			}
		} else {
			const rightDataSource = appController.getDataSource(conditionNode.targetDataSourceId)
			if (conditionNode.operator === IN || conditionNode.operator === NOT_IN) {
				rightValue = rightDataSource.getObjectsBySelectionType({
					selectionType: conditionNode.targetSelectionMode || e_FilterTargetSelectionMode.ALL,
					filterDescriptor: conditionNode.targetFilterDescriptor,
					contextData,
				})

				rightValue = rightValue
					? rightValue
						.map((item) => getValueFromItem(item, conditionNode, appController))
						.filter((value) => !isNil(value))
					: []
			} else {
				rightValue = rightDataSource.getSingleValue(contextData, conditionNode.targetNodeName)
				if (conditionNode.targetValueComponentId)
					rightValue = appController.getValueFromValueComponentId(
						rightValue,
						conditionNode.targetValueComponentId
					)

				if (isUndefined(rightValue)) rightValue = false
			}
		}
	}

	if (isArray(rightValue)) {
		if (conditionNode.isTargetPropertyCardinalityMany) {
			const dataBinding = {
				dataSourceId: conditionNode.targetDataSourceId,
				propertyId: conditionNode.targetPropertyId,
			}
			const propertyMetadata = appController.getPropertyMetadata(dataBinding)

			const lookForContextObject =
				propertyMetadata?.dataType === e_ObjectClassDataType.MULTI_REFERENCE &&
				isArray(rightValue) &&
				rightValue.length === 1

			rightValue = rightValue.reduce((result, arrayProperty) => result.concat(arrayProperty), [])

			if (lookForContextObject) {
				const dataSourceId = appController.getDataSourceIdFromDataBindingProperty(dataBinding)
				const contextObjects = contextData[dataSourceId]
				if (contextObjects?.length) {
					const contextObjectId = contextObjects[0]._id
					if (rightValue.includes(contextObjectId)) rightValue = [contextObjectId]
				}
			}
		}

		// To account for context object values
		const isContextValueComparison =
			[EQUALS, NOT_EQUALS].includes(conditionNode.operator) &&
			conditionNode.isSourceCardinalityMany &&
			!isArray(leftValue) &&
			rightValue.length === 1
		if (isContextValueComparison) rightValue = rightValue[0]

		// Remove duplicates if not EQ/NEQ
		if (!(conditionNode.operator === EQUALS || conditionNode.operator === NOT_EQUALS))
			rightValue = [...new Set(rightValue)]
	}

	return twoSideEvaluator(leftValue, conditionNode.operator, rightValue)
}

const evaluateGroupCondition = ({ appController, conditionNode, contextData }) => {
	switch (conditionNode.operator) {
		// Group operators
		case AND:
			return conditionNode.nodes.every((subconditionNode) =>
				evaluateCondition({ conditionNode: subconditionNode, contextData, appController })
			)

		case OR:
			return conditionNode.nodes.some((subconditionNode) =>
				evaluateCondition({ conditionNode: subconditionNode, contextData, appController })
			)

		case NOR:
			return conditionNode.nodes.every(
				(subconditionNode) =>
					!evaluateCondition({ conditionNode: subconditionNode, contextData, appController })
			)

		default:
			throw new Error('Unknown operator on group node')
	}
}

evaluateCondition = ({ appController, conditionNode, contextData }) => {
	if (!contextData) contextData = {}

	if (conditionNode.nodeType === e_FilterNodeType.GROUP) {
		return evaluateGroupCondition({ appController, conditionNode, contextData })
	} else {
		return evaluateEdgeCondition({ appController, conditionNode, contextData })
	}
}
