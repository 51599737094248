import cloneDeep from 'lodash/cloneDeep'

import e_BuiltInObjectClassPropertyIds from '@appfarm/common/enums/e_BuiltInObjectClassPropertyIds'
import e_ObjectState from '@appfarm/common/enums/e_ObjectState'
import e_BuiltInObjectPropertyIds from '@appfarm/common/enums/e_BuiltInObjectPropertyIds'
import e_Cardinality from '@appfarm/common/enums/e_Cardinality'
import e_ActionNodeSelectionType from '@appfarm/common/enums/e_ActionNodeSelectionType'

import { runActionNodeOnServer } from '#modules/afClientApi'
import persistFiles from './persistFiles'

const p_persist = ({
	actionNode,
	getState,
	actionNodeRunner,
	appController,
	actionNodeLogger,
	contextData,
}) =>
	new Promise((resolve, reject) => {
		if (!actionNode.dataSourceId) return reject(new Error('Persist ActionNode does not specify Data Source'))
		const dataSource = appController.getDataSource(actionNode.dataSourceId)
		if (!dataSource) return reject(new Error('Unable to find dataSource for persist'))

		const state = getState()
		if (!dataSource.local) return reject(new Error('Cannot persist non-local Data Source'))
		if (!state.appState.serverClientStateInSync)
			return reject(new Error('Cannot persist - no contact with server'))

		const { selectionType, staticFilter, filterDescriptor } = actionNode
		const data = dataSource.getObjectsBySelectionType({
			selectionType: selectionType || e_ActionNodeSelectionType.ALL,
			staticFilter,
			filterDescriptor,
			contextData,
		})
		if (!data.length) {
			actionNodeLogger.debug('No objects to persist')
			return resolve()
		}
		const getRefreshedData = () => {
			const refreshedData = dataSource.getObjectsBySelectionType({
				selectionType: e_ActionNodeSelectionType.FILTERED,
				staticFilter: { _id: { $in: data.map(({ _id }) => _id) } },
				contextData,
			})
			return refreshedData
		}

		const runtimeOrBuiltInProperties = Object.values(dataSource.getPropertyMetaDict()).filter(
			(propertyMeta) => propertyMeta.isBuiltIn || propertyMeta.runtime
		)

		const newOrUpdatedData = cloneDeep(data).map((item) => {
			// Exclude runtime or built-in properties, unless explicitly allowed for client generation
			runtimeOrBuiltInProperties.forEach((runtimeOrBuiltInPropertyMeta) => {
				if (runtimeOrBuiltInPropertyMeta.nodeName === '_id') return // always keep
				if (!runtimeOrBuiltInPropertyMeta.isNeededByServer) {
					delete item[runtimeOrBuiltInPropertyMeta.nodeName]
				}
			})

			return item
		})

		if (!newOrUpdatedData.length) {
			actionNodeLogger.debug('No new or updated objects to persist')
			return resolve()
		}

		const runRegularPersist = () => {
			const rootActionId = actionNodeRunner.getRootAction().id
			runActionNodeOnServer(rootActionId, actionNode.id, { data: newOrUpdatedData })
				.then(({ objects } = {}) => {
					if (objects) dataSource._addOrMergeObjects(objects)

					const data = getRefreshedData()
					const shouldSetCreatedProperties =
						dataSource.cardinality === e_Cardinality.MANY ||
						(dataSource.cardinality === e_Cardinality.ONE &&
							data[0] &&
							data[0][e_BuiltInObjectPropertyIds.OBJECT_STATE] === e_ObjectState.NEW)

					const shouldSetUpdateProperties =
						dataSource.cardinality === e_Cardinality.MANY ||
						(dataSource.cardinality === e_Cardinality.ONE &&
							data[0] &&
							data[0][e_BuiltInObjectPropertyIds.OBJECT_STATE] === e_ObjectState.UPDATED)

					// set client side created date for new objects
					if (shouldSetCreatedProperties) {
						const newData = data.filter(
							(item) => item[e_BuiltInObjectPropertyIds.OBJECT_STATE] === e_ObjectState.NEW
						)
						if (newData.length) {
							const temporaryValues = {
								[e_BuiltInObjectClassPropertyIds.CREATED_DATE]: new Date().toJSON(),
							}

							if (dataSource.storeCreatedBy) {
								const currentUserId = getState().authState.userId
								temporaryValues[e_BuiltInObjectClassPropertyIds.CREATED_BY] = currentUserId
							}

							newData.forEach((object) => {
								dataSource.modifyObjectLocally(object._id, temporaryValues)
							})
						}
					}
					// set updated date for updated (not new objects)
					if (shouldSetUpdateProperties) {
						const updatedData = data.filter(
							(item) => item[e_BuiltInObjectPropertyIds.OBJECT_STATE] === e_ObjectState.UPDATED
						)
						if (updatedData.length) {
							const temporaryValues = {
								[e_BuiltInObjectClassPropertyIds.UPDATED_DATE]: new Date().toJSON(),
							}

							if (dataSource.storeUpdatedBy) {
								const currentUserId = getState().authState.userId
								temporaryValues[e_BuiltInObjectClassPropertyIds.UPDATED_BY] = currentUserId
							}

							updatedData.forEach((object) => {
								dataSource.modifyObjectLocally(object._id, temporaryValues)
							})
						}
					}

					// set persisted objects syncronized
					dataSource.setObjectStateForObjectIds(
						data.map((item) => item._id),
						e_ObjectState.SYNCHRONIZED
					)

					const runtimeProperties = Object.values(dataSource.getPropertyMetaDict()).filter(
						(propertyMeta) => propertyMeta.runtime
					)

					const changedObjects = cloneDeep(getRefreshedData()).map((item) => {
						runtimeProperties.forEach((runtimePropertyMeta) => {
							delete item[runtimePropertyMeta.nodeName]
						})

						return item
					})

					return appController.p_notifyObjectChange({
						sourceDataSourceId: dataSource.id,
						changedObjecstArray: changedObjects,
						objectClassId: dataSource.objectClassId,
					})
				})
				.then(resolve)
				.catch((err) => reject(err))
		}

		if (dataSource.isFileObjectClass) {
			return persistFiles({
				dataSource,
				actionNode,
				actionNodeRunner,
				actionNodeLogger,
				newOrUpdatedData,
				contextData,
			})
				.then((result) => {
					if (result && result.runRegularPersist) {
						runRegularPersist()
					} else {
						dataSource.setObjectStateForObjectIds(result.objectIds, e_ObjectState.SYNCHRONIZED)
						resolve()
					}
				})
				.catch(reject)
		} else {
			runRegularPersist()
		}
	})

export default p_persist
