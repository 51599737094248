/******************************************************************************
 *
 * Client for interacting with AmpSession
 *
 *****************************************************************************/

import { nanoid } from 'nanoid'
import io from 'socket.io-client'
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs'
import { AbortError, ServerError } from '#utils/clientErrors'
import { RequestTimeoutError, ConnectionNotReadyError } from './errors'

const debug = false

/******************************************************************************
 *
 * Message Creators
 *
 *****************************************************************************/

const MessageType = {
	// Outgoing
	CONNECT: 'connect',
	REQUEST: 'request',
	ERR_RESPONSE: 'err_response',
	TERMINATE: 'terminate',

	// Incomming
	ERROR: 'error',
	CONNECTED: 'connected',
	APP_READY: 'app_ready',
	APP_REQUEST: 'app_request',

	// Bidirectional
	RESPONSE: 'response',
	AUTH: 'auth',
	APP_MSG: 'app_message',

	// Pingpong
	PING: 'ping',
	PONG: 'pong',
}

/******************************************************************************
 *
 * Class
 *
 *****************************************************************************/
const getRandomInteger = (maxValue) => Math.floor(Math.random() * maxValue)

class AmpClient {
	constructor({ appMessageHandler, appRequestHandler, onErrorHandler, onStateChange, authFunction }) {
		// Refs
		this.__appMessageHandler = appMessageHandler
		this.__appRequestHandler = appRequestHandler
		this.__onErrorHandler = onErrorHandler
		this.__onStateChange = onStateChange
		this.__authFunction = authFunction

		// State
		this.sessionId = null
		this.requestPromises = {}

		this.socketConnected = false
		this.sessionConnected = false
		this.communicationReady = false
		this.initiallyConnected = false

		this.sequenceCheckActive = false
		this.expectedSequenceNumber = 1
		this.nextSequenceNumber = 1

		// Bind all the stuff
		this._onSocketClose = this._onSocketClose.bind(this)
		this._onSocketOpen = this._onSocketOpen.bind(this)
		this._onSessionConnected = this._onSessionConnected.bind(this)
		this._onCommunicationsReady = this._onCommunicationsReady.bind(this)
		this._onStateChange = this._onStateChange.bind(this)

		this._handleResponse = this._handleResponse.bind(this)
		this._handleResponseError = this._handleResponseError.bind(this)

		this._sendRawMessage = this._sendRawMessage.bind(this)
		this._onMessage = this._onMessage.bind(this)

		this.sendMessage = this.sendMessage.bind(this)
		this.connect = this.connect.bind(this)
		this.disconnect = this.disconnect.bind(this)

		this._init = this._init.bind(this)
		this._init()
	}

	/******************************************************************************
	 *
	 * Socket Init
	 *
	 *****************************************************************************/
	_init() {
		const socket = io('/', {
			transports: ['polling', 'websocket'],
			// transports: ['polling'],
			path: '/api/ws/v2',
			autoConnect: false,
			reconnection: false,
			reconnectionDelayMax: 15000,
		})

		this.socket = socket

		this._reconnectAttempts = 0
		this._reconnectTimer = null

		const tryReconnectWithBackoff = () => {
			clearTimeout(this._reconnectTimer)

			// Exponential backoff
			const backoffTime = 2 ** this._reconnectAttempts * 1000

			// Max 10 seconds + random jitter
			const timeout = Math.min(backoffTime, 10000) + getRandomInteger(1000) - 500

			this._reconnectAttempts++
			console.log('Reconnect in: ' + timeout)

			this._reconnectTimer = setTimeout(() => {
				console.log('Trying to reconnect: ' + this._reconnectAttempts)
				socket.connect()
			}, timeout)
		}

		socket.on('connect', () => {
			console.log('Socket Connected')
			this._reconnectAttempts = 0
			this._onSocketOpen.call(this)
		})

		socket.on('disconnect', (reason) => {
			console.warn('Socket Disconnected: ' + reason)
			this.sequenceCheckActive = false
			this.expectedSequenceNumber = 1
			this.nextSequenceNumber = 1
			this._onSocketClose.call(this)

			// Disconnected by the client. No automatic reconnect
			if (reason === 'io client disconnect') {
				this._reconnectAttempts = 0
				return
			}

			tryReconnectWithBackoff()
		})

		socket.on('message', this._onMessage.bind(this))
		socket.on('reconnecting', (attempt) => console.log('Socket reconnect attempt ' + attempt))
		socket.on('connect_error', (err) => {
			switch (err.description) {
				case 400: {
					socket.disconnect()
					this.__onErrorHandler(new Error('Unable to connect to Solution - try again later'))
					break
				}

				default:
					console.log('Unknown connection error. Trying again in a while')
			}

			tryReconnectWithBackoff()
		})

		window.addEventListener('offline', (event) => {
			socket.disconnect()
		})

		window.addEventListener('online', (event) => {
			tryReconnectWithBackoff()
		})
	}

	/******************************************************************************
	 *
	 * Session State Changes
	 *
	 *****************************************************************************/

	_onSocketClose() {
		console.warn('*** Lost connection to server')

		/**
		 * Disqualify all connection stages
		 */
		this.socketConnected = false
		this.sessionConnected = false
		this.communicationReady = false

		this._onStateChange()
	}

	_onSocketOpen() {
		console.info('1 - Socket connected')

		if (this.socketConnected) return
		this.socketConnected = true
		this._onStateChange()

		// Authenticate the session
		Promise.all([this.__authFunction(), getFingerprint()])
			.then(([token, fp]) => {
				this._sendRawMessage({
					type: MessageType.AUTH,
					payload: {
						token: token,
						sessionId: this.sessionId,
						fp: fp,
					},
				})
			})
			.catch((err) => {
				console.log('Failed get authentication token', err)
				this.__onErrorHandler(err)
				this.disconnect()
			})
	}

	_onSessionConnected() {
		console.log('2 - AmpSession connected')
		this.sessionConnected = true
		this._onStateChange()
	}

	_onCommunicationsReady() {
		/**
		 * Connectd to the Application Session
		 */
		console.log('3 - Communication channel ready')
		this.communicationReady = true
		this.initiallyConnected = true

		this._onStateChange()
	}

	_onStateChange() {
		this.__onStateChange({
			socketConnected: this.socketConnected,
			sessionConnected: this.sessionConnected,
			communicationReady: this.communicationReady,
			initiallyConnected: this.initiallyConnected,
		})
	}
	/******************************************************************************
	 *
	 * Internal Utils
	 *
	 *****************************************************************************/

	_handleResponse(message) {
		if (!message.requestId) return console.error('Error: Got malformed response from server')
		if (!this.requestPromises[message.requestId])
			return console.error('Error: Could not find callback - probably client side timeout')
		const promises = this.requestPromises[message.requestId]
		promises.resolve(message.payload)
	}

	_handleResponseError(message) {
		if (!this.requestPromises[message.requestId])
			return console.error('Error: Could not find callback - probably client side timeout')
		const promises = this.requestPromises[message.requestId]
		promises.reject(new ServerError(message.payload))
	}

	_getMessageSequenceNumber() {
		const nextNumber = this.nextSequenceNumber
		this.nextSequenceNumber += 1
		return nextNumber
	}
	/******************************************************************************
	 *
	 * Server Messaging
	 *
	 *****************************************************************************/

	_sendRawMessage(message) {
		debug && console.log('[AmpClient] OUT', message)
		if (this.sequenceCheckActive) message.s = this._getMessageSequenceNumber()
		this.socket.send(message)
	}

	_onMessage(message) {
		debug && console.log('[AmpClient] IN', message)

		if (this.sequenceCheckActive && message.type !== MessageType.ERROR) {
			if (message.s !== this.expectedSequenceNumber) {
				// Ignore any duplicate messages
				if (message.s < this.expectedSequenceNumber) return

				debug && console.log('[AmpClient] unexpected sequence number')
				this.disconnect()
				this.__onErrorHandler(new Error('Out of sync with server. Please reconnect'))
				return
			}

			this.expectedSequenceNumber += 1
		}

		const messageType = message.type

		switch (messageType) {
			case MessageType.PING:
				this._sendPong(message)
				break

			// Message to Application Layer
			case MessageType.APP_MSG:
				this.__appMessageHandler(message.payload)
				break

			// Response from a specific request
			case MessageType.RESPONSE: {
				this._handleResponse(message)
				break
			}

			case MessageType.APP_REQUEST: {
				this.__appRequestHandler(message.payload)
					.then((result) => {
						this._sendRawMessage({
							type: MessageType.RESPONSE,
							id: message.requestId,
							payload: result,
						})
					})
					.catch((err) => {
						// TODO: Better handling
						this._sendRawMessage({
							type: MessageType.RESPONSE,
							id: message.requestId,
							payload: err,
						})
					})

				break
			}

			// AmpSession Authenticated and Connected
			case MessageType.AUTH: {
				const { sessionId } = message.payload
				this.sessionId = sessionId
				this._onSessionConnected()

				this.nextSequenceNumber = 1
				this.expectedSequenceNumber = 1
				this.sequenceCheckActive = true

				if (!message.payload.sameSession) {
					// Initiate connection with new session
					this._sendRawMessage({ type: MessageType.CONNECT })
				} else {
					// Connected
					console.log('Session ready - Connected to same session')
				}
				break
			}

			case MessageType.CONNECTED: {
				console.log('Session ready')
				break
			}

			case MessageType.APP_READY: {
				this._onCommunicationsReady()
				break
			}

			case MessageType.ERROR:
				// The error message are specific to a request
				if (message.requestId) return this._handleResponseError(message)

				// Generic error
				this.__onErrorHandler(new ServerError(message.payload))
				break

			default:
		}
	}

	/******************************************************************************
	 *
	 * Public Message Methods
	 *
	 *****************************************************************************/

	_sendPong(pingMessage) {
		this._sendRawMessage({
			type: MessageType.PONG,
			payload: {
				clientTime: Date.now(),
				pingId: pingMessage.id,
			},
		})
	}

	sendMessage(message) {
		this._sendRawMessage({
			type: MessageType.APP_MSG,
			payload: message,
		})
	}

	sendRequest(requestPayload, timeout = 10000, abortController) {
		return new Promise((resolve, reject) => {
			if (!this.communicationReady) {
				return reject(new ConnectionNotReadyError())
			}

			const requestId = nanoid()
			const timer = setTimeout(() => {
				reject(new RequestTimeoutError())
			}, timeout)

			if (abortController) {
				abortController.signal.onabort = () => {
					if (this.requestPromises[requestId]) {
						// Still ongoing
						clearTimeout(timer)
						delete this.requestPromises[requestId]
						reject(new AbortError('Request Aborted'))
					}
				}
			}

			this.requestPromises[requestId] = {
				resolve: (result) => {
					clearTimeout(timer)
					delete this.requestPromises[requestId]
					resolve(result)
				},
				reject: (err) => {
					clearTimeout(timer)
					delete this.requestPromises[requestId]
					reject(err)
				},
			}

			this._sendRawMessage({
				type: MessageType.REQUEST,
				id: requestId,
				payload: requestPayload,
			})
		})
	}

	/******************************************************************************
	 *
	 * Public Control API
	 *
	 *****************************************************************************/
	connect() {
		const socket = this.socket
		socket.connect()
	}

	disconnect() {
		if (this.communicationReady) this._sendRawMessage({ type: MessageType.TERMINATE })
		this.socket.disconnect()
	}
}

export default AmpClient
