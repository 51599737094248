import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import * as Sentry from '@sentry/react'

import retry from '@appfarm/common/utils/retry'

import { setDataModel } from '#actions/metadataActions'
import {
	getEnumsTranslation,
	getIsAppDescriptionReady,
	getObjectClassTranslation,
	getLoadedApp,
} from '#selectors/metadataSelectors'
import processAndTranslateEnumeratedTypes from '#utils/processAndTranslateEnumeratedTypes'
import translatObjectClasses from '#utils/translationUtils/translateObjectClasses'
import { dataSourceId as __BUILTIN_RUNTIME_STATE__DS__ } from '@appfarm/common/builtins/builtInRuntimeStateDataDefinition'
import translateItemRecursive from '#utils/translationUtils/translateItemRecursive'
import DataLoader from '../DataLoader'
import IndexDbAppStorageEngine from '../../../../controllers/IndexDbAppStorageEngine'

const WANTED_BUILT_IN_DATASOURCE_IDS = [
	'__MECH_CURRENT_USER_DS',
	'__BUILTIN_RUNTIME_STATE__DS__',
	'__BUILT_IN_CURRENT_USER_GROUPS__DS__',
]

const DataModelLoader = ({ activeAppId, appController, activeLanguageId, appTranslation }) => {
	// Local State
	const [originalDataSources, setOriginalDataSources] = useState(null)
	const [dataSources, setDataSources] = useState(null)
	const [objectClasses, setObjectClasses] = useState(null)
	const [originalObjectClasses, setOriginalObjectClasses] = useState(null)
	const [enumeratedTypes, setEnumeratedTypes] = useState(null)
	const [originalEnums, setOriginalEnums] = useState(null)
	const [storageEngine, setStorageEngine] = useState(null)
	const [readyForDataLoader, setReadyForDataLoader] = useState(false)

	// External State
	const dataSourcesChecksum = useSelector((state) => state.metaData.wantedChecksums.dataSources)
	const objectClassesChecksum = useSelector((state) => state.metaData.wantedChecksums.objectClasses)
	const enumeratedTypesChecksum = useSelector((state) => state.metaData.wantedChecksums.enumeratedTypes)
	const appDescriptionReady = useSelector(getIsAppDescriptionReady)
	const appDescription = useSelector(getLoadedApp)
	const enumTranslation = useSelector(getEnumsTranslation)
	const ocTranslation = useSelector(getObjectClassTranslation)

	const dispatch = useDispatch()

	// Hope this does not lead to race conditions
	useEffect(() => {
		setDataSources(null)
		setObjectClasses(null)
		setEnumeratedTypes(null)
	}, [activeAppId])

	// Logic to only report complete datamodel to AppController
	useEffect(() => {
		// Hold on till we got everything
		if (!dataSources) return
		if (!objectClasses) return
		if (!enumeratedTypes) return
		if (!appDescriptionReady) return

		// Check if data is up to date - no need to run if they are outdated
		if (dataSources.checksum !== dataSourcesChecksum) return
		if (objectClasses.checksum !== objectClassesChecksum) return
		if (enumeratedTypes.checksum !== enumeratedTypesChecksum) return

		// Check if we have a storage engine and app is enabled for
		// offline use
		// TODO: Make this robust for re-runs etc
		let newStorageEngine
		if (appDescription.pwaOffline) {
			if (!storageEngine) {
				// Create new storage engine
				newStorageEngine = new IndexDbAppStorageEngine(activeAppId)
				setStorageEngine(newStorageEngine)

				// Only want datasources of OC types
				const dataSourceIds = Object.values(dataSources.dataSources)
					.filter((item) => item.dataSourceType === 'OBJECT_CLASS')
					.map((item) => item.id)
					.concat(WANTED_BUILT_IN_DATASOURCE_IDS)

				newStorageEngine
					.init(dataSourceIds, dataSourcesChecksum)
					.then(() => {
						console.log('Storage engine initialized')
					})
					.catch((err) => {
						console.log('Failed to init storage engine', err)
					})
					.finally(() => setReadyForDataLoader(true))
			}
		} else {
			// Non-offline app
			setReadyForDataLoader(true)
		}

		// App controller needs metadata before data
		appController.setOrUpdateDataModel({
			dataSources: dataSources.dataSources,
			objectClasses: objectClasses.objectClasses,
			enumeratedTypes: enumeratedTypes.enumeratedTypes,
			dependenciesResolvable: dataSources.dependenciesResolvable,
			appStorageEngine: newStorageEngine,
		})

		// This will trigger dataModelReady
		dispatch(setDataModel({ dataSources, objectClasses, enumeratedTypes, languageId: activeLanguageId }))
	}, [dataSources, objectClasses, enumeratedTypes, appDescriptionReady, storageEngine]) // languageId is not set as dependency as we only care about language if Enum types changes

	/******************************************************************************
	 *
	 * Data Sources
	 *
	 *****************************************************************************/

	useEffect(() => {
		if (!originalDataSources) return

		const dataSources = { ...originalDataSources }

		if (
			appTranslation &&
			appTranslation[__BUILTIN_RUNTIME_STATE__DS__] &&
			originalDataSources.dataSources[__BUILTIN_RUNTIME_STATE__DS__]?.properties
		) {
			const appVariableDataSource = {
				...originalDataSources.dataSources[__BUILTIN_RUNTIME_STATE__DS__],
				properties: translateItemRecursive(
					originalDataSources.dataSources[__BUILTIN_RUNTIME_STATE__DS__].properties,
					appTranslation[__BUILTIN_RUNTIME_STATE__DS__]
				),
			}
			dataSources.dataSources = {
				...dataSources.dataSources,
				[__BUILTIN_RUNTIME_STATE__DS__]: appVariableDataSource,
			}
		}

		setDataSources(dataSources)
	}, [originalDataSources, appTranslation])

	useEffect(() => {
		if (!activeAppId) return
		if (!dataSourcesChecksum) return

		appController.setDataModelPendingFlag(true)

		const controller = new AbortController()

		// Note: Long timeout as transfer time is impacted by model size
		retry(
			(signal) =>
				axios.get(`/api/v1/apps/${activeAppId}/metadata/datasources?v=${dataSourcesChecksum}`, {
					signal,
					timeout: 60000,
				}),
			{
				maxTries: 3,
				retryInterval: 1000,
				signal: controller.signal,
				timeout: 65000,
			}
		)
			.then((result) => {
				if (controller.signal?.aborted) return

				setOriginalDataSources(result.data)
			})
			.catch((err) => {
				if (axios.isCancel(err)) return
				Sentry.captureMessage('Failed to fetch datasources (data model)', { extra: { err } })
			})

		return () => controller.abort()
	}, [activeAppId, dataSourcesChecksum])

	/******************************************************************************
	 *
	 * Object Classes
	 *
	 *****************************************************************************/

	/**
	 * Apply Format and translate objectClasses
	 */
	useEffect(() => {
		if (!originalObjectClasses) return

		const processedObjectClasses = translatObjectClasses(originalObjectClasses.objectClasses, ocTranslation)
		setObjectClasses({
			...originalObjectClasses,
			objectClasses: processedObjectClasses,
		})
	}, [originalObjectClasses, ocTranslation])

	useEffect(() => {
		if (!activeAppId) return
		if (!objectClassesChecksum) return

		appController.setDataModelPendingFlag(true)

		const controller = new AbortController()

		// Note: Long timeout as transfer time is impacted by model size
		retry(
			(signal) =>
				axios.get(`/api/v1/apps/${activeAppId}/metadata/objectclasses?v=${objectClassesChecksum}`, {
					signal,
					timeout: 60000,
				}),
			{
				maxTries: 3,
				retryInterval: 1000,
				signal: controller.signal,
				timeout: 65000,
			}
		)
			.then((result) => {
				if (controller.signal?.aborted) return

				setOriginalObjectClasses(result.data)
			})
			.catch((err) => {
				if (axios.isCancel(err)) return
				Sentry.captureMessage('Failed to fetch object classes (data model)', { extra: { err } })
			})

		return () => controller.abort()
	}, [activeAppId, objectClassesChecksum])

	/******************************************************************************
	 *
	 * Enumerated Types
	 *
	 *****************************************************************************/

	/**
	 * Apply Format and translate enums
	 */
	useEffect(() => {
		if (!originalEnums) return

		let viewEnum
		if (originalEnums.enumeratedTypes['__BUILTIN_ENUM__VIEW'] && appTranslation) {
			viewEnum = { ...originalEnums.enumeratedTypes['__BUILTIN_ENUM__VIEW'] }
			viewEnum.values = viewEnum.values.map((item) => {
				const translatedName = appTranslation[item.id]?.[item.id]?.name
				if (translatedName) {
					const translatedItem = { ...item, name: translatedName }
					viewEnum.valueDict[item.id] = translatedItem
					return translatedItem
				}

				return item
			})
		}

		const processedEnums = processAndTranslateEnumeratedTypes(originalEnums.enumeratedTypes, enumTranslation)
		if (viewEnum) processedEnums[viewEnum.id] = viewEnum

		setEnumeratedTypes({
			...originalEnums,
			enumeratedTypes: processedEnums,
		})
	}, [originalEnums, enumTranslation, appTranslation])

	useEffect(() => {
		if (!enumeratedTypesChecksum) return

		appController.setDataModelPendingFlag(true)

		const controller = new AbortController()

		// Note: Long timeout as transfer time is impacted by model size
		retry(
			(signal) =>
				axios.get(`/api/v1/apps/${activeAppId}/metadata/enumeratedtypes?v=${enumeratedTypesChecksum}`, {
					signal,
					timeout: 60000,
				}),
			{
				maxTries: 3,
				retryInterval: 1000,
				signal: controller.signal,
				timeout: 65000,
			}
		)
			.then((result) => {
				if (controller.signal?.aborted) return

				setOriginalEnums(result.data)
			})
			.catch((err) => {
				if (axios.isCancel(err)) return
				Sentry.captureMessage('Failed to fetch enumerated types (data model)', { extra: { err } })
			})

		return () => controller.abort()
	}, [activeAppId, enumeratedTypesChecksum])

	// Don't render data loader until we have stuff ready here
	if (!activeAppId) return null
	if (!readyForDataLoader) return null

	// DataModelLoader depends on stuff from this loader
	return <DataLoader activeAppId={activeAppId} appStorageEngine={storageEngine} />
}

DataModelLoader.propTypes = {
	activeAppId: PropTypes.string,
	activeLanguageId: PropTypes.string,
	appTranslation: PropTypes.object,
	appController: PropTypes.shape({
		setOrUpdateDataModel: PropTypes.func.isRequired,
		setDataModelPendingFlag: PropTypes.func.isRequired,
	}).isRequired,
}

export default DataModelLoader
