import e_FilterTargetSelectionMode from '@appfarm/common/enums/e_FilterTargetSelectionMode'
import { filterEvaluator } from '#utils/filterEvaluator'
import { generateFilterFromGroupNode } from '#utils/filterGenerator'

const getObjectsBySelectionType = ({
	dataSource,
	selectionType,
	staticFilter,
	filterDescriptor,
	contextData,
	actionName,
	removeFileData,
}) => {
	let resultingObjects = null
	switch (selectionType) {
		case e_FilterTargetSelectionMode.ALL:
			resultingObjects = dataSource.getAllObjects()
			break

		case e_FilterTargetSelectionMode.SELECTED:
			resultingObjects = dataSource.getSelectedObjects()
			break

		case e_FilterTargetSelectionMode.UNSELECTED:
			resultingObjects = dataSource.getUnselectedObjects()
			break

		case e_FilterTargetSelectionMode.CONTEXT:
			resultingObjects = (() => {
				const objectForReturn = dataSource.getContextObject(contextData)
				if (!objectForReturn) return null
				return [objectForReturn]
			})()

			if (!resultingObjects)
				console.warn(actionName, ': tried to run action on context, but no context was found')
			break

		case e_FilterTargetSelectionMode.FILTERED:
			resultingObjects = (() => {
				const data = dataSource.getAllObjects()
				if (staticFilter) {
					return filterEvaluator(data, staticFilter)
				} else if (filterDescriptor) {
					const filter = generateFilterFromGroupNode({
						filterDescriptorNode: filterDescriptor,
						contextData: contextData,
						appController: dataSource.__appController,
					})
					return filterEvaluator(data, filter)
				}

				console.warn(actionName, ': Tried to get filtered data, but no filter was set')
				return null
			})()
			break

		default:
			console.warn(actionName, ': No valid selection type was set')
	}

	if (removeFileData && dataSource.local && dataSource.isFileObjectClass) {
		resultingObjects = resultingObjects.map((item) => {
			const object = { ...item }
			delete object.__file
			return object
		})
	}
	return resultingObjects
}

export default getObjectsBySelectionType
