import ObjectId from 'bson-objectid'
import isUndefined from 'lodash/isUndefined'

import e_ObjectState from '@appfarm/common/enums/e_ObjectState'
import e_BuiltInObjectClassPropertyIds from '@appfarm/common/enums/e_BuiltInObjectClassPropertyIds'
import e_BuiltInObjectPropertyIds from '@appfarm/common/enums/e_BuiltInObjectPropertyIds'
import { randomIdentifierGenerator } from '@appfarm/common/utils/randomIdentifierGenerator'

/**
 * This will generate a new and empty object instance with default values
 */
const objectGenerator = (dataSource) => {
	const newObject = { _id: new ObjectId().toString() }

	if (dataSource.local) {
		newObject[e_BuiltInObjectPropertyIds.OBJECT_STATE] = e_ObjectState.NEW
	}

	// Set temporary created date and by properties (will be overwritten on server)
	newObject[e_BuiltInObjectClassPropertyIds.CREATED_DATE] = new Date().toJSON()
	if (dataSource.storeCreatedBy) {
		newObject[e_BuiltInObjectClassPropertyIds.CREATED_BY] = dataSource.getCurrentUserId()
	}

	// Generate a random identifier (will not be overwritten on server)
	if (dataSource.storeRandomId) {
		newObject[e_BuiltInObjectClassPropertyIds.RANDOM_ID] = randomIdentifierGenerator()
	}

	Object.values(dataSource.propertiesMetaDict)
		.filter((property) => !isUndefined(property.defaultValue))
		.forEach((property) => {
			const value = dataSource.__appController.getDataFromDataValue(property.defaultValue)
			newObject[property.nodeName] = value
		})

	if (dataSource.isFileObjectClass) {
		newObject.__fileContentLink = null
		newObject.__file = new Blob()
		newObject.__uploadComplete = false
		newObject.__uploadProgress = 0
		newObject.__mimeType = 'image/png'
		newObject.__fileSize = 0
	}

	return newObject
}

export default objectGenerator
