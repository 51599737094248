/******************************************************************************
 *
 * This will refresh all the checksums from current app
 *
 *****************************************************************************/
import axios from 'axios'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as Sentry from '@sentry/react'

import retry from '@appfarm/common/utils/retry'

import { setLatestChecksums, setWantedChecksums } from '#actions/metadataActions'
import { getDevToolsHotReloadDisabledAndInDev, getWantedChecksums } from '#selectors/metadataSelectors'

const ChecksumLoader = ({ activeAppId }) => {
	const checksumsReady = useSelector((state) => state.resourceReadyState.activeAppChecksums)
	const disableHotReload = useSelector(getDevToolsHotReloadDisabledAndInDev)
	const wantedChecksums = useSelector(getWantedChecksums)
	const initialWantedChecksumsReady = Object.values(wantedChecksums).some((checksum) => !!checksum)

	const dispatch = useDispatch()

	useEffect(() => {
		if (!activeAppId) return
		if (checksumsReady) return

		const controller = new AbortController()

		retry(
			(signal) =>
				axios.get(`/api/v1/apps/${activeAppId}/checksums`, {
					signal,
					timeout: 5000,
				}),
			{
				maxTries: 5,
				retryInterval: 1000,
				signal: controller.signal,
				timeout: 7500,
			}
		)
			.then((result) => {
				// The result is no longer relevant - just return
				if (controller.signal?.aborted) return

				// Set latest no matter what
				dispatch(setLatestChecksums(result.data))

				// Trigger metadata reload or set checksums if first load
				if (!disableHotReload || !initialWantedChecksumsReady) dispatch(setWantedChecksums(result.data))
			})
			.catch((err) => {
				if (axios.isCancel(err)) return
				Sentry.captureMessage('Failed to fetch app checksums', { extra: { err } })
			})

		// Abort requests + retry as cleanup
		return () => controller.abort()
	}, [activeAppId, checksumsReady])

	return null
}

export default ChecksumLoader
