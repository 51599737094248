import { Settings as luxonSettings } from 'luxon'
import { isNil, isUndefined } from 'lodash'

import e_Cardinality from '@appfarm/common/enums/e_Cardinality'
import e_DataSourceChangeType from '@appfarm/common/enums/e_DataSourceChangeType'
import e_StorageType from '@appfarm/common/enums/e_StorageType'
import e_ScreenSize from '@appfarm/common/enums/e_ScreenSize'
import e_DeviceOrientation from '@appfarm/common/enums/e_DeviceOrientation'
import { e_DrawerType } from '@appfarm/common/enums/e_PropertyTypes'
import { getObjectClassProperties } from '@appfarm/common/builtins/builtInRuntimeStateDataDefinition'
import { defaultLangId } from '@appfarm/common/builtins/builtInLanguageDefinitions'
import { screenSizeAppVarPropertyId } from '@appfarm/common/builtins/builtInScreenSizeDefinition'
import { environmentAppVarPropertyId } from '@appfarm/common/builtins/builtInEnvironmentEnumDefinition'
import { appTimeZoneAppVarPropertyId } from '@appfarm/common/builtins/builtInTimeZoneDefinition'
import { themeAppVarPropertyId, defaultThemeId } from '@appfarm/common/builtins/builtInThemeDefinition'
import { deviceOrientationAppVarPropertyId } from '@appfarm/common/builtins/builtInDeviceOrientationEnumDefinition'
import { notificationPermissionPropertyId } from '@appfarm/common/builtins/builtInNotificationPermissionDefinition'

import { getActiveAppId, getLoadedApp } from '#selectors/metadataSelectors'
import { getCurrentNotificationPermission } from '#utils/pwaUtils'

import getDeviceOSInfo from './browserUserAgentParser'
import accountLocalStorageHandler from '../../modules/accountLocalStorageHandler'
import sessionStorageHandler from '../../modules/sessionStorageHandler'
import getDataOperationDepnendencyDescriptors from './getDataOperationDepnendencyDescriptors'
import { getSideEffects, setTimeZoneOnServer } from '../../modules/afClientApi'
import getObjectChanges from './getObjectChanges'
import ClientDataSource from './ClientDataSource'

const getScreenSize = () => {
	const width = window.innerWidth
	if (width <= 599) return e_ScreenSize.EXTRA_SMALL
	if (width <= 1239) return e_ScreenSize.SMALL
	if (width <= 1439) return e_ScreenSize.MEDIUM
	return e_ScreenSize.LARGE
}

const getDeviceOrientation = () => {
	return window.innerHeight > window.innerWidth ? e_DeviceOrientation.PORTRAIT : e_DeviceOrientation.LANDSCAPE
}

const getIsFullScreen = () => {
	if (window.matchMedia('(display-mode: standalone)').matches) return true
	if (window.navigator.standalone === true) return true
	return false
}

// Locally known properties. Ignore if they come from
// server or cache.
const LOCAL_PROPERTY_NAMES = [
	'__BUILTIN_RUNTIME_STATE__DRAWER_OPEN',
	'__BUILTIN_RUNTIME_STATE__IS_ONLINE',
	'__APP_VAR__HOSTNAME',
	'__APP_VAR__DEVICE_OS',
	'__APP_VAR__DEVICE_OS_VERSION',
	'_APP_VAR__FULLSCREEN',
	'__APP_VAR_DOCUMENT_TITLE',
	screenSizeAppVarPropertyId,
	deviceOrientationAppVarPropertyId,
	environmentAppVarPropertyId,
	notificationPermissionPropertyId,
]

class AppVariableDataSource extends ClientDataSource {
	constructor(dataSourceMeta, appController, logger) {
		dataSourceMeta = {
			...dataSourceMeta,
			properties: getObjectClassProperties().reduce(
				(dict, prop) => {
					dict[prop.id] = prop
					return dict
				},
				{ ...dataSourceMeta.properties }
			),
		}

		super(dataSourceMeta, appController, logger)

		this.id = dataSourceMeta.id
		this.name = dataSourceMeta.name
		this.objectClassId = '__BUILTIN_RUNTIME_STATE__'
		this.isFileObjectClass = false
		this.local = true
		this.cardinality = e_Cardinality.ONE
		this.dataReady = true

		this.drawerSubscribers = []
		this.screenSizeSubscribers = []

		this._modifyObject = this._modifyObject.bind(this)
	}

	setDataSourceModel(dataSourceMeta) {
		if (!dataSourceMeta) return

		this.initOnLoad = dataSourceMeta.initOnLoad

		this.reverseDependencies = dataSourceMeta.reverseDependencies || []
		this.functionCalculationOrder = dataSourceMeta.functionCalculationOrder || []
		this.localFunctionReverseDependencies = dataSourceMeta.localFunctionReverseDependencies || []
		this.hasDependenciesToSelfDataSource = dataSourceMeta.hasDependenciesToSelfDataSource
		this.clientFilterReverseDependencies = dataSourceMeta.clientFilterReverseDependencies || []
		this.clientFilterDependencies = dataSourceMeta.clientFilterDependencies || []

		const builtinObjectClassProperties = getObjectClassProperties().reduce((dict, prop) => {
			dict[prop.id] = prop
			return dict
		}, {})

		this.dataSourceProperties = dataSourceMeta.properties || {}
		this.propertiesMetaDict = { ...dataSourceMeta.properties, ...builtinObjectClassProperties }
		// Used for debug tool
		this.propertiesMetadataInUseList = Object.values(this.propertiesMetaDict)

		this.operationDependencyDescriptors = getDataOperationDepnendencyDescriptors(this.reverseDependencies)
		this.nodeNamesWithChangeDependencies = Object.keys(
			this.operationDependencyDescriptors.propertyChangeDescriptorDictionary
		)
	}

	_getLocalStorageController() {
		return accountLocalStorageHandler
	}
	_getSessionStorageController() {
		return sessionStorageHandler
	}

	// Can be both from cache and from server
	setInitialData(data = {}, { skipDefaults } = {}) {
		const initialData = data.data || [{ _id: 'dummyId' }]

		// Filter locally known values
		LOCAL_PROPERTY_NAMES.forEach((propertyName) => {
			delete initialData[propertyName]
		})

		if (this.hasInitialData || skipDefaults) {
			// merge data from server
			if (initialData.length && initialData[0]) {
				const data = {
					...initialData[0],
					...this.getAllObjects()?.[0],
				}
				this._setAllObjects([data], { [data._id]: data })
			}

			return
		}

		// Set initial data
		const state = this.reduxStore.getState()
		const app = getLoadedApp(state)

		const screenSize = getScreenSize()
		let drawerOpen = !!app.drawerDefaultOpen

		if (
			app.drawerId &&
			app.drawerDefaultOpen &&
			(!app.drawerType || app.drawerType === e_DrawerType.RESPONSIVE) &&
			[e_ScreenSize.EXTRA_SMALL, e_ScreenSize.SMALL].includes(screenSize)
		) {
			drawerOpen = false
		}

		const deviceOSInfo = getDeviceOSInfo()
		let initialObject = {
			_id: 'dummyId',
			__BUILTIN_RUNTIME_STATE__DRAWER_OPEN: drawerOpen,
			__BUILTIN_RUNTIME_STATE__IS_ONLINE: state.appState.serverClientStateInSync,
			__APP_VAR__ACTIVE_LANG: defaultLangId,
			__BUILTIN_RUNTIME_STATE__IS_UNAUTHENTICATED: state.authState.isAnonymous,
			__APP_VAR__HOSTNAME: window.location.host,
			__APP_VAR__DEVICE_OS: deviceOSInfo.name,
			__APP_VAR__DEVICE_OS_VERSION: deviceOSInfo.version,
			__APP_VAR__FULLSCREEN: getIsFullScreen(),
			__APP_VAR__READABLE_ID: app.readableId,
			__APP_VAR__DOCUMENT_TITLE: document.title,
			[screenSizeAppVarPropertyId]: screenSize,
			[deviceOrientationAppVarPropertyId]: getDeviceOrientation(),
			[environmentAppVarPropertyId]: window.AF_PARAMS.afEnvironment,
			[appTimeZoneAppVarPropertyId]: luxonSettings.defaultZoneName, //Intl.DateTimeFormat().resolvedOptions().timeZone,
			[themeAppVarPropertyId]: app.themeId || defaultThemeId,
			[notificationPermissionPropertyId]: getCurrentNotificationPermission(),
			...initialData[0],
		}

		// set data to be able to calculate default values
		this._setAllObjects([initialObject])

		// set default values
		const defaultValues = Object.values(this.propertiesMetaDict)
			.filter((property) => !isUndefined(property.defaultValue))
			.reduce((acc, property) => {
				const value = this.__appController.getDataFromDataValue(property.defaultValue)
				acc[property.nodeName] = value
				return acc
			}, {})

		let storedValues = {}
		// set values from local storage
		const maybeLocallyStoredProperties = Object.values(this.propertiesMetaDict).filter(
			(property) => property.storageType === e_StorageType.LOCAL_STORAGE
		)

		if (maybeLocallyStoredProperties.length) {
			const localStorageController = this._getLocalStorageController()
			if (localStorageController) {
				const nodeNameKeyDict = {}
				const appId = app.id
				storedValues = maybeLocallyStoredProperties.reduce((acc, property) => {
					const value = localStorageController.getValue([appId, this.id, property.nodeName])
					if (!isNil(value)) {
						acc[property.nodeName] = value
						nodeNameKeyDict[property.nodeName] = true
					}
					return acc
				}, storedValues)
				localStorageController.cleanKeyValuesByPath([appId, this.id], nodeNameKeyDict)
			}
		}

		// set values from session storage
		const maybeSessionStoredProperties = Object.values(this.propertiesMetaDict).filter(
			(property) => property.storageType === e_StorageType.SESSION_STORAGE
		)

		if (maybeSessionStoredProperties.length) {
			const sessionStorageController = this._getSessionStorageController()
			if (sessionStorageController) {
				const nodeNameKeyDict = {}
				const appId = app.id
				storedValues = maybeSessionStoredProperties.reduce((acc, property) => {
					const value = sessionStorageController.getValue([appId, this.id, property.nodeName])
					if (!isNil(value)) {
						acc[property.nodeName] = value
						nodeNameKeyDict[property.nodeName] = true
					}
					return acc
				}, storedValues)
				sessionStorageController.cleanKeyValuesByPath([appId, this.id], nodeNameKeyDict)
			}
		}

		initialObject = {
			...defaultValues,
			...initialObject,
			...storedValues,
		}

		this._setAllObjects([initialObject], { [initialObject._id]: initialObject })
		this.hasInitialData = true

		this._runFormulaRecalculation()
	}

	_resolveUpdateSideEffects(changedNodeNames) {
		const newObject = this.getAllObjects()[0] || {}

		if (changedNodeNames[appTimeZoneAppVarPropertyId]) {
			luxonSettings.defaultZoneName = newObject[appTimeZoneAppVarPropertyId]
			setTimeZoneOnServer(luxonSettings.defaultZoneName)
		}

		if (changedNodeNames['__APP_VAR__DOCUMENT_TITLE']) {
			document.title = newObject.__APP_VAR__DOCUMENT_TITLE
		}
	}

	_modifyObject(objectId, newData) {
		if (!this.getAllObjects()?.length) return console.warn('Tried to set appVariable before initial data')
		const oldObject = this.getAllObjects()[0]
		let newObject = { ...oldObject, ...newData }

		newObject = this._recalculateSingleObjectFormula(newObject)

		this._setAllObjects([newObject], { [newObject._id]: newObject })

		const { hasChanges, changedNodeNames } = getObjectChanges(oldObject, newObject)

		if (hasChanges) {
			this._resolveUpdateSideEffects(changedNodeNames)

			this._addChangeDescriptor(e_DataSourceChangeType.OBJECT_MODIFIED, changedNodeNames, {
				[newObject._id]: true,
			})
			this._notifyLocalDependencies(e_DataSourceChangeType.OBJECT_MODIFIED, changedNodeNames)
			this._notifyClientFilterDependencies(e_DataSourceChangeType.OBJECT_MODIFIED, changedNodeNames)
			this.__storageAdapter.onObjectChanged(newObject)

			const propertiesToStoreOnLocalDevice = Object.values(this.propertiesMetaDict).filter(
				(property) =>
					property.storageType === e_StorageType.LOCAL_STORAGE && changedNodeNames[property.nodeName]
			)
			const localStorageController = this._getLocalStorageController()
			if (propertiesToStoreOnLocalDevice.length && localStorageController) {
				const state = this.reduxStore.getState()
				const activeAppId = getActiveAppId(state)
				propertiesToStoreOnLocalDevice.forEach((property) => {
					localStorageController.setValue(
						[activeAppId, this.id, property.nodeName],
						newObject[property.nodeName]
					)
				})
			}

			const propertiesToStoreInSession = Object.values(this.propertiesMetaDict).filter(
				(property) =>
					property.storageType === e_StorageType.SESSION_STORAGE && changedNodeNames[property.nodeName]
			)
			const sessionStorageController = this._getSessionStorageController()
			if (propertiesToStoreInSession.length && sessionStorageController) {
				const state = this.reduxStore.getState()
				const activeAppId = getActiveAppId(state)
				propertiesToStoreInSession.forEach((property) => {
					sessionStorageController.setValue(
						[activeAppId, this.id, property.nodeName],
						newObject[property.nodeName]
					)
				})
			}
		}
		return newObject
	}

	/**
	 * Drawer methods
	 */
	getDrawerState() {
		return this.getAllObjects()[0].__BUILTIN_RUNTIME_STATE__DRAWER_OPEN
	}

	setDrawerState(newState) {
		this._modifyObject(null, { __BUILTIN_RUNTIME_STATE__DRAWER_OPEN: !!newState })
		this.drawerSubscribers.forEach((sub) => sub(!!newState))
		this._writeToRedux()
	}

	// Tester konsept der vi går utenom redux for denne datakilden
	subscribeToDrawerState(sub) {
		this.drawerSubscribers.push(sub)

		const unsubscribe = () => {
			this.drawerSubscribers = this.drawerSubscribers.filter((item) => item !== sub)
		}

		// sub(this.getDrawerState())
		return unsubscribe
	}

	getAppTimeZone() {
		return this.getAllObjects()[0][appTimeZoneAppVarPropertyId]
	}

	setOnlineStatus(newState) {
		this._modifyObject(null, { __BUILTIN_RUNTIME_STATE__IS_ONLINE: !!newState })
		this._writeToRedux()
	}

	setAnoymousStatus(newState) {
		this._modifyObject(null, { __BUILTIN_RUNTIME_STATE__IS_UNAUTHENTICATED: !!newState })
		this._writeToRedux()
	}

	setDeviceOrientation(newState) {
		if (!this.getAllObjects()?.length) return
		if (newState === this.getAllObjects()[0][deviceOrientationAppVarPropertyId]) return

		this._modifyObject(null, { [deviceOrientationAppVarPropertyId]: newState })
		this._writeToRedux()
	}

	getClientScreenSize() {
		return this.getAllObjects()[0][screenSizeAppVarPropertyId]
	}

	setClientScreenSize(newState) {
		this._modifyObject(null, { [screenSizeAppVarPropertyId]: newState })
		this.screenSizeSubscribers.forEach((sub) => sub(newState))
		this._writeToRedux()
	}

	subscribeToClientScreenSize(sub) {
		this.screenSizeSubscribers.push(sub)

		const unsubscribe = () => {
			this.screenSizeSubscribers = this.screenSizeSubscribers.filter((item) => item !== sub)
		}
		return unsubscribe
	}

	setNotificationPermission(newState) {
		if (!this.getAllObjects()?.length) return

		if (newState === this.getAllObjects()[0][notificationPermissionPropertyId]) return

		this._modifyObject(null, { [notificationPermissionPropertyId]: newState })
		this._writeToRedux()
	}

	_resolveSideEffects(nodeNames = {}, newData = []) {
		if (!Object.keys(nodeNames)?.length && !newData.length) return // nothing to do
		// calculate sideeffects
		if (this.reverseDependencies?.some((dependency) => nodeNames[dependency.nodeName])) {
			const invalidatedDataSourceIdDict = this.__appController.invalidateDataSourcesById(
				this.reverseDependencies.filter((item) => nodeNames[item.nodeName]).map((item) => item.dataSourceId),
				{ updateGui: true }
			)
			getSideEffects(this.id, { objectsModified: newData }, this.getDataForSynchronization())
				.then((sideEffects) => {
					this.logger.debug('Side effects for ' + this.name, { payload: sideEffects })
					// Apply side effects
					this.__appController.writeSideEffects(this, sideEffects, {
						logger: this.logger,
						invalidatedDataSourceIdDict,
					})
					this._writeToRedux()
				})
				.catch((err) =>
					this.logger.warning('Could not resolve dependencies for ' + this.name, {
						payload: { err },
					})
				)
		}
	}

	setLanguage(languageId) {
		const newData = {
			__APP_VAR__ACTIVE_LANG: languageId,
		}
		this._modifyObject(null, newData)
		this._resolveSideEffects({ __APP_VAR__ACTIVE_LANG: true }, [newData])
	}

	resetLanguage() {
		const newData = {
			__APP_VAR__ACTIVE_LANG: defaultLangId,
		}
		this._modifyObject(null, newData)
		this._resolveSideEffects({ __APP_VAR__ACTIVE_LANG: true }, [newData])
	}

	reevaluateActiveTheme() {
		const state = this.reduxStore.getState()
		const app = getLoadedApp(state)

		const themeId = state.appState.themeIdOverride || app.themeId || defaultThemeId
		if (themeId !== this.getAllObjects()[0][themeAppVarPropertyId]) {
			this._modifyObject(null, {
				[themeAppVarPropertyId]: themeId,
			})
		}
	}

	// setAccountDialogState(newState) {
	// 	this._modifyObject(null, { __ACCOUNT_DIALOG_OPEN: !!newState })
	// }

	// Override Unneeded methods
	_removeObject() {
		console.warn('Method disabled in AppvariableDataSource')
	}
	_removeMultipleObjects() {
		console.warn('Method disabled in AppvariableDataSource')
	}
	_insertObject() {
		console.warn('Method disabled in AppvariableDataSource')
	}
	_replaceAllObjects() {
		console.warn('Method disabled in AppvariableDataSource')
	}
	_replaceObjects() {
		console.warn('Method disabled in AppvariableDataSource')
	}
	_addOrMergeObjects() {
		console.warn('Method disabled in AppvariableDataSource')
	}
	_runSorting() {}

	/**************************************************************************
	 *
	 * Metadata Getters
	 *
	 *************************************************************************/
}

export default AppVariableDataSource
