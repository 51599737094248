/******************************************************************************
 *
 * AppStorageEngine
 * Storage Engine using IndexDB
 *
 *****************************************************************************/

import Dexie from 'dexie'
import sequentialPromiseResolver from '@appfarm/common/utils/sequentialPromiseResolver'
// Dexie.debug = true
const SYSTEM_OBJECT_ID = 'default'

class IndexDbAppStorageEngine {
	constructor(appId) {
		this.appId = appId
		this.indexDB = null

		this.queueTimer = null
		this.queue = []

		this.isPopulated = false
	}

	async init(dataSourceIdList, dataSourcesChecksum) {
		console.log('Initializing storage engine', dataSourceIdList)

		// Generate current schema
		const configs = dataSourceIdList.reduce((acc, dataSourceId) => {
			acc[dataSourceId] = '_id, data'
			return acc
		}, {})

		// Single object for system data
		configs['af_system'] = '_id, data'

		console.log(configs)

		const indexDb = new Dexie(this.appId)
		this.indexDB = indexDb

		try {
			await indexDb.open()
		} catch (err) {
			if (err.name === 'NoSuchDatabaseError') {
				console.log('No db found. Creating a new one')
				this.indexDB.version(1).stores(configs)
				await this.indexDB.open()
				this.indexDB.af_system.put({
					_id: SYSTEM_OBJECT_ID,
					dataModelChecksum: dataSourcesChecksum,
					dbVersion: 1,
					updatedDate: new Date(),
					populated: false,
				})

				// No further action needed. We just created a new db
				return
			}
		}

		console.log('We already have a db. Check if we need to update it')
		const systemObject = await indexDb.table('af_system').get('default')
		this.isPopulated = systemObject.populated

		if (systemObject.dataModelChecksum !== dataSourcesChecksum) {
			console.log('Data model checksums do not match. Updating db')
			this.indexDB.close()
			const newDbVersion = systemObject.dbVersion + 1
			this.indexDB.version(newDbVersion).stores(configs)
			await this.indexDB.open()
			this.indexDB.af_system.update(SYSTEM_OBJECT_ID, {
				dataModelChecksum: dataSourcesChecksum,
				dbVersion: newDbVersion,
				updatedDate: new Date(),
			})
			return
		}

		console.log('Data model checksums match. No need to update db')
	}

	getTable(dataSourceId) {
		return this.indexDB.table(dataSourceId)
	}

	/******************************************************************************
	 *
	 * System Information
	 *
	 *****************************************************************************/
	getIsPopulated() {
		return this.isPopulated
	}

	/******************************************************************************
	 *
	 * Initial Data Population
	 *
	 *****************************************************************************/

	async populateAllInitialData(data) {
		console.log('Populating initial data', data)
		const db = this.indexDB
		await db.transaction('rw', db.tables, async () => {
			await Promise.all(
				db.tables.map(async (table) => {
					console.log('Populating table', table.name)
					if (table.name === 'af_system') return
					if (data[table.name]) {
						await table.clear()
						await table.bulkAdd(data[table.name].data)
					}
				})
			)

			await db.table('af_system').update(SYSTEM_OBJECT_ID, {
				populated: true,
				populatedDate: new Date(),
			})
		})
	}

	/******************************************************************************
	 *
	 * Processing
	 *
	 *****************************************************************************/

	processQueue() {
		if (this.queue.length === 0) return
		const currentQueue = this.queue
		this.queue = []

		console.log('Processing queue', currentQueue)
		sequentialPromiseResolver(currentQueue)
			.then(() => {
				console.log('Queue processed successfully')
			})
			.catch((err) => {
				console.log('Error processing queue', err)
			})
	}

	/******************************************************************************
	 *
	 * Handle Object Events
	 *
	 *****************************************************************************/

	replaceAllObjects(dataSourceId, objectList) {
		console.log(`[${dataSourceId}] Got a replace all event`, objectList)

		const table = this.getTable(dataSourceId)
		if (!table) return

		clearTimeout(this.queueTimer)
		this.queue.push(async () => {
			await table.clear()
			await table.bulkAdd(objectList)
		})

		this.queueTimer = setTimeout(() => this.processQueue(), 1000)
	}

	newObject(dataSourceId, newObject) {
		console.log(`[${dataSourceId}] Got new object for storage`, newObject)
		const table = this.getTable(dataSourceId)
		if (!table) return

		clearTimeout(this.queueTimer)
		this.queue.push(async () => {
			await table.put(newObject)
		})

		this.queueTimer = setTimeout(() => this.processQueue(), 1000)
	}

	objectChanged(dataSourceId, changedObject) {
		console.log(`[${dataSourceId}] Got object change for storage`, changedObject)
		const table = this.getTable(dataSourceId)
		if (!table) return

		clearTimeout(this.queueTimer)
		this.queue.push(async () => {
			await table.put(changedObject)
		})

		this.queueTimer = setTimeout(() => this.processQueue(), 1000)
	}

	objectDeleted(dataSourceId, objectId) {
		console.log(`[${dataSourceId}] Got object delete for storage`, objectId)
		const table = this.getTable(dataSourceId)
		if (!table) return
		clearTimeout(this.queueTimer)
		this.queue.push(async () => {
			await table.delete(objectId)
		})

		this.queueTimer = setTimeout(() => this.processQueue(), 1000)
	}

	multipleObjectsDeleted(dataSourceId, objectIdList) {
		console.log(`[${dataSourceId}] Got a delete multiple objects event:`, objectIdList)
		const table = this.getTable(dataSourceId)
		if (!table) return

		clearTimeout(this.queueTimer)
		this.queue.push(async () => {
			await table.bulkDelete(objectIdList)
		})

		this.queueTimer = setTimeout(() => this.processQueue(), 1000)
	}

	/******************************************************************************
	 *
	 * Data Getters
	 *
	 *****************************************************************************/
	async getAllData() {
		const db = this.indexDB
		return db.transaction('r', db.tables, async () => {
			const result = {}

			await Promise.all(
				db.tables.map(async (table) => {
					result[table.name] = await table.toArray()
				})
			)

			return result
		})
	}
}

export default IndexDbAppStorageEngine
