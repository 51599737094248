import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import throttle from 'lodash/throttle'

import e_DeviceOrientation from '@appfarm/common/enums/e_DeviceOrientation'
import e_ScreenSize from '@appfarm/common/enums/e_ScreenSize'
import { e_DrawerType } from '@appfarm/common/enums/e_PropertyTypes'

import { getLoadedApp, getMainDrawer, isDataModelReady } from '#selectors/metadataSelectors'

import appController from '../controllers/appControllerInstance'

const getScreenSize = (clientWidth) => {
	if (clientWidth <= 599) return e_ScreenSize.EXTRA_SMALL
	if (clientWidth <= 1239) return e_ScreenSize.SMALL
	if (clientWidth <= 1439) return e_ScreenSize.MEDIUM
	return e_ScreenSize.LARGE
}

const setDrawerState = (appVariablesDataSource, newScreenSize, drawer, app) => {
	if (!drawer?.drawerType || drawer?.drawerType === e_DrawerType.RESPONSIVE) {
		const currentDrawerState = appVariablesDataSource.getDrawerState()

		if (currentDrawerState && [e_ScreenSize.EXTRA_SMALL, e_ScreenSize.SMALL].includes(newScreenSize)) {
			appVariablesDataSource.setDrawerState(false)
		} else if (
			!currentDrawerState &&
			app.drawerDefaultOpen &&
			[e_ScreenSize.MEDIUM, e_ScreenSize.LARGE].includes(newScreenSize)
		) {
			appVariablesDataSource.setDrawerState(true)
		}
	}
}

const setDeviceState = (drawer, app) => {
	const appVariablesDataSource = appController.getAppVariablesDataSource()
	if (!appVariablesDataSource) return

	const newDeviceOrientation =
		window.innerHeight > window.innerWidth ? e_DeviceOrientation.PORTRAIT : e_DeviceOrientation.LANDSCAPE
	appVariablesDataSource.setDeviceOrientation(newDeviceOrientation)

	const newScreenSize = getScreenSize(window.innerWidth)
	const currentScreenSize = appVariablesDataSource.getClientScreenSize()

	if (newScreenSize !== currentScreenSize) {
		setDrawerState(appVariablesDataSource, newScreenSize, drawer, app)

		appVariablesDataSource.setClientScreenSize(newScreenSize)
	}
}

/*
 * Will sync state built in state to the
 * runtime state object for data binding
 */
const RuntimeStateProvider = () => {
	const drawer = useSelector(getMainDrawer)
	const app = useSelector(getLoadedApp)
	const serverClientStateInSync = useSelector((state) => state.appState.serverClientStateInSync)
	const isAnonymous = useSelector((state) => state.authState.isAnonymous)
	const dataModelReady = useSelector(isDataModelReady)

	// Setting drawer state
	useEffect(() => {
		const eventHandler = throttle(() => {
			setDeviceState(drawer, app)
		}, 500)

		window.addEventListener('resize', eventHandler)
		return () => window.removeEventListener('resize', eventHandler)
	}, [drawer, app])

	// Set Online/Offline state
	useEffect(() => {
		const runtimeStateDataSource = appController.getAppVariablesDataSource()
		runtimeStateDataSource && runtimeStateDataSource.setOnlineStatus(!!serverClientStateInSync)
	}, [serverClientStateInSync])

	useEffect(() => {
		const runtimeStateDataSource = appController.getAppVariablesDataSource()
		runtimeStateDataSource && runtimeStateDataSource.setAnoymousStatus(!!isAnonymous)
	}, [isAnonymous])

	useEffect(() => {
		if (!dataModelReady) return

		// Permissions API is only supported in Safari on iOS 16+ (likely missing on older Android browsers, too)
		if (!navigator.permissions) return

		const onNotificationPermissionChange = (event) => {
			const runtimeStateDataSource = appController.getAppVariablesDataSource()
			runtimeStateDataSource && runtimeStateDataSource.setNotificationPermission(event.currentTarget.state)
		}

		navigator.permissions
			.query({ name: 'notifications' })
			.then((permissionStatus) => permissionStatus.addEventListener('change', onNotificationPermissionChange))

		return () =>
			navigator.permissions
				.query({ name: 'notifications' })
				.then((permissionStatus) =>
					permissionStatus.removeEventListener('change', onNotificationPermissionChange)
				)
	}, [dataModelReady]) // make sure to run it after appVariableDataSource exists

	return null
}

export default RuntimeStateProvider
