import e_IteratorParamType, { e_IteratorParamTypeOptions } from '@appfarm/common/enums/e_IteratorParamType'

const getIteratorParamsDataForIteration = (
	component,
	{ index, iteratorCount, iteratorLevel, iteratorParamIdsInUse }
) => {
	if (!iteratorParamIdsInUse || !Object.keys(iteratorParamIdsInUse)?.length) return {}

	return e_IteratorParamTypeOptions
		.filter(
			(item) =>
				iteratorParamIdsInUse[`${component.id}_${item.ident}`] &&
				item.componentTypes.includes(component.componentType)
		)
		.reduce((params, item) => {
			const id = `${component.id}_${item.ident}` // Do not changes! Used in create and inflator
			switch (item.ident) {
				case e_IteratorParamType.IS_FIRST_ITERATION: {
					params[id] = index === 0
					break
				}
				case e_IteratorParamType.IS_LAST_ITERATION: {
					params[id] = index + 1 === iteratorCount
					break
				}
				case e_IteratorParamType.INDEX: {
					params[id] = index
					break
				}
				case e_IteratorParamType.RECURSION_LEVEL: {
					params[id] = iteratorLevel
					break
				}
				case e_IteratorParamType.ITERATOR_COUNT: {
					params[id] = iteratorCount
					break
				}
				default:
					console.log('Found unknown iteration param of type: ' + item.ident)
			}
			return params
		}, {})
}

export default getIteratorParamsDataForIteration
