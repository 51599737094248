import { useCallback, useEffect, useRef, useState } from 'react'
import * as Sentry from '@sentry/react'
import { useDispatch } from 'react-redux'
import { forceRetryAppLoad } from '#actions/metadataActions'
import { SLOW_LOADING_THRESHOLD, SOFT_RELOAD_TIMEOUT } from '#utils/loading'

export default function useSlowLoadHelpers(
	appReadyForRender: boolean
): [isLoadingSlow: boolean, isForcingRetry: boolean, retryLoadOrRefresh: () => void] {
	const dispatch = useDispatch()

	const slowLoadingTimeoutId = useRef<number | undefined>()
	const retryTimeout = useRef<number | undefined>()
	const [isLoadingSlow, setIsLoadingSlow] = useState(false)
	const [isForcingRetry, setIsForcingRetry] = useState(false)

	function clearTimeouts() {
		window.clearTimeout(slowLoadingTimeoutId.current)
		window.clearTimeout(retryTimeout.current)
	}

	// Start a timer to track when loading takes longer than the threshold.
	useEffect(() => {
		function reset() {
			clearTimeouts()
			setIsLoadingSlow(false)
			setIsForcingRetry(false)
		}

		if (appReadyForRender) {
			reset()
			return
		}

		if (!slowLoadingTimeoutId.current) {
			slowLoadingTimeoutId.current = window.setTimeout(() => {
				setIsLoadingSlow(true)
				slowLoadingTimeoutId.current = undefined
			}, SLOW_LOADING_THRESHOLD)
		}

		return reset
	}, [appReadyForRender])

	/**
	 * Triggers a "soft" reload of the app by forcing a rerender of ClientLoader, and hard reloads the
	 * browser if it takes too long after that.
	 */
	const retryLoadOrRefresh = useCallback(() => {
		if (isForcingRetry) return

		Sentry.captureMessage('App load is being forcibly retried.', 'warning')
		setIsForcingRetry(true)

		// Start a timer to track when the retry takes longer than the threshold.
		retryTimeout.current = window.setTimeout(() => {
			Sentry.captureMessage('Unable to load in time after forced retry - reloading app.', 'warning')
			window.location.reload()
		}, SOFT_RELOAD_TIMEOUT)

		// Trigger app load retry.
		dispatch(forceRetryAppLoad())
	}, [isForcingRetry])

	return [isLoadingSlow, isForcingRetry, retryLoadOrRefresh]
}
