import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import type { RouteMatch } from 'react-router-dom'

import { t } from '@lingui/macro'

import { setActiveApp } from '#actions/metadataActions'
import { handleEvent } from '#actions/eventActions'
import { setOnAppLoadExecutedValue, setAppActiveState } from '#actions/appStateActions'
import { modelAnalytics_logPerformanceMeasure } from '#logger/logger'

import {
	isAppNotFoundError,
	isNoAccessError,
	isUnsupportedDeviceError,
	getLoadedApp,
	getIsAppReadyForRender,
	getIsAppReadyToRunOnAppLoadAction,
	getAppRenderKey,
	getAppIsActive,
	getActiveAppId,
} from '#selectors/metadataSelectors'

import { getOnAppLoadExecuted } from '#selectors/appStateSelectors'
import { SOFT_RELOAD_TIMEOUT } from '#utils/loading'
import appController from '../controllers/appControllerInstance'
import unsupported_device from '../resources/unsupported_device.svg'
import NotFound from './NotFound'
import LoaderAnimation from './LoaderAnimation'
import ActiveApp from './ActiveApp'
import OnAppLoadFail from './OnAppLoadFail'
import SlowLoading from './SlowLoading'
import useSlowLoadHelpers from './ActiveAppLoader.hooks'

interface ActiveAppLoaderProps {
	match: RouteMatch<'appId'>
}

const ActiveAppLoader = (props: ActiveAppLoaderProps) => {
	/**
	 * Get stuff from state
	 */
	const readyForOnAppLoadAction = useSelector(getIsAppReadyToRunOnAppLoadAction)
	const appIsActive = useSelector(getAppIsActive)
	const appReadyForRender = useSelector(getIsAppReadyForRender)
	const onAppLoadExecuted = useSelector(getOnAppLoadExecuted)
	const activeAppDescription = useSelector(getLoadedApp)
	const appNotFoundError = useSelector(isAppNotFoundError)
	const noAccessError = useSelector(isNoAccessError)
	const unsupportedDeviceError = useSelector(isUnsupportedDeviceError)
	/* TODO: Fix getAppRenderKey types */
	const appRenderKey = useSelector((state) => (getAppRenderKey as any)(state, appController.getAppTimeZone()))
	const activeAppId = useSelector(getActiveAppId)

	const dispatch = useDispatch()

	const [onAppLoadError, setOnAppLoadError] = useState(false)

	/******************************************************************************
	 *
	 * Effects
	 *
	 *****************************************************************************/

	/**
	 * Setting active app based on url (initially)
	 */
	useEffect(() => {
		const appIdFromUrl = props.match.params.appId!.toLowerCase()
		dispatch(setActiveApp(appIdFromUrl))
		dispatch(setAppActiveState(true))

		return () => {
			dispatch(setAppActiveState(false))
		}
	}, [])

	/**
	 * Setting active app based on url (when app changes)
	 */
	useEffect(() => {
		const appIdFromUrl = props.match.params.appId!.toLowerCase()
		dispatch(setActiveApp(appIdFromUrl))
	}, [props.match.params.appId])

	/**
	 * Running onAppLoad action
	 */
	useEffect(() => {
		// Maybe we need a debounce
		if (readyForOnAppLoadAction && appIsActive) {
			// TODO: Er dette riktig plassering?

			if (!onAppLoadExecuted && activeAppDescription) {
				if (activeAppDescription.eventHandlers?.onAppLoad) {
					dispatch(
						handleEvent(
							activeAppDescription.eventHandlers.onAppLoad,
							{},
							{},
							{
								resolve: () => {
									if (window.performance.now) {
										modelAnalytics_logPerformanceMeasure({
											message: 'App loaded',
											timeEnd: window.performance.now(),
											metadata: { labels: { appId: activeAppId } },
										})
									}
									dispatch(setOnAppLoadExecutedValue(true))
									appController.onAppLoadExecuted()
								},
								reject: (err: unknown) => {
									console.error('Cannot start app - onAppLoad failed', err)
									setOnAppLoadError(true)
								},
							}
						)
					)
				} else {
					dispatch(setOnAppLoadExecutedValue(true))
					appController.onAppLoadExecuted()
				}
			}
		}
	}, [readyForOnAppLoadAction, appIsActive])

	const [isLoadingSlow, isRetrying, retryLoadOrRefresh] = useSlowLoadHelpers(appReadyForRender)

	if (onAppLoadError) {
		return <OnAppLoadFail />
	}

	if (appNotFoundError)
		return (
			<NotFound
				title={t`App not found`}
				message={t`No app with this URL exists. Please check the spelling of the URL.`}
			/>
		)

	if (noAccessError)
		return (
			<NotFound
				title={t`Access denied`}
				message={t`You lack sufficient permissions to access this app. Contact support for further assistance.`}
				enableLogout
			/>
		)

	if (unsupportedDeviceError)
		return (
			<NotFound
				imagePath={unsupported_device}
				title={t`Unsupported device`}
				message={t`The app is not available for this device size. Try another device.`}
			/>
		)

	if (!appReadyForRender) {
		return (
			<LoaderAnimation duration={isRetrying ? SOFT_RELOAD_TIMEOUT : undefined}>
				{ isLoadingSlow && <SlowLoading isRetrying={isRetrying} onRetry={retryLoadOrRefresh} /> }
			</LoaderAnimation>
		)
	}

	// Testing how cheksum as key works
	return <ActiveApp key={appRenderKey} />
}

export default ActiveAppLoader
