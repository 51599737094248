import UAParser from 'ua-parser-js'
import e_DeviceOS from '@appfarm/common/enums/e_DeviceOS'

const osUaNameToEnumValue = {
	Android: e_DeviceOS.ANDROID,
	iOS: e_DeviceOS.IOS,
	'Mac OS': e_DeviceOS.MAC_OS,
	'iPad OS': e_DeviceOS.IPAD_OS,
	Windows: e_DeviceOS.WINDOWS,
}

const getDeviceOSInfo = () => {
	const userAgent = window.navigator.userAgent
	const uaParser = new UAParser(userAgent)
	const osInfo = uaParser.getOS()
	const osValue = osUaNameToEnumValue[osInfo.name] ? osUaNameToEnumValue[osInfo.name] : e_DeviceOS.OTHER
	return {
		...osInfo,
		name: osValue,
	}
}

export default getDeviceOSInfo
