import isArray from 'lodash/isArray'
import e_ExecutionMode from '@appfarm/common/enums/e_ExecutionMode'
import e_ActionNodeSelectionType from '@appfarm/common/enums/e_ActionNodeSelectionType'
import executeBlockInSequence from './helpers/executeBlockInSequence'
import getIteratorParamsDataForIteration from './helpers/getIteratorParamsDataForIteration'

const forEachActionNode = ({ actionNode, contextData, actionNodeRunner, appController, actionNodeLogger }) =>
	new Promise((resolve, reject) => {
		let dataSourceId
		let objectsForIteration = []
		if (actionNode.dataBinding.propertyId) {
			objectsForIteration = appController.getObjectsFromDataBindingProperty(actionNode.dataBinding, {
				contextData,
			})
			dataSourceId = appController.getDataSourceIdFromDataBindingProperty(actionNode.dataBinding)
		} else {
			const dataSource = appController.getDataSource(actionNode.dataBinding.dataSourceId)
			if (!dataSource) return reject(new Error('ForEach: Unable to find dataSource for iteration'))

			dataSourceId = dataSource.id

			objectsForIteration = dataSource.getObjectsBySelectionType({
				selectionType: actionNode.selectionType || e_ActionNodeSelectionType.ALL,
				staticFilter: actionNode.staticFilter,
				filterDescriptor: actionNode.filterDescriptor,
				actionName: actionNode.name,
				contextData,
			})
		}

		if (isArray(objectsForIteration) && objectsForIteration.length && actionNodeRunner.children.length) {
			actionNodeLogger.debug('Iterating objects: ' + objectsForIteration.length)
			actionNodeLogger.table(objectsForIteration, null, { dataSourceId: dataSourceId })

			const children = actionNodeRunner.children

			const getContextForIteration = (objectItem, index, contextData, logger) => {
				const forEachContext = {
					...contextData,
					[dataSourceId]: [objectItem],
				}

				if (actionNode.iteratorParamIdsInUse && Object.keys(actionNode.iteratorParamIdsInUse)?.length) {
					const iteratorParams = getIteratorParamsDataForIteration(actionNode, {
						indexForExec: index,
						iteratorCount: objectsForIteration.length,
						iteratorParamIdsInUse: actionNode.iteratorParamIdsInUse,
						logger,
					})

					forEachContext.iterator_params = {
						...contextData?.iterator_params,
						...iteratorParams,
					}
				}
				return forEachContext
			}

			let executionMode = actionNode.executionMode
			if (actionNodeRunner.rootActionRunner.actionDebugger)
				executionMode = actionNodeRunner.rootActionRunner._debug_getExecutionModeOverride(
					actionNode.executionMode
				)

			switch (executionMode) {
				case e_ExecutionMode.ALL: {
					Promise.all(
						objectsForIteration.map((objectItem, indexForExec) => {
							const logger = actionNodeLogger.createChildLogger({ prefix: 'ForEach ' + indexForExec })
							logger.debug('Iteration ' + indexForExec)
							logger.time('Iteration ' + indexForExec)

							const forEachContext = getContextForIteration(objectItem, indexForExec, contextData, logger)
							logger.context(forEachContext)

							return executeBlockInSequence(children, forEachContext, logger).finally(() =>
								logger.timeEnd('Iteration ' + indexForExec)
							)
						})
					)
						.then((result) => {
							// absorbe nextIteration and exitLoop (no need to act on these since all iterations are run and current loop is exitied)
							const endExecution = result.some((singleResult) => !!singleResult?.endExecution)
							if (endExecution) resolve({ endExecution })

							resolve()
						})
						.catch(reject)
					break
				}
				default: {
					// run default / sequential
					const runIndex = (indexForExec) => {
						const logger = actionNodeLogger.createChildLogger({ prefix: 'ForEach ' + indexForExec })
						logger.debug('Iteration ' + indexForExec)
						logger.time('Iteration ' + indexForExec)

						const forEachContext = getContextForIteration(
							objectsForIteration[indexForExec],
							indexForExec,
							contextData,
							logger
						)
						logger.context(forEachContext)

						executeBlockInSequence(children, forEachContext, logger)
							.then((result) => {
								logger.timeEnd('Iteration ' + indexForExec)

								if (result) {
									const { endExecution, exitLoop } = result
									if (exitLoop) return resolve() // Absorbe this message
									if (endExecution) return resolve(result)
									// Probably nextIteration
								}

								// Increment index
								const nextIndex = indexForExec + 1

								// Terminate if end of loop
								if (!objectsForIteration[nextIndex]) return resolve()

								runIndex(nextIndex)
							})
							.catch((err) => {
								logger.timeEnd('Iteration ' + indexForExec)
								reject(err)
							})
					}

					runIndex(0)
				}
			}
		} else {
			actionNodeLogger.debug('No objects found for iteration')
			resolve()
		}
	})

export default forEachActionNode
