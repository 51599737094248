/******************************************************************************
 *
 * This will refresh all the checksums for global resources
 * not tied to any single app.
 *
 *****************************************************************************/
import axios from 'axios'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as Sentry from '@sentry/react'

import retry from '@appfarm/common/utils/retry'

import { setLatestGlobalChecksums } from '#actions/metadataActions'
import type { State } from '#reducers/index'

const GlobalResourceChecksumsLoader = () => {
	const checksumsReady = useSelector<State>((state) => state.resourceReadyState.globalChecksums)

	const dispatch = useDispatch()

	useEffect(() => {
		if (checksumsReady) return

		const controller = new AbortController()

		retry(
			(signal) =>
				axios.get('/api/v1/checksums', {
					signal,
					timeout: 5000,
				}),
			{
				maxTries: 5,
				retryInterval: 1000,
				signal: controller.signal,
				timeout: 7500,
			}
		)
			.then((result) => {
				// The result is no longer relevant - just return
				if (controller.signal?.aborted) return

				dispatch(setLatestGlobalChecksums(result.data))
			})
			.catch((err) => {
				if (axios.isCancel(err)) return
				Sentry.captureMessage('Failed to fetch global checksums', { extra: { err } })
			})

		// Abort requests + retry as cleanup
		return () => controller.abort()
	}, [checksumsReady])

	return null
}

export default GlobalResourceChecksumsLoader
