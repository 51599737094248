import builtInThemeDefinition from '@appfarm/common/builtins/builtInThemeDefinition'

const defaultTheme = builtInThemeDefinition.getDefaultTheme()

export default {
	// This will be set as the first thing
	// upon serverConnection
	appFarmMeta: {
		environment: null,
		webPushPublicKey: null,
	},

	metaData: {
		globalSettings: null,
		allApps: [],
		app: {}, // Loaded app
		views: [],
		dataSources: {},
		properties: {},
		enumeratedTypes: {},
		actions: {},
		themes: {
			[defaultTheme.id]: defaultTheme,
		},

		translations: {
			activeLanguageId: null,
			appTranslation: null,
			enumsTranslation: null,
			ocTranslation: null,

			// Applied together with actual translated metadata
			appliedLayoutTranslation: null,
			appliedActionTranslation: null, // Not in use
			appliedDataModelTranslation: null,
		},

		latestGlobalChecksums: {
			appList: null,
			globalSettings: null,
			themes: null,
		},

		/**
		 * Latest checksums for current app.
		 */
		latestChecksums: {
			app: null,
			dataSources: null,
			objectClasses: null,
			enumeratedTypes: null,
			layout: null,
			actions: null,
			functions: null,
			stylesheet: null,
		},

		/**
		 * Usually latest checksums, but may not be updated in non-dev or if
		 * hot reload is disabled. A diff between values here and the ones
		 * in loadedChecksums will trigger automatic reload of the metadata with
		 * checksum mismatch.
		 */
		wantedChecksums: {
			app: null,
			dataSources: null,
			objectClasses: null,
			enumeratedTypes: null,
			layout: null,
			actions: null,
			functions: null,
			stylesheet: null,
		},

		/**
		 * These are the checkusms of the currently loaded app
		 */
		loadedChecksums: {
			app: null,
			dataSources: null,
			objectClasses: null,
			enumeratedTypes: null,
			layout: null,
			actions: null,
			functions: null,
			stylesheet: null,
		},

		currentDeployment: {
			comment: null,
			createdBy: null,
			email: null,
			deployTime: null,
		},

		/**
		 * Can be set to force the global checksums loader to retry, cascading to the downstream loaders.
		 * */
		forceRetryLoadTimestamp: null,
	},

	// Same pattern as with Create
	resourceReadyState: {
		appList: false, // This will only indiciate initially loaded
		globalSettings: false,
		themes: false,
		globalChecksums: false,
		activeAppChecksums: false,
		clientStatus: false,
		currentUser: false,
	},

	// Runtime stuff
	appState: {
		appIsActive: undefined, // Unset initially

		// Set by url-sync
		wantedAppId: null,

		// Set by AppLoader
		activeAppId: null,

		activeAppReadableId: null, // Readable AppID

		// Connected to server. Datasources are in sync at this
		// stage and are ready to recieve commands.
		// It will also be sending live update messages.
		serverSessionIsReady: false,

		// set to true if the last reconnect was to the same session
		// allows for more optimized data sync
		lastConnectionToSameSession: false,

		// All datasources are in sync and initial data has been read.
		// Actions can be dispatched when this is true
		serverClientStateInSync: false,

		// Current app has been completely loaded at least once
		currentAppHasInitialData: false,

		// applist set at least once
		initialAppListSet: false,

		// TBD
		pendingData: false,

		onAppLoadExecuted: false,
		onAppLoadedExecuted: false,

		themeIdOverride: null,
		accountDialogOpenState: false,
		profileImageData: null,

		clientOutdated: false,
		forceClientUpgrade: false,

		// Scan barcode
		scanBarcodeDialogOpen: false,
		scanBarcodeDialogOnClose: null,
		scanBarcodeDialogOnAccept: null,
		scanBarcodeDialogOnError: null,

		// Confirmation Dialog
		confirmDialogOpen: false,
		confirmDialogHeader: '',
		confirmDialogBody: '',
		confirmDialogConfirmButton: '',
		confirmDialogCancelButton: '',
		confirmDialogDismissOnClickOutside: false,

		// used to store function references. Not sure if this is ok.
		confirmDialogConfirmCallback: null,
		confirmDialogCancelCallback: null,

		// Snackbar
		snackbarOpen: false,
		snackbarAutohideDuration: null,
		snackbarText: '',
		snackbarActionText: '',
		snackbarActionCallback: null,
		snackbarDismissCallback: null,

		// Unsplash
		unsplashDialogOpen: false,
		unsplashConfig: null,
		unsplashDialogPhotoSelectedCallback: null,
		unsplashDialogCancelCallback: null,

		// Used by LogIn to determine where to send the user
		// after log in from anonymous.
		loginDialogOpen: false,
		afterLoginRedirect: null,

		userTouchDetected: false,

		// Dev toolbar For developers
		devToolsHotReloadDisabled: false,
		devToolsIncludeGeneratedData: false,
		devToolsActiveActionId: null,
		devToolsActiveDataSourceId: null,
		devToolsEnabled: null,
		devToolsLogEntriesCounter: false,
	},

	// Runtime state in context of running app
	runtimeState: {
		openDialogIds: [],
		openDrawerIds: [],
		openPopovers: [],
		activeKeyboardShortcuts: [],
		viewTransition: undefined,
	},

	// All stuff related to authentication
	authState: {
		// Runtime - user input
		emailValue: '',
		passwordValue: '',

		isLoggedIn: null, // Initially unknown - set by checking status url

		// GUI Flow control
		loginErrorMessage: null,
		emailLoginActive: false,

		passwordLoginPending: false,
		showPasswordResetMessage: false,

		createFirstTimePasswordStatus: false,

		loginPinOrdered: false,

		// Config from server below
		jwtAuthPublicKey: null,
		initialUserStatusApplied: false, // Login-status. TODO: Henger på resourceReadyState.clientStatus

		isAnonymous: null,

		maintenanceModeEnabled: false,
		maintenanceModeText: '',

		googleEnabled: false,
		loginLinkEnabled: false,
		otpLoginEnabled: false,
		passwordLoginEnabled: false,
		customAuthProviders: [],
		redirectToAuth: false,

		title: '',
		logo: '',
		favIcon: '',
		infoText: '',
		onboardingUrl: null,

		loginThemeId: null,
		loginThemeData: null,

		backgroundColor: null,
		backgroundImageValue: null,
		backgroundImageSize: null,
		backgroundImageRepeat: null,
		backgroundGradient: null,

		userId: null,
		userFirstName: '',
		userLastName: '',
		userFullName: '',
		userEmail: '',
		userProfileImage: '',

		defaultAppReadableId: '',
		loginAppReadableId: '',

		...window.AF_PARAMS,
	},
}
