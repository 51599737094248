import React from 'react'
import PropTypes from 'prop-types'
import { isNil, isEqual, isPlainObject } from 'lodash'
import { withTheme } from '@material-ui/core/styles'

import e_UiComponentType from '@appfarm/common/enums/e_UiComponentType'

import { componentConnector } from '#store/dataConnector'
import { generatePrefixFromContext } from '#utils/contextDataUtils'

import { getUiComponent, generateBackgroundStyle } from './utils'
import UiMotion from './UiMotion'

const UiComponent = (props) => {
	const { component, visible } = props

	if (!visible) return null

	const ComponentProp = getUiComponent(component.componentType)

	if (component.motions?.length) {
		//const motion = component.motions[0]
		// if (component.componentType === CONTAINER && motion.type !== ROTATE) {
		// 	const ComponentPropForwardedRef = React.forwardRef((props, ref) => <ComponentProp {...props} forwardedRef={ref} />)
		// 	return <UiMotion ComponentProp={ComponentPropForwardedRef} {...props} />
		// }

		return <UiMotion ComponentProp={ComponentProp} {...props} />
	}

	return <ComponentProp {...props} />
}

UiComponent.propTypes = {
	component: PropTypes.object.isRequired,
	appController: PropTypes.object.isRequired,
	styleProp: PropTypes.object,
	virtualizationStyle: PropTypes.object,
	ownData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	eventHandler: PropTypes.func.isRequired,
	visible: PropTypes.bool.isRequired,
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	error: PropTypes.bool,
	tooltipTitle: PropTypes.string,
	contextData: PropTypes.object,
	dataUpdateReference: PropTypes.number.isRequired,
}

const makeMapStateToProps = () => {
	let stylePropCache = null
	let cachedThemeId
	let dataBoundStyleCache = {}
	let componentCache = null
	let lastOverride = null

	let widthValueCache
	let heightValueCache
	let offsetTopCache
	let offsetRightCache
	let offsetBottomCache
	let offsetLeftCache

	let backgroundStyleCache

	let cachedComponent

	const mapStateToProps = (ownProps, { getDataFromDataValue, getDataFromDataBinding }) => {
		if (cachedComponent !== ownProps.component) {
			cachedComponent = ownProps.component

			stylePropCache = null
			cachedThemeId = undefined
			dataBoundStyleCache = {}
			componentCache = null
			lastOverride = null

			backgroundStyleCache = undefined
			widthValueCache = undefined
			heightValueCache = undefined
			offsetTopCache = undefined
			offsetRightCache = undefined
			offsetBottomCache = undefined
			offsetLeftCache = undefined
		}

		let component = componentCache || ownProps.component
		let componentId = ownProps.component.id
		if (isPlainObject(ownProps.contextData) && Object.keys(ownProps.contextData).length) {
			const contextPrefix = generatePrefixFromContext(ownProps.contextData)
			componentId = `${contextPrefix}${component.id}`
		}

		let visible = true
		if (!isNil(component.visible)) visible = !!getDataFromDataValue(component.visible, ownProps.contextData)

		if (!visible) {
			return {
				component,
				visible,
			}
		}

		let disabled = ownProps.disabled || false
		let readOnly = ownProps.readOnly || false
		let error = false

		// Invalidate style-cache if theme changes
		if (stylePropCache && cachedThemeId !== ownProps.theme.af_id) stylePropCache = null

		let tooltipTitle
		let tooltipDisableInteractive
		let tooltipLineBreak
		if (ownProps.component.tooltip?.title) {
			tooltipTitle = getDataFromDataValue(ownProps.component.tooltip.title, ownProps.contextData, {
				getDisplayValue: true,
			})
			tooltipDisableInteractive = ownProps.component.tooltip.disableInteractive
			tooltipLineBreak = ownProps.component.tooltip.lineBreak
		}

		let conditionalClassNames = []
		component.conditionalStyles?.forEach((condition, index) => {
			if (!isNil(condition.condition) && getDataFromDataValue(condition.condition, ownProps.contextData)) {
				conditionalClassNames.push('c' + index)
			}
		})
		conditionalClassNames = conditionalClassNames.join(' ') || undefined

		if (
			![e_UiComponentType.LIST, e_UiComponentType.CHIP_GROUP, e_UiComponentType.AVATAR_GROUP].includes(
				component.componentType
			) &&
			component.propertyConditions?.length
		) {
			const overrides = {}
			component.propertyConditions.forEach((propertyCondition) => {
				if (getDataFromDataValue(propertyCondition.condition, ownProps.contextData)) {
					propertyCondition.propertyConditionValues.forEach((item) => {
						overrides[item.propertyIdent] = item.value
					})
				}
			})

			if (!isEqual(overrides, lastOverride)) {
				lastOverride = overrides
				component = {
					...ownProps.component,
					...overrides,
				}
				componentCache = component
			}
		}

		let regenerateDataBoundStyle = false

		const componentStyle = ownProps.component['style_props']
		const componentContainerStyle = ownProps.component['c_style_props']

		// Data bound component style
		if (componentStyle || componentContainerStyle) {
			const flattenedDataBoundStyles = {
				width: componentContainerStyle?.widthValue?.dataValue || componentStyle?.widthValue?.dataValue,
				height: componentContainerStyle?.heightValue?.dataValue || componentStyle?.heightValue?.dataValue,
				offsetTop: componentContainerStyle?.offset?.top?.dataValue || componentStyle?.offset?.top?.dataValue,
				offsetRight:
					componentContainerStyle?.offset?.right?.dataValue || componentStyle?.offset?.right?.dataValue,
				offsetBottom:
					componentContainerStyle?.offset?.bottom?.dataValue || componentStyle?.offset?.bottom?.dataValue,
				offsetLeft:
					componentContainerStyle?.offset?.left?.dataValue || componentStyle?.offset?.left?.dataValue,
			}

			if (isPlainObject(flattenedDataBoundStyles.width)) {
				const widthValue = getDataFromDataValue(flattenedDataBoundStyles.width, ownProps.contextData)
				if (widthValue !== widthValueCache) {
					widthValueCache = widthValue
					regenerateDataBoundStyle = true
				}
			}
			if (isPlainObject(flattenedDataBoundStyles.height)) {
				const heightValue = getDataFromDataValue(flattenedDataBoundStyles.height, ownProps.contextData)
				if (heightValue !== heightValueCache) {
					heightValueCache = heightValue
					regenerateDataBoundStyle = true
				}
			}
			if (componentStyle?.offset || componentContainerStyle?.offset) {
				if (isPlainObject(flattenedDataBoundStyles.offsetTop)) {
					const offsetTop = getDataFromDataValue(flattenedDataBoundStyles.offsetTop, ownProps.contextData)
					if (offsetTop !== offsetTopCache) {
						offsetTopCache = offsetTop
						regenerateDataBoundStyle = true
					}
				}
				if (isPlainObject(flattenedDataBoundStyles.offsetRight)) {
					const offsetRight = getDataFromDataValue(flattenedDataBoundStyles.offsetRight, ownProps.contextData)
					if (offsetRight !== offsetRightCache) {
						offsetRightCache = offsetRight
						regenerateDataBoundStyle = true
					}
				}
				if (isPlainObject(flattenedDataBoundStyles.offsetBottom)) {
					const offsetBottom = getDataFromDataValue(
						flattenedDataBoundStyles.offsetBottom,
						ownProps.contextData
					)
					if (offsetBottom !== offsetBottomCache) {
						offsetBottomCache = offsetBottom
						regenerateDataBoundStyle = true
					}
				}
				if (isPlainObject(flattenedDataBoundStyles.offsetLeft)) {
					const offsetLeft = getDataFromDataValue(flattenedDataBoundStyles.offsetLeft, ownProps.contextData)
					if (offsetLeft !== offsetLeftCache) {
						offsetLeftCache = offsetLeft
						regenerateDataBoundStyle = true
					}
				}
			}

			let backgrounds
			if (componentContainerStyle?.backgrounds?.length) {
				backgrounds = componentContainerStyle.backgrounds
			} else if (componentStyle?.backgrounds?.length) {
				backgrounds = componentStyle.backgrounds
			}

			if (backgrounds) {
				const hasDataBoundImage = backgrounds.some((backgroundItem) =>
					isPlainObject(backgroundItem.imageValue)
				)

				if (hasDataBoundImage) {
					const getImageUrl = (dataValue) => getDataFromDataValue(dataValue, ownProps.contextData)
					const backgroundStyle = generateBackgroundStyle(ownProps.component, ownProps.theme, { getImageUrl })
					if (backgroundStyle && !isEqual(backgroundStyleCache, backgroundStyle)) {
						backgroundStyleCache = backgroundStyle
						regenerateDataBoundStyle = true
					}
				}
			}

			if (regenerateDataBoundStyle) {
				let dataBoundStyle = {}

				if (backgroundStyleCache) dataBoundStyle = { ...backgroundStyleCache }

				if (!isNil(widthValueCache))
					dataBoundStyle.width = widthValueCache + (componentStyle.widthValue.unitValue || null)
				if (!isNil(heightValueCache))
					dataBoundStyle.height = heightValueCache + (componentStyle.heightValue.unitValue || null)
				if (!isNil(offsetTopCache))
					dataBoundStyle.top = offsetTopCache + (componentStyle.offset.top.unitValue || null)
				if (!isNil(offsetRightCache))
					dataBoundStyle.right = offsetRightCache + (componentStyle.offset.right.unitValue || null)
				if (!isNil(offsetBottomCache))
					dataBoundStyle.bottom = offsetBottomCache + (componentStyle.offset.bottom.unitValue || null)
				if (!isNil(offsetLeftCache))
					dataBoundStyle.left = offsetLeftCache + (componentStyle.offset.left.unitValue || null)

				dataBoundStyleCache = dataBoundStyle
				stylePropCache = null
			}
		}
		if (!stylePropCache) {
			cachedThemeId = ownProps.theme.af_id

			stylePropCache = {
				...(dataBoundStyleCache || {}),
				...ownProps.virtualizationStyle,
			}
		}

		// Inherits disabled if parent is disabled
		if (!disabled && !isNil(component.disabled))
			disabled = !!getDataFromDataValue(component.disabled, ownProps.contextData)

		// Inherits readOnly if parent is readOnly
		if (!readOnly && !isNil(component.readOnly))
			readOnly = !!getDataFromDataValue(component.readOnly, ownProps.contextData)

		if (!isNil(component.error)) error = !!getDataFromDataValue(component.error, ownProps.contextData)

		let ownData

		// DataValues as value
		if (
			ownProps.component.componentType !== e_UiComponentType.TEXT &&
			ownProps.component.componentType !== e_UiComponentType.IMAGE &&
			ownProps.component.componentType !== e_UiComponentType.LINEAR_PROGRESS &&
			ownProps.component.componentType !== e_UiComponentType.CIRCULAR_PROGRESS &&
			ownProps.component.componentType !== e_UiComponentType.GAUGE
		)
			ownData = getDataFromDataBinding({
				dataBinding: ownProps.component.value,
				contextData: ownProps.contextData,
			})

		let motionInValue = false
		if (component.motions?.[0]) {
			const motion = component.motions?.[0]
			if (motion.motionIn) motionInValue = !!getDataFromDataValue(motion.motionIn, ownProps.contextData)
		}

		return {
			component,
			componentId,
			styleProp: stylePropCache,
			visible,
			disabled,
			readOnly,
			error,
			tooltipTitle,
			tooltipDisableInteractive,
			tooltipLineBreak,
			ownData,
			motionInValue,

			conditionalClassNames,
		}
	}

	return mapStateToProps
}

export default withTheme(componentConnector(makeMapStateToProps)(UiComponent))
