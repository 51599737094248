import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'
import axios from 'axios'

import retry from '@appfarm/common/utils/retry'

import { setApp } from '#actions/metadataActions'

import generateElementFromItem from './generateElementFromItem'

const AppDescriptionLoader = ({ activeAppId, appController }) => {
	const appChecksum = useSelector((state) => state.metaData.wantedChecksums.app)
	const dispatch = useDispatch()

	/******************************************************************************
	 *
	 * Loading app description
	 *
	 *****************************************************************************/

	useEffect(() => {
		if (!activeAppId) return
		if (!appChecksum) return

		const controller = new AbortController()

		let customHeaderElements = []

		retry(
			(signal) =>
				axios.get(`/api/v1/apps/${activeAppId}/metadata/app?v=${appChecksum}`, {
					signal,
					timeout: 5000,
				}),
			{
				maxTries: 5,
				retryInterval: 1000,
				signal: controller.signal,
				timeout: 7500,
			}
		)
			.then((result) => {
				if (controller.signal?.aborted) return

				const app = result.data.app

				document.title = app.name
				Sentry.setTag('af.app.id', app.id)
				Sentry.setTag('af.app.name', app.name)

				if (app && app.customHeaderTags && app.customHeaderTags.length) {
					customHeaderElements = app.customHeaderTags.reduce(generateElementFromItem, [])
					customHeaderElements.forEach((element) => document.head.appendChild(element))
				}

				// Store a copy in redux.
				appController.setOrUpdateApp(result.data)
				dispatch(setApp(result.data))
			})
			.catch((err) => {
				if (axios.isCancel(err)) return
				Sentry.captureMessage('Failed to fetch app metadata', { extra: { err } })
			})

		return () => {
			customHeaderElements.forEach((element) => document.head.removeChild(element))
			controller.abort()
		}
	}, [activeAppId, appChecksum])

	return null
}

AppDescriptionLoader.propTypes = {
	activeAppId: PropTypes.string,
	appController: PropTypes.shape({
		setOrUpdateApp: PropTypes.func.isRequired,
	}),
}

export default AppDescriptionLoader
