import axios from 'axios'
import isString from 'lodash/isString'

import axiosErrorParser from '#modules/axiosErrorParser'
import e_FileTargetOptions from '@appfarm/common/enums/e_FileTargetOptions'

import getToken from './helpers/getTokenForActionNode'
import p_placeFileInDataSource from './helpers/placeFileInDataSource'

const zippyServiceHostname = window.AF_PARAMS.zippyServiceTarget
const endpointURL = `${zippyServiceHostname}/api/archive/v1/zip`

const p_createFileArchive = async ({
	actionNode,
	contextData,
	appController,
	actionNodeRunner,
	actionNodeLogger,
}) => {
	actionNodeLogger.info('Run ActionNode on Client', { payload: actionNode })

	const download = actionNode.fileTarget === e_FileTargetOptions.DOWNLOAD
	let targetDataSource
	if (!download) {
		if (!actionNode.dataSourceId) throw new Error('Could not create file - target dataSourceId is not set')
		targetDataSource = appController.getDataSource(actionNode.dataSourceId)
		if (!targetDataSource) throw new Error('Could not find dataSource for storing of file')
		if (!targetDataSource.isFileObjectClass) throw new Error('Cannot upload file into non-file ObjectClass')
	}

	let fileName = appController.getDataFromDataValue(actionNode.fileName, contextData)
	if (!fileName) fileName = 'filename.zip'
	if (!isString(fileName)) throw new Error('File Name must be a string')
	if (!fileName.endsWith('.zip')) fileName += '.zip'

	const pathDict = {}

	const files = actionNode.fileItems?.reduce((fileList, item) => {
		if (!item.dataSourceId) return fileList

		const dataSource = appController.getDataSource(item.dataSourceId)
		const allObjects = dataSource.getAllObjects()
		const isFileObjectClass = dataSource.isFileObjectClass
		if (allObjects?.length) {
			fileList.push(
				...allObjects.map((subItem) => {
					const contextDataForItem = { ...contextData, [item.dataSourceId]: [subItem] }

					let fileUrl = subItem.__fileContentLink

					if (!isFileObjectClass) {
						fileUrl = appController.getDataFromDataValue(item.fileContentUrl, contextDataForItem)
					}

					let fileName =
						item.fileName && appController.getDataFromDataValue(item.fileName, contextDataForItem)
					if (!fileName) {
						fileName = subItem.originalFileName || subItem._id // NB: we do not add extension on default / _id-name
					}
					const filePath = item.path && appController.getDataFromDataValue(item.path, contextDataForItem)

					if (pathDict[(filePath || '') + fileName]) {
						throw new Error('Duplicate filename found for same path - filenames should be unique')
					}

					pathDict[(filePath || '') + fileName] = true

					return {
						url: fileUrl,
						name: fileName,
						path: filePath,
					}
				})
			)
		}

		return fileList
	}, [])

	const rootActionId = actionNodeRunner.getRootAction().id
	const token = await getToken(appController, actionNode, rootActionId)

	const config = {
		url: endpointURL,
		method: 'post',
		responseType: 'blob',
		timeout: 60 * 1000,
		data: {
			options: {
				fileName: fileName,
			},
			files: files,
		},
		headers: { 'x-af-sol-token-v1': token },
	}

	let result
	try {
		result = await axios.request(config)
	} catch (err) {
		throw axiosErrorParser(err)
	}

	const file = new File([result.data], fileName, { type: 'application/x-zip' })
	if (download) {
		/**
		 * Download directly
		 */
		if (navigator.msSaveBlob) {
			// IE 10+
			navigator.msSaveBlob(file, fileName)
		} else {
			const link = document.createElement('a')
			const reader = new FileReader()
			reader.onload = function (e) {
				link.setAttribute('href', reader.result)
				link.setAttribute('download', fileName)
				link.style.visibility = 'hidden'
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
			}
			reader.readAsDataURL(file)
		}
	} else {
		/**
		 * Place in datasource
		 */
		return await p_placeFileInDataSource({
			dataSource: targetDataSource,
			actionNode,
			actionNodeLogger,
			actionNodeRunner,
			file,
			fileName,
			mimeType: 'application/x-zip',
			contextData,
		})
	}
}

export default p_createFileArchive
