import e_IteratorParamType from '@appfarm/common/enums/e_IteratorParamType'

const getIteratorParamsDataForIteration = (
	actionNode,
	{ indexForExec, iteratorCount, iteratorParamIdsInUse, logger }
) => {
	if (!iteratorParamIdsInUse || !Object.keys(iteratorParamIdsInUse)?.length) return {}

	return e_IteratorParamType.options
		.filter(
			(item) =>
				iteratorParamIdsInUse[`${actionNode.id}_${item.ident}`] &&
				item.nodeTypes.includes(actionNode.nodeType)
		)
		.reduce((params, item) => {
			const id = `${actionNode.id}_${item.ident}` // Do not changes! Used in create and inflator
			switch (item.ident) {
				case e_IteratorParamType.IS_FIRST_ITERATION: {
					params[id] = indexForExec === 0
					break
				}
				case e_IteratorParamType.IS_LAST_ITERATION: {
					params[id] = indexForExec + 1 === iteratorCount
					break
				}
				case e_IteratorParamType.INDEX: {
					params[id] = indexForExec
					break
				}
				default:
					logger.warning('Found unknown iteration param of type: ' + item.ident)
			}
			return params
		}, {})
}

export default getIteratorParamsDataForIteration
