import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { AnimatePresence } from 'framer-motion'
import { t } from '@lingui/macro'

import e_SwitchTransitionType from '@appfarm/common/enums/e_SwitchTransitionType'

import {
	getLoadedApp,
	getNormalViews,
	getDialogs,
	getAllDrawers,
	isInDevelopMode,
} from '#selectors/metadataSelectors'
import { handleEvent } from '#actions/eventActions'

import UiView from './uiComponents/UiView'
import NotFound from './NotFound'
import Drawers from './uiComponents/Drawers'
import appController from '../controllers/appControllerInstance'
import UiKeyboardShortcutsManager from './UiKeyboardShortcutsManager'
import OnBrowserHistoryChange from './OnBrowserHistoryChange'
import OnAppLoaded from './OnAppLoaded'
import OnVisibilityChange from './OnVisibilityChange'
import page_not_found from '../resources/404_page_not_found.svg'

const styles = {
	activeAppRoot: {
		flex: 1,
		display: 'flex',
		width: '100%',
		maxHeight: '100%',
		overflow: 'hidden',
	},
	'@media print': {
		activeAppRoot: {
			overflow: 'visible',
		},
	},
	viewContainer: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
	},
	devToolsEnabled: {
		// Reset fixed position parent to activeAppRoot for correct positioning when DevTools panel is open
		transform: 'rotate(0deg)',
	},
}

const LOG_ENABLED = window.AF_PARAMS && window.AF_PARAMS.enableClientLog
class ActiveApp extends Component {
	shouldComponentUpdate(nextProps) {
		if (nextProps.view !== this.props.view) return true
		if (nextProps.dialogs !== this.props.dialogs) return true
		if (nextProps.drawers !== this.props.drawers) return true
		if (nextProps.match !== this.props.match) return true
		if (nextProps.viewTransition !== this.props.viewTransition) return true
		if (nextProps.history !== this.props.history) return true
		return false
	}

	render() {
		const { view, dialogs, drawers, viewTransition, history, classes } = this.props

		if (!view) {
			return (
				<NotFound
					title={t`Page not found`}
					message={
						t`Sorry, but we cannot find the page you are looking for. Please check the spelling of the URL.` +
						'\n\n' +
						t`Contact support for further assistance.`
					}
					imagePath={page_not_found}
				/>
			)
		}

		let direction
		if (viewTransition === e_SwitchTransitionType.SLIDE)
			direction = history.action === 'PUSH' ? 'forward' : 'back'

		let mainContent = (
			<main className={classes.viewContainer}>
				<AnimatePresence initial={false} custom={direction}>
					<UiView key={view.id} view={view} direction={direction} viewTransition={viewTransition} />
				</AnimatePresence>
			</main>
		)

		if (drawers.length) mainContent = <Drawers drawers={drawers}>{ mainContent }</Drawers>

		return (
			<div className={classNames(classes.activeAppRoot, { [classes.devToolsEnabled]: LOG_ENABLED })}>
				{ mainContent }
				{ dialogs.map((dialog) => (
					<UiView key={dialog.id} view={dialog} />
				)) }
				<UiKeyboardShortcutsManager />
				<OnBrowserHistoryChange />
				<OnAppLoaded />
				<OnVisibilityChange />
			</div>
		)
	}
}

ActiveApp.propTypes = {
	view: PropTypes.object,
	dialogs: PropTypes.array.isRequired,
	drawers: PropTypes.array.isRequired,
	viewTransition: PropTypes.oneOf([
		e_SwitchTransitionType.NONE,
		e_SwitchTransitionType.FADE,
		e_SwitchTransitionType.SLIDE,
		e_SwitchTransitionType.ZOOM,
	]),
	onAppLoaded: PropTypes.object,
	eventHandler: PropTypes.func.isRequired,

	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
}

const makeMapStateToProps = () => {
	let view = null
	let cachedViewPath = null
	let cachedLanguageId = null
	let lastLayoutHash

	const mapStateToProps = (state, ownProps) => {
		const app = getLoadedApp(state)
		const views = getNormalViews(state)

		if (isInDevelopMode(state)) {
			const currentLayoutHash = state.metaData.loadedChecksums.layout

			if (lastLayoutHash !== currentLayoutHash) {
				lastLayoutHash = currentLayoutHash
				cachedViewPath = null
			}
		}

		const languageId = appController.getActiveLanguageId()
		if (cachedLanguageId !== languageId) {
			cachedLanguageId = languageId
			cachedViewPath = null
		}

		if (cachedViewPath !== ownProps.match.params.view) {
			if (ownProps.match.params.view) {
				view = views.find(
					(view) =>
						(view.path && view.path === ownProps.match.params.view) || view.id === ownProps.match.params.view
				)
			} else if (!ownProps.match.params.view) {
				view = views.find((view) => view.id === app.defaultViewId)
			}
			cachedViewPath = ownProps.match.params.view
		}

		return {
			view,
			dialogs: getDialogs(state),
			drawers: getAllDrawers(state),
			viewTransition: state.runtimeState.viewTransition,
			onAppLoaded: app.eventHandlers?.onAppLoaded,
		}
	}
	return mapStateToProps
}

const mapDispatchToProps = (dispatch) => ({
	eventHandler: (eventHandler, callbacks) => dispatch(handleEvent(eventHandler, {}, {}, callbacks)),
})

export default withStyles(styles)(withRouter(connect(makeMapStateToProps, mapDispatchToProps)(ActiveApp)))
