import React, { useCallback, useMemo, useRef, createContext, useContext, useEffect } from 'react'
import { VariableSizeList as List, type ListChildComponentProps } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'

const ESTIMATED_ITEM_SIZE = 36

interface DynamicListContextValue {
	setSize: (key: string, size: number, skipIfSizeIsSet?: boolean) => void
	outerProps: React.HTMLAttributes<HTMLElement>
}

const DynamicListContext = createContext<DynamicListContextValue>({} as any)

const Row = (props: ListChildComponentProps<any>) => {
	const { setSize } = useContext(DynamicListContext)
	const rowRoot = useRef<HTMLElement>(null)
	const { data, index } = props
	const itemKey = data[index].key

	useEffect(() => {
		setTimeout(() => {
			if (rowRoot.current) setSize(itemKey, rowRoot.current.getBoundingClientRect().height)
		}, 0)
	})

	const style = { ...props.style }
	if (style.height === ESTIMATED_ITEM_SIZE) style.height = 'fit-content'

	return React.cloneElement(data[index], {
		style,
		ref: rowRoot,
	})
}

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
	const { outerProps } = useContext(DynamicListContext)

	return <div ref={ref} {...outerProps} {...props} />
})
OuterElementType.displayName = 'OuterElementType'

const useResetCache = (data: unknown, callback: () => unknown) => {
	const ref = React.useRef<any>(null)
	React.useEffect(() => {
		callback()
		if (ref.current !== null) {
			ref.current.resetAfterIndex(0)
		}
	}, [data])
	return ref
}

const UiVirtualizedListBox = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
	(props, ref) => {
		const sizeMap = useRef(new Map<string, number>())
		const listRef = useResetCache(React.Children.count(props.children), () => sizeMap.current.clear())

		const setSize = useCallback(
			(key: string, size: number, skipIfSizeIsSet?: boolean) => {
				if (!sizeMap.current) return
				if (skipIfSizeIsSet && sizeMap.current.has(key)) return

				// Only update the sizeMap and reset cache if an actual value is changed
				if (sizeMap.current.get(key)?.toFixed(4) !== size?.toFixed(4)) {
					//only compare until the fourth decimal to avoid rendering issues
					sizeMap.current.set(key, size)
					// Clear cached data and rerender
					listRef.current?.resetAfterIndex(0)
				}
			},
			[sizeMap, listRef]
		)

		const [children, providerValue] = useMemo(() => {
			const { children, ...outerProps } = props
			return [
				children,
				{
					setSize,
					outerProps,
				} satisfies DynamicListContextValue,
			]
		}, [props, setSize])

		const itemData = React.Children.toArray(children) as React.ReactElement<
			any,
			string | React.JSXElementConstructor<any>
		>[]

		const getSize = useCallback(
			(index: number) => {
				const key = itemData[index].key!
				const size = sizeMap.current.get(key) || ESTIMATED_ITEM_SIZE
				return size
			},
			[itemData, sizeMap]
		)

		const initialHeight = useMemo(() => {
			if (itemData.length > 15) return 15 * ESTIMATED_ITEM_SIZE
			const size = itemData.map((node, index) => getSize(index)).reduce((a, b) => a + b, 5)
			return size
		}, [itemData, getSize])

		return (
			<div ref={ref} style={{ width: '100%', height: initialHeight }}>
				<DynamicListContext.Provider value={providerValue}>
					<AutoSizer>
						{ ({ height, width }) => (
							<List
								ref={listRef}
								width={width}
								height={height}
								itemData={itemData}
								itemCount={itemData.length}
								estimatedItemSize={ESTIMATED_ITEM_SIZE}
								itemSize={getSize}
								overscanCount={5}
								outerElementType={OuterElementType}
								innerElementType="ul"
							>
								{ Row }
							</List>
						) }
					</AutoSizer>
				</DynamicListContext.Provider>
			</div>
		)
	}
)
UiVirtualizedListBox.displayName = 'UiVirtualizedListBox'

export default UiVirtualizedListBox
