import e_EnableDisableMode from '@appfarm/common/enums/e_EnableDisableMode'
import NoSleep from '#utils/noSleep'

const noSleep = new NoSleep()
let noSleepEnabled = false

const p_preventDeviceSleep = ({ actionNode, actionNodeLogger }) => {
	switch (actionNode.toggleMode) {
		case e_EnableDisableMode.DISABLE:
			actionNodeLogger.debug('Disable device sleep prevention')
			noSleepEnabled = false
			noSleep.disable()
			break

		case e_EnableDisableMode.TOGGLE:
			noSleepEnabled = !noSleepEnabled
			actionNodeLogger.debug(`Toggle device sleep prevention: ${noSleepEnabled}`)
			if (noSleepEnabled) {
				noSleep.enable()
			} else {
				noSleep.disable()
			}
			break

		default:
			// ENABLE
			actionNodeLogger.debug('Enable device sleep prevention')
			noSleepEnabled = true
			noSleep.enable()
	}

	return Promise.resolve()
}

export default p_preventDeviceSleep
