import {
	isString,
	isPlainObject,
	isUndefined,
	isInteger,
	isFinite,
	isNil,
	isArray,
	last,
	cloneDeep,
	shuffle,
} from 'lodash'
import * as Sentry from '@sentry/browser'

import e_ActionNodeSelectionType from '@appfarm/common/enums/e_ActionNodeSelectionType'
import e_Cardinality from '@appfarm/common/enums/e_Cardinality'
import e_ObjectClassDataType from '@appfarm/common/enums/e_ObjectClassDataType'
import e_ReadObjectsOperation from '@appfarm/common/enums/e_ReadObjectsOperation'
import e_ObjectState from '@appfarm/common/enums/e_ObjectState'
import e_FilterTargetSelectionMode from '@appfarm/common/enums/e_FilterTargetSelectionMode'
import e_BuiltInDataSourceAttributeIds from '@appfarm/common/enums/e_BuiltInDataSourceAttributeIds'
import e_DataSourceChangeType from '@appfarm/common/enums/e_DataSourceChangeType'
import e_BuiltInObjectPropertyIds from '@appfarm/common/enums/e_BuiltInObjectPropertyIds'
import e_BuiltInObjectClassPropertyIds from '@appfarm/common/enums/e_BuiltInObjectClassPropertyIds'

import builtInCurrentUserGroupsDefinition from '@appfarm/common/builtins/builtInCurrentUserGroupsDefinition'
import builtInRuntimeStateDataDefinition from '@appfarm/common/builtins/builtInRuntimeStateDataDefinition'
import builtInUrlParamsDefinition from '@appfarm/common/builtins/builtInUrlParamsDefinition'
import builtInUrlPathDefinition from '@appfarm/common/builtins/builtInUrlPathDefinition'
import { resolvePropertyValues } from '@appfarm/common/utils/resolvePropertyValues'
import dataTypeParser from '@appfarm/common/utils/dataTypeParser'

import logger from '#logger/logger'
import { evaluateObjectWithFilterNode } from '#utils/filterEvaluator'
import { generateFilterFromGroupNode } from '#utils/filterGenerator'
import evaluateFunctionValue from '#utils/functionEvaluator'

import { getStaticPropertyMeta } from './builtInPropertiesFactory'
import { getStore } from '../../store/reduxStoreUtils'
import dayjs from '../dayjs'
import {
	setSelectionOnServer,
	modifyMultipleObjects as modifyMultipleObjectsOnServer,
	deleteObjectsOnServer,
	deleteFilteredObjectsOnServer,
	getSideEffects,
	setDataSourceDisabledOnServer,
	setSubscribeToUpdatesOnServer,
	setLimitOnServer,
	setSkipOnServer,
	readFilteredObjectsOnServer,
	modifyFilteredObjectsOnServer,
	getTotalObjectCount,
	getRefreshedData,
} from '../../modules/afClientApi'
import getDataOperationDepnendencyDescriptors from './getDataOperationDepnendencyDescriptors'
import dataSourceObjectSorter from './dataSourceObjectSorter'
import StorageEngineAdapter from './StorageEngineAdapter'
import objectGenerator from './objectGenerator'
import createObject from './createObject'
import modifyMultipleObjects from './modifyMultipleObjects'
import insertNewObjects from './insertNewObjects'
import getObjectsBySelectionType from './getObjectsBySelectionType'
import getObjectChanges from './getObjectChanges'
import getAttributeChanges from './getAttributeChanges'

const { ONE, MANY } = e_Cardinality
const { OBJECT_STATE } = e_BuiltInObjectPropertyIds
const {
	INITIAL_DATA,
	SELECTION_CHANGE,
	SORT_CHANGE,
	OBJECT_ADDED,
	OBJECT_MODIFIED,
	OBJECT_REMOVED,
	DATA_REPLACED,
} = e_DataSourceChangeType
const {
	DATA_READY,
	OBJECT_COUNT,
	SELECTED_OBJECTS_COUNT,
	LIMIT,
	SKIP,
	TOTAL_OBJECT_COUNT,
	IS_EMPTY,
	IS_NOT_EMPTY,
	HAS_SELECTED_OBJECTS,
	HAS_NO_SELECTED_OBJECTS,
	DISABLED,
	SUBSCRIBE_TO_UPDATES,
	SKIP_FUNCTION_PROPERTIES,
} = e_BuiltInDataSourceAttributeIds

class ClientDataSource {
	constructor(dataSourceMeta, appController, parentLogger) {
		// References
		this.__appController = appController
		this.__metadataController = appController.__metadataController
		this.__storageAdapter = new StorageEngineAdapter(dataSourceMeta.id)

		this.reduxStore = getStore()
		this.dispatch = this.reduxStore.dispatch
		if (parentLogger) {
			this.logger = parentLogger.createChildLogger({ prefix: 'ClientDataSource ' + dataSourceMeta.name })
		} else {
			this.logger = logger.createChildLogger({ prefix: 'ClientDataSource ' + dataSourceMeta.name })
			Sentry.captureMessage('No parentlogger found for ClientDataSource ' + dataSourceMeta.name)
		}

		// metadata
		this.id = dataSourceMeta.id
		this.setDataSourceModel(
			dataSourceMeta,
			this.__metadataController.getObjectClassMetadata(dataSourceMeta.objectClassId)
		)
		// this.referenceDataSources  -- populated by devtools metadata

		// data
		this.unfilteredDataDict = {}
		this.data = []
		this.dataDict = {}
		this.singleSelectedId = null
		this.selectionDictionary = {}

		// Runtime state.
		this.isDirty = true
		this.changeDescriptor = {
			[INITIAL_DATA]: true,
		} // detailed dirty flag

		this.dataReady = this.local ? true : false
		this.pendingSelectionSideEffects = false
		this.selectionSideEffectsReadySubs = []

		this.getCurrentUserId = this.getCurrentUserId.bind(this)
		this.getPropertiesForDebugTool = this.getPropertiesForDebugTool.bind(this)

		this._setDirtyFlag()
	}

	/**************************************************************************
	 *
	 * Init and metadata update
	 *
	 *************************************************************************/

	setDataSourceModel(dataSourceMeta, objectClassMetadata) {
		/**
		 * Datasource Metadata
		 */
		if (dataSourceMeta) {
			this.name = dataSourceMeta.name
			this.objectClassId = dataSourceMeta.objectClassId
			this.local = dataSourceMeta.local
			this.dataConnector = dataSourceMeta.dataConnector
			this.autoCreate = dataSourceMeta.autoCreate
			this.cardinality = dataSourceMeta.cardinality
			this.liveUpdate = dataSourceMeta.liveUpdate

			this.totalObjectCount = dataSourceMeta.totalObjectCount
			this.resultLimit = dataSourceMeta.resultLimit
			this.skip = dataSourceMeta.skip || 0
			this.sorting = dataSourceMeta.sorting
			this.sortNodeNameDict = {}

			this.staticFilter = dataSourceMeta.staticFilter
			this.filterDescriptor = dataSourceMeta.filterDescriptor
			this.conditionalFilter = dataSourceMeta.conditionalFilter
			if (this.conditionalFilter?.length) this.validFilterIds = []
			this.dataSourceDisabled = dataSourceMeta.dataSourceInitiallyDisabled
			this.skipFunctionProperties = dataSourceMeta.skipFunctionProperties

			this.reverseDependencies = dataSourceMeta.reverseDependencies || []
			this.functionCalculationOrder = dataSourceMeta.functionCalculationOrder || []
			this.localFunctionReverseDependencies = dataSourceMeta.localFunctionReverseDependencies || []
			this.hasDependenciesToSelfDataSource = dataSourceMeta.hasDependenciesToSelfDataSource
			this.clientFilterReverseDependencies = dataSourceMeta.clientFilterReverseDependencies || []
			this.clientFilterDependencies = dataSourceMeta.clientFilterDependencies || []

			this.dataSourceProperties = dataSourceMeta.properties || {}
			this.localProjection = dataSourceMeta.localProjection || {}
			this.propertiesMetaDict = {
				...dataSourceMeta.properties,
				...getStaticPropertyMeta({
					objectClassId: dataSourceMeta.objectClassId,
					isFileObjectClass: objectClassMetadata?.isFileObjectClass,
				}),
			}

			this.localPropertiesInUse = Object.values(this.propertiesMetaDict).filter(
				(item) => this.localProjection[item.nodeName]
			)
			this.referenceDataSources = dataSourceMeta.referenceDataSources
		}

		/**
		 * ObjectClass Metadata
		 */
		if (objectClassMetadata) {
			this.isFileObjectClass = objectClassMetadata.isFileObjectClass
			this.displayNameNodeName = objectClassMetadata.displayNameProperty?.nodeName
			this.storeCreatedBy = objectClassMetadata.storeCreatedBy
			this.storeUpdatedBy = objectClassMetadata.storeUpdatedBy
			this.storeRandomId = objectClassMetadata.storeRandomId

			this.propertiesMetaDict = {
				...this.propertiesMetaDict,
				...objectClassMetadata.getPropertyIdDictionary(),
			}

			this.persistableNodenameList = objectClassMetadata
				.getPropertyList()
				.filter((item) => !item.isFunction && item.nodeName !== '_id')
				.map((item) => item.nodeName)
		}

		/**
		 * Initial Calculations
		 * TODO: Ta med egenskaper som evt er injisert av devtools via
		 * setExtendMetadata
		 */
		this.propertiesMetadataInUseList = objectClassMetadata
			? objectClassMetadata.getPropertyList().concat(this.localPropertiesInUse)
			: this.localPropertiesInUse

		this.operationDependencyDescriptors = getDataOperationDepnendencyDescriptors(this.reverseDependencies)
		this.nodeNamesWithChangeDependencies = Object.keys(
			this.operationDependencyDescriptors.propertyChangeDescriptorDictionary
		)

		if (this.sorting)
			this.sortNodeNameDict = this.sorting.reduce((dict, item) => {
				dict[item.nodeName] = true
				return dict
			}, {})
	}

	initializeStorage(storageEngine) {
		if (storageEngine) this.__storageAdapter.attachStorageEngine(storageEngine)
	}

	setInitialData({ data, count }) {
		let initialData = data
		this.setTotalObjectCount(count)
		if (this.local && this.autoCreate && (!initialData || initialData.length === 0)) {
			initialData = [objectGenerator(this)]
		}

		if (isNil(initialData)) {
			this._setUnfilteredDataDict({})
			this._setAllObjects([], {})
			this.selectionDictionary = {}
			this.singleSelectedId = null
		} else {
			if (this.isFileObjectClass) {
				initialData = initialData.map((item) => {
					const extraProperties = this.generateShellObject()
					item = { ...item, ...extraProperties }
					// Populate url when inserted from cache
					if (item.__file) item.__fileContentLink = URL.createObjectURL(item.__file)
					return item
				})
			}

			if (this.conditionalFilter?.length) {
				const unfilteredDataDict = initialData.reduce((dataDict, dataItem) => {
					dataDict[dataItem._id] = dataItem
					return dataDict
				}, {})
				this._setUnfilteredDataDict(unfilteredDataDict)
				this._runFullClientFiltering()

				initialData = this.getAllObjects()
			}

			const finalDataDict = {}
			const finalData = initialData.map((item, index) => {
				item.__NOT_SELECTED__ = !item.__SELECTED__
				item.__SELECTED__ = !!item.__SELECTED__

				if (this.cardinality !== ONE) {
					if (index === 0) {
						item.__IS_FIRST_IN_DATA_SOURCE__ = true
					} else {
						item.__IS_FIRST_IN_DATA_SOURCE__ = false
					}

					if (index === initialData.length - 1) {
						item.__IS_LAST_IN_DATA_SOURCE__ = true
					} else {
						item.__IS_LAST_IN_DATA_SOURCE__ = false
					}

					if (index % 2 === 0) {
						item.__IS_EVEN_IN_DATA_SOURCE__ = true
					} else {
						item.__IS_EVEN_IN_DATA_SOURCE__ = false
					}

					item.__INDEX__ = index

					if (this.displayNameNodeName) item.__NAME__ = item[this.displayNameNodeName]
				}

				finalDataDict[item._id] = item
				return item
			})

			this.selectionDictionary = {}
			this.singleSelectedId = null
			this._setAllObjects(finalData, finalDataDict)
		}

		this.dataReady = true
		this._addChangeDescriptor(INITIAL_DATA)
	}

	init() {
		this._runFormulaRecalculation()
		this._runSorting()

		this._writeToRedux()
	}

	/**************************************************************************
	 *
	 * Render Control
	 *
	 *************************************************************************/

	_writeToRedux() {
		if (!this.isDirty) return
		if (!this.__appController) return
		if (!this.__appController.isRenderEnabled()) return
		this._setDirtyFlag()
		this.__appController.queueDataUpdate(this.id)
	}

	_setDirtyFlag() {
		this.isDirty = true
		this.changeDescriptor.pendingChange = true
	}

	_addChangeDescriptor(changeType, changedNodeNames = {}, affectedObjectIds = {}) {
		this.isDirty = true
		this.changeDescriptor.pendingChange = true

		if (!this.changeDescriptor[changeType]) {
			this.changeDescriptor[changeType] = {
				changedNodeNames,
				affectedObjectIds,
			}
		} else {
			this.changeDescriptor[changeType] = {
				changedNodeNames: {
					...this.changeDescriptor[changeType].changedNodeNames,
					...changedNodeNames,
				},
				affectedObjectIds: {
					...this.changeDescriptor[changeType].affectedObjectIds,
					...affectedObjectIds,
				},
			}
		}
	}

	readAndResetChangeDescriptor() {
		const changeDescriptor = this.changeDescriptor
		this.changeDescriptor = {
			pendingChange: false,
		}

		this.isDirty = false

		return changeDescriptor
	}

	/**************************************************************************
	 *
	 * Utils
	 *
	 *************************************************************************/

	_getDataStats() {
		return {
			numObjects: this.getAllObjects().length,
			numSelectedObjects: Object.keys(this.selectionDictionary).length,
		}
	}

	/**
	 * TODO: Denne har ingenting her å gjøre
	 */
	getCurrentUserId() {
		return this.__appController.getCurrentUserId()
	}

	/**************************************************************************
	 *
	 * Internal Setters - will not update redux
	 *
	 *************************************************************************/

	_setUnfilteredDataDict(unfilteredDataDict) {
		if (isNil(unfilteredDataDict)) return
		if (!this.conditionalFilter?.length) return

		this.unfilteredDataDict = unfilteredDataDict
	}

	_setAllObjects(data, dataDict) {
		if (!isNil(data)) this.data = data
		if (!isNil(dataDict)) this.dataDict = dataDict
	}

	_setObjectById(objectId, object, { isUnqualified } = {}) {
		if (isNil(objectId)) return // do nothing

		if (isUnqualified) {
			if (!this.conditionalFilter?.length) return // TODO should not happen

			delete this.dataDict[objectId]
			if (this.selectionDictionary[objectId]) {
				delete this.selectionDictionary[objectId]
				object.__SELECTED__ = false
				object.__NOT_SELECTED__ = true
			}
			this.unfilteredDataDict[objectId] = object
		} else if (isNil(object)) {
			// remove object
			delete this.dataDict[objectId]
			if (this.conditionalFilter?.length) delete this.unfilteredDataDict[objectId]
		} else {
			this.dataDict[objectId] = object
			if (this.conditionalFilter?.length) this.unfilteredDataDict[objectId] = object
		}
	}

	_checkQualificationAndSetObjectById(objectId, object) {
		if (!this.conditionalFilter?.length) {
			this._setObjectById(objectId, object)
			return true
		}

		if (isNil(objectId)) return true

		let isQualified = true
		if (!isNil(object)) isQualified = this._isObjectQualifiedByClientFiltering(object)
		this._setObjectById(objectId, object, { isUnqualified: !isQualified })
		return isQualified
	}

	_runBuiltInPropertyCalculation() {
		if (this.cardinality === ONE) return

		const displayNameNodeName = this.displayNameNodeName

		const existingData = this.getAllObjects()
		const newData = existingData.map((item, index) => {
			// handle First in DataSource
			if (index === 0) {
				item.__IS_FIRST_IN_DATA_SOURCE__ = true
			} else {
				item.__IS_FIRST_IN_DATA_SOURCE__ = false
			}

			// handle Last in DataSource
			if (index === existingData.length - 1) {
				item.__IS_LAST_IN_DATA_SOURCE__ = true
			} else {
				item.__IS_LAST_IN_DATA_SOURCE__ = false
			}

			// handle Even in DataSource
			if (index % 2 === 0) {
				item.__IS_EVEN_IN_DATA_SOURCE__ = true
			} else {
				item.__IS_EVEN_IN_DATA_SOURCE__ = false
			}

			if (displayNameNodeName) item.__NAME__ = item[displayNameNodeName]

			// handle index
			item.__INDEX__ = index
			this._setObjectById(item._id, item)

			return item
		})
		this._setAllObjects(newData)
	}

	// Tester ut denne greia her på modifyObjects. Burde nok blitt utvidet?
	disableSideEffectsCalculation() {
		this.sideEffectNotificationDisabled = true
	}

	// Tester ut denne greia her på modifyObjects
	enableAndExecutePendingSideEffects(dataSourceChangeType, changedNodeNames) {
		this.sideEffectNotificationDisabled = false

		if (this.pendingSelfSort) {
			this._runSorting()
			this.pendingSelfSort = false
		}

		if (this.pendingLocalDependencyNotification) {
			this.pendingLocalDependencyNotification = false
			this._notifyLocalDependencies(dataSourceChangeType, changedNodeNames)
		}
	}

	setAllObjectStates(newStateValue) {
		if (!this.local) return // object state only applicable on runtimeobjects

		const modifiedObjectIds = {}
		const newData = this.getAllObjects().reduce((newData, object) => {
			if (object[OBJECT_STATE] !== newStateValue) {
				modifiedObjectIds[object._id] = true
				const newObject = { ...object, [OBJECT_STATE]: newStateValue }

				const isQualified = this._checkQualificationAndSetObjectById(newObject._id, newObject)
				if (isQualified) newData.push(newObject)

				return newData
			}

			newData.push(object)
			return newData
		}, [])
		this._setAllObjects(newData)
		this._addChangeDescriptor(OBJECT_MODIFIED, { [OBJECT_STATE]: true }, modifiedObjectIds)
		this.__storageAdapter.onReplaceAll(newData)
	}

	setObjectStateForObjectIds(objectIds, newStateValue) {
		if (!this.local) return // object state only applicable on runtimeobjects

		const objectIdsDict = objectIds.reduce((dict, objectId) => {
			dict[objectId] = true
			return dict
		}, {})
		const modifiedObjectIds = {}

		const newData = this.getAllObjects().reduce((newData, object) => {
			if (objectIdsDict[object._id] && object[OBJECT_STATE] !== newStateValue) {
				modifiedObjectIds[object._id] = true
				const newObject = { ...object, [OBJECT_STATE]: newStateValue }

				const isQualified = this._checkQualificationAndSetObjectById(newObject._id, newObject)
				if (isQualified) newData.push(newObject)

				return newData
			}
			newData.push(object)
			return newData
		}, [])

		this._setAllObjects(newData)
		this._addChangeDescriptor(OBJECT_MODIFIED, { [OBJECT_STATE]: true }, modifiedObjectIds)
		this.__storageAdapter.onReplaceAll(newData)
	}

	setTotalObjectCount(objectCount) {
		this.totalObjectCount = objectCount
	}

	/******************************************************************************
	 *
	 * Methods for manipulating the content of the datasource
	 * No other methods should change items stored in memory
	 *
	 *****************************************************************************/

	// Private - just manipulate store
	_modifyObject(objectId, newData) {
		const oldObject = this.getObjectById(objectId)
		if (!oldObject) {
			// Sentry.captureException(
			// new Error(`Unable to modify object in Data Source ${this.name}. Could not find the object`)
			// )
			console.error(`Unable to modify object in Data Source ${this.name}. Could not find the object`)
			return
		}
		let changedObject = {
			...oldObject,
			...newData,
		}

		changedObject = this._recalculateSingleObjectFormula(changedObject)
		const { hasChanges, changedNodeNames } = getObjectChanges(oldObject, changedObject)

		if (hasChanges) {
			this._addChangeDescriptor(OBJECT_MODIFIED, changedNodeNames, { [objectId]: true })

			if (this.local) {
				const hasPersistableChanges = this.persistableNodenameList?.some(
					(nodeName) => changedNodeNames[nodeName]
				)

				if (hasPersistableChanges && changedObject[OBJECT_STATE] !== e_ObjectState.NEW) {
					// syncronized, set in changing state before storing changes
					changedObject[OBJECT_STATE] = e_ObjectState.UPDATED
					this._addChangeDescriptor(OBJECT_MODIFIED, { [OBJECT_STATE]: true }, { [objectId]: true })
				}
			}

			const isQualified = this._checkQualificationAndSetObjectById(objectId, changedObject)
			if (isQualified) {
				const newData = this.getAllObjects().map((item) => {
					if (item._id === objectId) return changedObject
					return item
				})
				this._setAllObjects(newData)
			} else {
				const newData = this.getAllObjects().filter((item) => item._id !== objectId)
				this._setAllObjects(newData)
				// needded by iterating container and other components that donot store nodename-dependencies
				this._addChangeDescriptor(OBJECT_REMOVED, changedNodeNames, { [objectId]: true })
			}

			// Run resort only if a sortable nodename was changed
			if (this.sorting && Object.keys(changedNodeNames).some((nodeName) => this.sortNodeNameDict[nodeName])) {
				if (this.sideEffectNotificationDisabled) {
					this.pendingSelfSort = true
				} else {
					this._runSorting()
				}
			}

			if (this.sideEffectNotificationDisabled) {
				this.pendingLocalDependencyNotification = true
			} else {
				this._notifyLocalDependencies(OBJECT_MODIFIED, changedNodeNames)
			}

			this._notifyClientFilterDependencies(OBJECT_MODIFIED, changedNodeNames)
			this.__storageAdapter.onObjectChanged(changedObject)
		}

		return changedObject
	}

	// Will remove object without notifying the server
	_removeObject(objectId) {
		const prevStats = this._getDataStats()

		const newData = this.getAllObjects().filter((item) => item._id !== objectId)
		this._setAllObjects(newData)
		this._setObjectById(objectId)

		delete this.selectionDictionary[objectId]
		if (this.singleSelectedId === objectId) {
			this.singleSelectedId = null
		} else {
			// Set single selected if there is now only one
			if (Object.keys(this.selectionDictionary).length === 1)
				this.singleSelectedId = Object.keys(this.selectionDictionary)[0]
		}

		// calculate changed attributes
		const newStats = this._getDataStats()
		const changedNodeNames = getAttributeChanges(prevStats, newStats)

		this._runBuiltInPropertyCalculation()
		this._notifyLocalDependencies(OBJECT_REMOVED, changedNodeNames)
		this._notifyClientFilterDependencies(OBJECT_REMOVED, changedNodeNames)
		this._addChangeDescriptor(OBJECT_REMOVED, changedNodeNames, { [objectId]: true })
		this.__storageAdapter.onObjectDeleted(objectId)
	}

	_removeMultipleObjects(objectIdArray) {
		const prevStats = this._getDataStats()
		const idDict = objectIdArray.reduce((idDict, objectId) => {
			idDict[objectId] = true

			this._setObjectById(objectId)
			delete this.selectionDictionary[objectId]
			return idDict
		}, {})

		const newData = this.getAllObjects().filter((item) => !idDict[item._id])
		this._setAllObjects(newData)

		if (Object.keys(this.selectionDictionary).length === 1) {
			this.singleSelectedId = Object.keys(this.selectionDictionary)[0]
		} else {
			this.singleSelectedId = null
		}

		// calculate changed attributes
		const newStats = this._getDataStats()
		const changedNodeNames = getAttributeChanges(prevStats, newStats)

		this._runBuiltInPropertyCalculation()
		this._notifyLocalDependencies(OBJECT_REMOVED, changedNodeNames)
		this._notifyClientFilterDependencies(OBJECT_REMOVED, changedNodeNames)
		this._addChangeDescriptor(OBJECT_REMOVED, changedNodeNames, idDict)
		this.__storageAdapter.onMultipleObjectsDeleted(objectIdArray)
	}

	// Insert or merge
	_insertObject(newObject) {
		newObject.__NOT_SELECTED__ = !newObject.__SELECTED__
		newObject.__SELECTED__ = !!newObject.__SELECTED__

		let dataSourceChangetype
		let changesForLocalDependencies
		const oldObject = this.getObjectById(newObject._id)
		if (oldObject) {
			let mergedObject
			const newData = this.getAllObjects().reduce((newData, item) => {
				if (item._id === newObject._id) {
					mergedObject = this._recalculateSingleObjectFormula({
						...item,
						...newObject,
					})

					const isQualified = this._checkQualificationAndSetObjectById(mergedObject._id, mergedObject)
					if (isQualified) newData.push(mergedObject)
					return newData
				}

				newData.push(item)
				return newData
			}, [])
			this._setAllObjects(newData)

			const { hasChanges, changedNodeNames } = getObjectChanges(oldObject, mergedObject)

			if (hasChanges) {
				this._addChangeDescriptor(OBJECT_MODIFIED, changedNodeNames, { [newObject._id]: true })
				this._notifyClientFilterDependencies(OBJECT_MODIFIED, changedNodeNames)
				dataSourceChangetype = OBJECT_MODIFIED
				changesForLocalDependencies = changedNodeNames
				this.__storageAdapter.onObjectChanged(mergedObject)
			}
		} else {
			const prevStats = this._getDataStats()

			newObject = this._recalculateSingleObjectFormula(newObject)

			const isQualified = this._checkQualificationAndSetObjectById(newObject._id, newObject)
			if (isQualified) this._setAllObjects([...this.getAllObjects(), newObject])

			if (newObject.__SELECTED__) {
				this.selectionDictionary[newObject._id] = true
				if (Object.keys(this.selectionDictionary).length === 1) this.singleSelectedId = newObject._id
			}

			const newStats = this._getDataStats()
			const changedNodeNames = getAttributeChanges(prevStats, newStats)

			this._addChangeDescriptor(OBJECT_ADDED, changedNodeNames, { [newObject._id]: true })
			this._notifyClientFilterDependencies(OBJECT_ADDED, changedNodeNames)
			dataSourceChangetype = OBJECT_ADDED
			changesForLocalDependencies = changedNodeNames
			this.__storageAdapter.onNewObject(newObject)
		}

		this._runSorting()
		this._runBuiltInPropertyCalculation()
		this._notifyLocalDependencies(dataSourceChangetype, changesForLocalDependencies)
	}

	/**
	 * Antar at data er rene. Altså ikke inneholder
	 * selection fra andre datakilder.
	 * TODO: Gjør mer smartness på denne. Changedescriptor kan være mer granulær
	 */
	_replaceAllObjects(objectArray, { mergeWithExistingObjects, skipLocalCalculations } = {}) {
		if (objectArray.length === 0 && this.getAllObjects().length === 0) {
			this._forceValid()
			return
		}

		if (mergeWithExistingObjects) {
			if (this.conditionalFilter?.length) {
				const unfilteredDataDict = objectArray.reduce((dataDict, item) => {
					const existingObject = this.getObjectById(item._id, { checkUnfiltered: true })
					if (existingObject) {
						item = {
							...existingObject,
							...item,
						}
					}

					dataDict[item._id] = item
					return dataDict
				}, {})
				this._setUnfilteredDataDict(unfilteredDataDict)
				this._runFullClientFiltering()
			} else {
				const newSelectionDict = {}
				const newDataDict = {}

				const newData = objectArray.map((dataItem) => {
					let item = { ...dataItem }

					const existingObject = this.getObjectById(item._id)
					if (existingObject) {
						item = {
							...existingObject,
							...dataItem,
						}
					}

					if (this.selectionDictionary[item._id]) {
						newSelectionDict[item._id] = true
						item.__SELECTED__ = true
						item.__NOT_SELECTED__ = false
					} else {
						item.__NOT_SELECTED__ = true
						item.__SELECTED__ = false
					}

					newDataDict[item._id] = item
					return item
				})
				this._setAllObjects(newData, newDataDict)

				if (Object.keys(newSelectionDict).length === 1) {
					this.singleSelectedId = Object.keys(newSelectionDict)[0]
				} else {
					this.singleSelectedId = null
				}

				this.selectionDictionary = newSelectionDict
			}
		} else {
			if (this.conditionalFilter?.length) {
				const unfilteredDataDict = objectArray.reduce((dataDict, item) => {
					dataDict[item._id] = item
					return dataDict
				}, {})
				this.singleSelectedId = null
				this.selectionDictionary = {}
				this._setUnfilteredDataDict(unfilteredDataDict)
				this._runFullClientFiltering()
			} else {
				const newDataDict = {}
				const newData = objectArray.map((dataItem) => {
					const object = {
						...dataItem,
						__NOT_SELECTED__: true,
						__SELECTED__: false,
					}
					newDataDict[object._id] = object
					return object
				})

				this._setAllObjects(newData, newDataDict)
				this.singleSelectedId = null
				this.selectionDictionary = {}
			}
		}

		if (!skipLocalCalculations) {
			this._runFormulaRecalculation()
			this._runSorting()
			this._runBuiltInPropertyCalculation()
			this._notifyLocalDependencies(DATA_REPLACED)
			this._notifyClientFilterDependencies(DATA_REPLACED) // Should maybe do this regardless
		}

		this._forceValid()
		this._addChangeDescriptor(DATA_REPLACED)
		this.__storageAdapter.onReplaceAll(objectArray)
	}

	_addOrMergeObjects(objectArray, { skipLocalCalculations } = {}) {
		if (!objectArray.length) return
		const prevStats = this._getDataStats()

		let objectsAdded = true
		let objectsMerged = false
		const addedObjectIds = {}
		const mergedObjectIds = {}
		let accumulatedChangedNodenames = {}
		const storageOperations = []

		// Her oppdaterer man this.dataDict for så å bruke denne til å sette this.data -> kan dette gjøres bedre / mer forstårlig
		objectArray.forEach((item) => {
			const oldObject = this.getObjectById(item._id)
			if (oldObject) {
				const newObject = this._recalculateSingleObjectFormula({
					...oldObject,
					...item,
				})
				if (this.selectionDictionary[newObject._id]) {
					newObject.__SELECTED__ = true
					newObject.__NOT_SELECTED__ = false
				} else {
					newObject.__NOT_SELECTED__ = true
					newObject.__SELECTED__ = false
				}
				this._checkQualificationAndSetObjectById(item._id, newObject)

				const { hasChanges, changedNodeNames } = getObjectChanges(oldObject, newObject)

				if (hasChanges) {
					objectsMerged = true
					mergedObjectIds[item._id] = true
					accumulatedChangedNodenames = {
						...accumulatedChangedNodenames,
						...changedNodeNames,
					}

					storageOperations.push({
						op: 'update',
						data: newObject,
					})
				}
			} else {
				// Not exists - add
				const objectForAdd = this._recalculateSingleObjectFormula(item)
				objectForAdd.__NOT_SELECTED__ = true
				objectForAdd.__SELECTED__ = false

				this._checkQualificationAndSetObjectById(item._id, objectForAdd)

				storageOperations.push({
					op: 'create',
					data: objectForAdd,
				})

				objectsAdded = true
				addedObjectIds[item._id] = true
			}
		})

		this.__storageAdapter.onMultiOperation(storageOperations)

		const newData = Object.values(this.getAllObjects({ asDictionary: true }))
		this._setAllObjects(newData)

		const newStats = this._getDataStats()
		const changedAttributes = getAttributeChanges(prevStats, newStats)

		if (!skipLocalCalculations) {
			this._runSorting()
			this._runBuiltInPropertyCalculation()

			const dataSourceChangeType = objectsAdded ? OBJECT_ADDED : objectsMerged ? OBJECT_MODIFIED : undefined
			const changedNodeNames =
				dataSourceChangeType === OBJECT_MODIFIED ? accumulatedChangedNodenames : undefined
			this._notifyLocalDependencies(dataSourceChangeType, changedNodeNames)
		}

		if (objectsAdded) {
			this._addChangeDescriptor(OBJECT_ADDED, changedAttributes, addedObjectIds)
			if (!skipLocalCalculations)
				// Should maybe do this regardless
				this._notifyClientFilterDependencies(OBJECT_ADDED, changedAttributes)
		}
		if (objectsMerged) {
			this._addChangeDescriptor(OBJECT_MODIFIED, accumulatedChangedNodenames, mergedObjectIds)
			if (!skipLocalCalculations)
				// Should maybe do this regardless
				this._notifyClientFilterDependencies(OBJECT_MODIFIED, accumulatedChangedNodenames)
		}
	}

	/******************************************************************************
	 *
	 * Sorting and Calculated fields
	 *
	 *****************************************************************************/

	_runSorting(customSortDescriptorArray, additionalSortDataDict) {
		if (this.cardinality === ONE) return
		const sortDescriptor = customSortDescriptorArray || this.sorting
		if (!sortDescriptor) return

		const { result, didSort } = dataSourceObjectSorter(
			this.getAllObjects(),
			sortDescriptor,
			additionalSortDataDict
		)

		if (didSort) {
			this._setAllObjects(result)
			this._addChangeDescriptor(SORT_CHANGE)
		}
	}

	_runFormulaRecalculation(visitedDataSources = {}, options = {}) {
		if (this.skipFunctionProperties) return
		if (!this.functionCalculationOrder.length) return // No formulas to calculate
		if (this.__appController.pendingFunctions) return

		// This datasource has allready been visited
		if (visitedDataSources[this.id]) return
		visitedDataSources[this.id] = true

		let dataDidChange = false
		const changedObjectIds = {}
		let accumulatedChangedNodenames = {}

		const propertyNamesForDebug = []
		const dependentFunctionValuesOnSelf = []
		const functionValuesForRecalculation = options.dataSourceChangeType // if change type is not provided -> run all function values
			? this.functionCalculationOrder.reduce((functionValuesForRecalculation, propertyId) => {
				const propertyMeta = this.propertiesMetaDict[propertyId]

				// function property not in use in app
				if (!propertyMeta) return functionValuesForRecalculation

				if (!propertyMeta.functionValue) return functionValuesForRecalculation

				let shouldRunRecalculation
				if (dependentFunctionValuesOnSelf.includes(propertyId)) {
					shouldRunRecalculation = true
				} else {
					const localFunctionDependencies = propertyMeta.localFunctionDependencies?.find(
						(item) => item.dataSourceId === options.dataSourceId
					)

					shouldRunRecalculation = localFunctionDependencies
						? this._shouldRunFunctionRecalculation({
							dataSourceChangeType: options.dataSourceChangeType,
							changedNodeNames: options.changedNodeNames,
							...localFunctionDependencies,
						})
						: false
				}

				if (shouldRunRecalculation) {
					functionValuesForRecalculation.push(propertyId)
					if (propertyMeta.reverseFunctionDependencies?.length)
						dependentFunctionValuesOnSelf.push(...propertyMeta.reverseFunctionDependencies)

					propertyNamesForDebug.push(propertyMeta.name)
				}

				return functionValuesForRecalculation
			}, [])
			: this.functionCalculationOrder

		if (!functionValuesForRecalculation?.length) return

		if (
			propertyNamesForDebug?.length &&
			functionValuesForRecalculation?.length !== this.functionCalculationOrder?.length
		)
			this.logger.debug(
				'Calculating function properties- ' + propertyNamesForDebug.join(', ') + ' -on ' + this.name
			)
		else this.logger.debug('Calculating all function properties on ' + this.name)

		const newData = this.getUnfilteredData().reduce((newData, item) => {
			const changedObject = this._recalculateSingleObjectFormula(item, {
				functionValuesForRecalculation,
			})
			const { hasChanges, changedNodeNames } = getObjectChanges(item, changedObject)

			if (hasChanges) {
				dataDidChange = true
				accumulatedChangedNodenames = {
					...accumulatedChangedNodenames,
					...changedNodeNames,
				}
				changedObjectIds[changedObject._id] = true

				const isQualified = this._checkQualificationAndSetObjectById(changedObject._id, changedObject)
				if (isQualified) newData.push(changedObject)
			} else {
				const isQualified = this._checkQualificationAndSetObjectById(item._id, item)
				if (isQualified) newData.push(item)
			}

			return newData
		}, [])
		this._setAllObjects(newData)

		if (dataDidChange) {
			if (!options.doNotNotifyDependencies) {
				if (this.localFunctionReverseDependencies.find((dependency) => dependency.dataSourceId === this.id)) {
					this.logger.warning(
						`Running full formula recalculation on Self after just recalcuated all formulas: ${this.name}`
					)
				}
				// NB: _notifyLocalDependencies here can cause infinite loops as it can call full formula calculation on self,
				// should not do this.
				this._notifyLocalDependencies(OBJECT_MODIFIED, accumulatedChangedNodenames, visitedDataSources)

				// calculate sideeffects
				if (
					this.reverseDependencies?.some((dependency) => accumulatedChangedNodenames[dependency.nodeName])
				) {
					const invalidatedDataSourceIdDict = this.__appController.invalidateDataSourcesById(
						this.reverseDependencies
							.filter((item) => accumulatedChangedNodenames[item.nodeName])
							.map((item) => item.dataSourceId),
						{ updateGui: true }
					)
					getSideEffects(this.id, { objectsModified: newData }, this.getDataForSynchronization())
						.then((sideEffects) => {
							// Apply side effects
							this.__appController.writeSideEffects(this, sideEffects, {
								logger: this.logger,
								invalidatedDataSourceIdDict,
							})
							this._writeToRedux()
						})
						.catch((err) =>
							this.logger.warning('Could not resolve dependencies after formula calculation', {
								payload: { err },
							})
						)
				}
			}
			this._addChangeDescriptor(OBJECT_MODIFIED, accumulatedChangedNodenames, changedObjectIds)
			this._notifyClientFilterDependencies(OBJECT_MODIFIED, accumulatedChangedNodenames)
		}
	}

	_recalculateSingleObjectFormula(
		object,
		{ functionValuesForRecalculation = this.functionCalculationOrder } = {}
	) {
		if (this.skipFunctionProperties) return object
		if (!functionValuesForRecalculation.length) return object
		if (this.__appController.pendingFunctions) return object // Functions not ready

		const calculatedObject = { ...object }

		functionValuesForRecalculation.forEach((propertyId) => {
			const propertyMeta = this.propertiesMetaDict[propertyId]

			// function property not in use in app
			if (!propertyMeta) return

			if (propertyMeta.functionValue) {
				calculatedObject[propertyMeta.nodeName] = dataTypeParser(
					evaluateFunctionValue({
						appController: this.__appController,
						contextData: { [this.id]: [calculatedObject] },
						functionValue: propertyMeta.functionValue,
						selfObject: calculatedObject,
					}),
					propertyMeta.dataType,
					{ dayjs }
				)
			}
		})

		return calculatedObject
	}

	_cleanObject(object) {
		return Object.keys(this.propertiesMetaDict).reduce(
			(cleanObject, propertyId) => {
				const propertyMeta = this.propertiesMetaDict[propertyId]
				const propertyDataType = propertyMeta.dataType

				const oldValue = object[propertyMeta.nodeName]
				if (isUndefined(oldValue)) return cleanObject
				if (oldValue === null) return cleanObject

				let newValue = oldValue
				if (
					propertyDataType === e_ObjectClassDataType.INTEGER ||
					propertyDataType === e_ObjectClassDataType.DURATION
				) {
					newValue = parseInt(oldValue, 10)
					if (!isInteger(newValue)) newValue = null
				} else if (propertyDataType === e_ObjectClassDataType.FLOAT) {
					if (isString(oldValue) && oldValue.indexOf(',') !== -1) newValue = oldValue.replace(',', '.')
					newValue = parseFloat(newValue)
					if (!isFinite(newValue)) newValue = null
				}

				cleanObject[propertyMeta.nodeName] = newValue

				return cleanObject
			},
			{ ...object }
		)
	}

	_shouldRunFunctionRecalculation({
		dataSourceChangeType,
		changedNodeNames,
		dataSourceId,
		nodeNames,
		changeTypes,
	}) {
		const defaultDependencyChangeTypes = {
			[DATA_REPLACED]: true,
			[INITIAL_DATA]: true,
			[SORT_CHANGE]: true,
			[OBJECT_MODIFIED]: true,
		}

		// if no dataSourceChangeType recalculate on all changes
		if (!dataSourceChangeType) return true

		// do not care about selection changes in self
		const isSelectionChangeInSelf = dataSourceChangeType === SELECTION_CHANGE && dataSourceId === this.id
		if (isSelectionChangeInSelf) return false

		const hasChangedNodeNames = changedNodeNames && Object.keys(changedNodeNames)?.length
		const hasDependencyNodeNames = nodeNames && Object.keys(nodeNames)?.length

		// if we have changed nodenames and dependency nodenames -> always recalc when changed dependency is ds attributes
		const someChangedDependencyNodeNameIsDsAttribute =
			hasChangedNodeNames &&
			hasDependencyNodeNames &&
			Object.values(e_BuiltInDataSourceAttributeIds).some(
				(attributeId) => nodeNames[attributeId] && changedNodeNames[attributeId]
			)
		if (someChangedDependencyNodeNameIsDsAttribute) return true

		// if we know changed nodenames and dependency nodenames --> for modified object only run recalculation if some dependency nodenames are changed
		if (dataSourceChangeType === OBJECT_MODIFIED) {
			if (hasChangedNodeNames && hasDependencyNodeNames) {
				const someDependencyNodeNameHasChanged = Object.keys(changedNodeNames).some((changedNodeName) =>
					Object.keys(nodeNames).includes(changedNodeName)
				)
				return someDependencyNodeNameHasChanged
			} else {
				return true // default to recalc if changed nodenames or dependency nodenames are not provided
			}
		}

		const dependencyChangeTypes = { ...defaultDependencyChangeTypes, ...changeTypes }

		// if selection count changes on removed object this is an indirect selectionchange
		if (dependencyChangeTypes[SELECTION_CHANGE]) {
			const isIndirectSelectionChange =
				dataSourceChangeType === OBJECT_REMOVED && changedNodeNames[SELECTED_OBJECTS_COUNT]
			if (isIndirectSelectionChange) return true
		}

		// check if we care about the change type.
		const isChangeTypeOfInterest = dependencyChangeTypes[dataSourceChangeType]
		if (isChangeTypeOfInterest) return true

		return false
	}

	_notifyLocalDependencies(dataSourceChangeType, changedNodeNames, visitedDataSources) {
		if (this.hasDependenciesToSelfDataSource) {
			// run full recalculation on self prior to notifying dependencie.
			this._runFormulaRecalculation(undefined, { doNotNotifyDependencies: true })
		}
		this.localFunctionReverseDependencies.forEach(({ dataSourceId, nodeNames, changeTypes }) => {
			const shouldRunRecalculation = this._shouldRunFunctionRecalculation({
				dataSourceChangeType,
				changedNodeNames,
				dataSourceId,
				nodeNames,
				changeTypes,
			})
			if (shouldRunRecalculation) {
				const dependentDataSource = this.__appController.getDataSource(dataSourceId)

				this.logger.time('Run Formula Recalculation: ' + dependentDataSource.name)
				dependentDataSource &&
					dependentDataSource._runFormulaRecalculation(visitedDataSources, {
						dataSourceId: this.id,
						dataSourceChangeType,
						changedNodeNames,
					})
				this.logger.timeEnd('Run Formula Recalculation: ' + dependentDataSource.name)
			}
		})
	}

	_notifyClientFilterDependencies(changeType, nodeNameDict, visitedDataSources) {
		if (changeType === SORT_CHANGE) return // nothing dependent on this

		this.clientFilterReverseDependencies.forEach((dataSourceId) => {
			const dependentDataSource = this.__appController.getDataSource(dataSourceId)
			dependentDataSource &&
				dependentDataSource.runClientFiltering({
					dataSourceId: this.id,
					changeType,
					nodeNameDict,
					visitedDataSources,
				})
		})
	}

	runClientFiltering({ dataSourceId, changeType, nodeNameDict, visitedDataSources = {} }) {
		if (changeType === SORT_CHANGE) return // nothing dependent on this

		// This datasource has allready been visited - is this a bit strict or ok?
		if (visitedDataSources[this.id]) {
			console.warn(
				`Data Source ${this.name} has already had client filters reevaluated and will not be evaluated again.`
			)
			return
		}
		visitedDataSources[this.id] = true

		const hasFilterChange = this.clientFilterDependencies.some(({ id, filterDependencies }) => {
			if (!this.validFilterIds.includes(id)) return false // only need to check for changes in filters which are in use

			return filterDependencies.some((item) => {
				if (item.dataSourceId !== dataSourceId) return false
				if ([INITIAL_DATA, DATA_REPLACED, OBJECT_REMOVED, OBJECT_ADDED].includes(changeType)) return true
				if (
					changeType === SELECTION_CHANGE &&
					[e_FilterTargetSelectionMode.SELECTED, e_FilterTargetSelectionMode.UNSELECTED].includes(
						item.selection
					)
				)
					return true
				// if (item.selection === e_FilterTargetSelectionMode.ALL) return true // hva om selected er CONTEXT? all er dekket av object_added og object_removed
				if (changeType === OBJECT_MODIFIED && nodeNameDict[item.nodeName]) return true
				return false
			})
		})

		let runFull = hasFilterChange
		const addedFilters = []
		const removedFilters = []
		if (!runFull) {
			this.clientFilterDependencies.forEach(({ id, conditionDependencies }, index) => {
				const rerunCondition = conditionDependencies.some((item) => {
					if (item.dataSourceId !== dataSourceId) return false
					if (changeType !== OBJECT_MODIFIED) return true
					if (changeType === OBJECT_MODIFIED && nodeNameDict[item.nodeName]) return true
					return false
				})
				if (rerunCondition) {
					const isValid = this.__appController.getDataFromDataValue(this.conditionalFilter[index].condition)
					const wasValid = this.validFilterIds.includes(id)
					if (isValid !== wasValid) {
						if (isValid) {
							addedFilters.push(this.conditionalFilter[index])
						} else {
							runFull = true
							removedFilters.push(this.conditionalFilter[index])
						}
					}
				}
			})
		}

		if (!runFull && !addedFilters?.length) return // No need to do anything

		if (runFull) {
			this._runFullClientFiltering()
		} else {
			this._applyClientFilters({ addedFilters })
		}

		this._runFormulaRecalculation()
		this._runSorting()
		this._runBuiltInPropertyCalculation()
		this._notifyLocalDependencies()
		this._notifyClientFilterDependencies(DATA_REPLACED, undefined, visitedDataSources)
		this._addChangeDescriptor(DATA_REPLACED)
		// this._writeToRedux()
	}

	_applyClientFilters({ addedFilters }) {
		let newData = this.getAllObjects()
		addedFilters.forEach((item) => {
			this.validFilterIds.push(item.id)
			if (item.filterDescriptor) {
				const filter = generateFilterFromGroupNode({
					filterDescriptorNode: item.filterDescriptor,
					appController: this.__appController,
				})
				newData = newData.filter((dataObject) => filter && evaluateObjectWithFilterNode(dataObject, filter))
			}
		})
		const newDataDict = newData.reduce((dataDict, item) => {
			dataDict[item._id] = item
			return dataDict
		}, {})

		// remove invalid selection
		const newSelectionDictionary = Object.keys(this.selectionDictionary).reduce(
			(newSelectionDict, objectId) => {
				if (newDataDict[objectId]) newSelectionDict[objectId] = true
				return newSelectionDict
			},
			{}
		)
		this.selectionDictionary = newSelectionDictionary
		if (Object.keys(newSelectionDictionary).length === 1) {
			this.singleSelectedId = Object.keys(newSelectionDictionary)[0]
		} else {
			this.singleSelectedId = null
		}
		this._setAllObjects(newData, newDataDict)
	}
	// bør man kjøre filtrering på alt av data og lagre id'er slik at man kan evaluere single conditions hver for seg
	// heller enn å evaluere alle på nytt dersom en endrer seg.
	// da kan man også spare noe på hvilke objekter som må reevalueres og sånn vell?

	// kjøres ved initial data setting, ved replace all data, ved endring i filtere
	_runFullClientFiltering() {
		// no data to filter -> just return
		if (!this.conditionalFilter?.length) return

		let newData = this.getUnfilteredData()
		if (!newData.length) {
			this._setAllObjects([], {})
			return
		}

		this.validFilterIds = []
		this.conditionalFilter
			.filter((item) => this.__appController.getDataFromDataValue(item.condition)) // er vel ingen context data her
			.forEach((item) => {
				this.validFilterIds.push(item.id)
				if (item.filterDescriptor) {
					const filter = generateFilterFromGroupNode({
						filterDescriptorNode: item.filterDescriptor,
						appController: this.__appController,
					})
					newData = newData.filter((dataObject) => filter && evaluateObjectWithFilterNode(dataObject, filter))
				}
			})

		const hasSelection = !!this.singleSelectedId || Object.keys(this.selectionDictionary)?.length
		if (hasSelection) {
			const newDataDict = {}
			const newSelectionDictionary = {}
			newData = newData.map((item) => {
				let dataItem = { ...item, __NOT_SELECTED__: true, __SELECTED__: false }

				if (this.selectionDictionary[dataItem._id]) {
					newSelectionDictionary[dataItem._id] = true
					dataItem = { ...dataItem, __NOT_SELECTED__: false, __SELECTED__: true }
				}

				newDataDict[dataItem._id] = dataItem
				return dataItem
			})

			this._setAllObjects(newData, newDataDict)
			this.selectionDictionary = newSelectionDictionary
			if (Object.keys(newSelectionDictionary).length === 1) {
				this.singleSelectedId = Object.keys(newSelectionDictionary)[0]
			} else {
				this.singleSelectedId = null
			}
		} else {
			const newDataDict = {}
			newData = newData.map((item) => {
				const dataItem = { ...item, __NOT_SELECTED__: true, __SELECTED__: false }
				newDataDict[dataItem._id] = dataItem
				return dataItem
			})

			this._setAllObjects(newData, newDataDict)

			// should not be necessary
			// this.singleSelectedId = null
			// this.selectionDictionary = {}
		}
	}

	// kjøres ved endring av et objekt, ved seleksjonsendring.
	_isObjectQualifiedByClientFiltering(object) {
		if (!this.conditionalFilter?.length) return true

		const isQualified = this.conditionalFilter
			.filter((item) => this.__appController.getDataFromDataValue(item.condition)) // er vel ingen context data her
			.every((item) => {
				if (!item.filterDescriptor) return true
				const filter = generateFilterFromGroupNode({
					filterDescriptorNode: item.filterDescriptor,
					appController: this.__appController,
				})
				if (!filter) return false

				return evaluateObjectWithFilterNode(object, filter)
			})
		return isQualified
	}

	_forceValid() {
		this.dataReady = true
	}

	_getDataFromDataOperationDescriptor(dataChangeDescriptor) {
		const { sendAllObjects, sendSelection, projection } = dataChangeDescriptor
		const projectionArray = projection ? Object.keys(projection) : []
		const objectsForSend = sendAllObjects ? this.getAllObjects() : this.getSelectedObjects()

		return objectsForSend.map((object) => {
			const projectedObject = {
				_id: object._id,
			}

			// Only needed by server when a dependency is selected og unselected
			// don't send __SELECTED__ if not true
			if (sendSelection && object.__SELECTED__) {
				projectedObject.__SELECTED__ = object.__SELECTED__
			}

			projectionArray.forEach((nodeName) => (projectedObject[nodeName] = object[nodeName]))
			return projectedObject
		})
	}

	/**************************************************************************
	 *
	 * Object Manipulation API
	 *
	 *************************************************************************/

	/**
	 * Method for use by controller when
	 * other dataSources has changed an object
	 *
	 * For use by appController. Vurderer å flytte denne.
	 */
	async p_incommingObjectChange(objectArray, logger = this.logger) {
		if (this.local) return // not interested - should not happen.
		if (this.dataSourceDisabled) return // if datasource is disabled, ds should not listen to changes.

		// ObjectArray should be cleaned

		/**
		 * Since we already have one object - lets see if changes are relevant
		 */
		const data = this.getAllObjects()
		if (this.cardinality === ONE && data.length) {
			let currentObject = data[0]

			const currentObjectId = currentObject._id
			const changedObject = objectArray.find((item) => item._id === currentObjectId)

			if (changedObject) {
				currentObject = {
					...currentObject,
					...changedObject,
				}
			}
			const currentObjectIsQualified = this.isObjectQualified(currentObject)
			const qualifiedIncommingObjects = objectArray.filter(
				(item) => this.isObjectQualified(item) && item._id !== currentObjectId
			)

			const cleanObject = (object) => {
				const cleanObject = {}
				Object.values(this.propertiesMetaDict).forEach((propertyDescription) => {
					if (!isUndefined(object[propertyDescription.nodeName]))
						cleanObject[propertyDescription.nodeName] = object[propertyDescription.nodeName]
				})
				return cleanObject
			}

			const p_resolveSideEffects = async (changeDescription) => {
				logger.debug('Getting side effects for ' + this.name)
				const selectionData = this.getDataForSynchronization()
				try {
					const sideEffects = await getSideEffects(this.id, changeDescription, selectionData)
					this.__appController.writeSideEffects(this, sideEffects, { logger })
				} catch (err) {
					logger.error('Failed to get sideffects', { err })
				}
			}

			if (this.sorting && qualifiedIncommingObjects.length) {
				// check if current object should be replaced
				const objectsForSorting = currentObjectIsQualified
					? [currentObject, ...qualifiedIncommingObjects]
					: qualifiedIncommingObjects
				const { result, didSort } = dataSourceObjectSorter(objectsForSorting, this.sorting)
				if (didSort || !currentObjectIsQualified) {
					// change current object with new qualified object
					const qualifiedObject = cleanObject(result[0])
					this._replaceAllObjects([qualifiedObject])

					const changeDescription = { objectsReplaced: [qualifiedObject] }
					await p_resolveSideEffects(changeDescription)

					return
				}
			} else if (!currentObjectIsQualified) {
				// object disqualified
				if (qualifiedIncommingObjects.length) {
					// replace with qualified object
					const qualifiedObject = cleanObject(qualifiedIncommingObjects[0])
					this._replaceAllObjects([qualifiedObject])

					const changeDescription = { objectsReplaced: [qualifiedObject] }
					await p_resolveSideEffects(changeDescription)
				} else {
					// refresh datasource to se if other qualified data exists
					await this.p_getAndSetRefreshedData()

					// no qualified objects found
					// this._replaceAllObjects([])

					// const changeDescription = { objectsRemoved: [currentObject] }
					// console.log('currentObject', currentObject)
					// resolveSideEffects(changeDescription)
				}
				return
			}

			// if object not replaced, check for changes
			if (changedObject) {
				// changes in current object
				const cleanChangeObject = cleanObject(changedObject)
				this._addOrMergeObjects([cleanChangeObject])

				const changeDescription = { objectsModified: [cleanChangeObject] }
				await p_resolveSideEffects(changeDescription)
			}
			return
		}

		const objectsForAdd = []
		const objectsForModification = []
		const objectsForRemoval = []
		let dataChangeWithDependency = false

		objectArray.forEach((item) => {
			const existingObject = this.getObjectById(item._id)
			const objectAllreadyExist = !!existingObject
			// Process objects. Merge with existing to have a better chance of
			// have enough properties to eval filter
			const objectForEval = objectAllreadyExist ? { ...existingObject, ...item } : { ...item }

			if (!objectAllreadyExist) {
				objectForEval.__SELECTED__ = false
				objectForEval.__NOT_SELECTED__ = true
			}

			const isQualified = this.isObjectQualified(objectForEval)

			if (isQualified) {
				if (objectAllreadyExist) {
					objectsForModification.push(objectForEval)

					// Check if changes actually has any effect
					this.nodeNamesWithChangeDependencies.forEach((nodeName) => {
						if (item[nodeName] !== existingObject[nodeName]) dataChangeWithDependency = true
					})
				} else {
					objectsForAdd.push(objectForEval)
				}
			} else {
				if (objectAllreadyExist) {
					objectsForRemoval.push(objectForEval)
				}
			}
		})

		let objectsForMerge = objectsForModification.concat(objectsForAdd)

		// check if limit exists and is exceeded, if exceeded has to adjust object count
		if (this.resultLimit) {
			const oldObjectCount = this.getAllObjects().length
			const newObjectCount = oldObjectCount + objectsForAdd.length - objectsForRemoval.length
			if (newObjectCount > this.resultLimit) {
				if (this.sorting) {
					const objectsForMergeDict = objectsForMerge.reduce((acc, item) => {
						acc[item._id] = item
						return acc
					}, {})
					const objectsForRemovalDict = objectsForRemoval.reduce((acc, item) => {
						acc[item._id] = item
						return acc
					}, {})
					const objectsForSorting = this.getAllObjects()
						.map((item) => {
							if (objectsForMergeDict[item._id]) return objectsForMergeDict[item._id]
							else if (objectsForRemovalDict[item._id]) return undefined
							else return item
						})
						.filter((item) => item)
						.concat(objectsForAdd)

					const { result, didSort } = dataSourceObjectSorter(objectsForSorting, this.sorting)
					if (didSort) {
						objectsForMerge = result
							.slice(0, this.resultLimit - 1)
							.filter((item) => objectsForMergeDict[item._id])
						const disqualifiedObjects = result.slice(this.resultLimit)
						objectsForRemoval.push(...disqualifiedObjects)
					} else {
						const excludedObjectCount = newObjectCount - this.resultLimit
						objectsForMerge = objectsForMerge.slice(0, -excludedObjectCount)
					}
				} else {
					const excludedObjectCount = newObjectCount - this.resultLimit
					objectsForMerge = objectsForMerge.slice(0, -excludedObjectCount)
				}
			} else if (newObjectCount < this.resultLimit && this.resultLimit === oldObjectCount) {
				// handle if count previously was on limit, but now is below limit -> there might be other objects qualified on server, have to refresh datasource
				await this.p_getAndSetRefreshedData()
				return
			}
		}

		let dataSourceChanged = false

		if (objectsForRemoval.length) {
			dataSourceChanged = true
			this._removeMultipleObjects(objectsForRemoval.map((item) => item._id))
		}

		if (objectsForMerge.length) {
			dataSourceChanged = true
			this._addOrMergeObjects(objectsForMerge)
		}

		await this.p_getAndSetTotalObjectCount()

		/** Her sier man ifra til server om clientside-endringer dersom det er sideeffekts eller man har added objekter. Burde nok også sendt med info dersom objectsRemoved.length > 0, men usikker på ringvirkningene av dette.
		 * MEN Man sier ikke ifra dersom man kun fjerner objekter. Dette kan føre til at server har objekter som ikke er kvalidisert lengre, og som ikke finnes på klienten lengre.
		 * Det kan f eks bli et problem dersom live update er slått på og objektet som kun finnes på server endres.
		 * Da vil man få en update melding til klienten der klienten ikke finner objektet, slik at svrApi_modifySingleObject() og _modifyObject() feiler.
		 * Eller endrer -> men endringene bryr man seg nok ikke om ettersom server kun holder på id'er.
		 */
		if (dataSourceChanged && (this.reverseDependencies.length || objectsForAdd.length)) {
			// Have dependencies - get side effects
			logger.debug('Getting side effects for ' + this.name)
			const changeDescription = {}
			if (objectsForAdd.length) changeDescription.objectsAdded = objectsForAdd
			if (objectsForModification.length) changeDescription.objectsModified = objectsForModification
			if (objectsForRemoval.length) changeDescription.objectsRemoved = objectsForRemoval

			// No side effects - only changes in non-filtered values
			if (!objectsForRemoval.length && !objectsForAdd.length && !dataChangeWithDependency) {
				logger.debug('Nothing is dependant on this change.')
				return
			}

			const selectionData = this.getDataForSynchronization()

			try {
				const sideEffects = await getSideEffects(this.id, changeDescription, selectionData)
				this.__appController.writeSideEffects(this, sideEffects, { logger })
			} catch (err) {
				logger.error('Failed to get sideeffects', { err })
			}
		}
	}

	async p_getAndSetRefreshedData() {
		const { data, count } = await getRefreshedData(this.id)
		this._replaceAllObjects(data, { mergeWithExistingObjects: true })
		this.setTotalObjectCount(count)
		this._writeToRedux()
	}

	/**
	 * This is for use by controls. For now. Maybe consolidate.
	 */
	modifySingleValueOptimistic({ dataBinding, oldObject, newValue, contextData = {}, next = () => {} }) {
		this.p_modifySingleValue({ dataBinding, oldObject, newValue, contextData })
			.then(next)
			.catch((err) => this.logger.error('Unable to update value: ', { err }))
	}

	modifySingleCardinalityObject(changeObject) {
		if (this.cardinality === MANY)
			throw new Error('Cannot use modifySingleCardinalityObject on multi cardinality dataSource')
		if (!this.local) throw new Error('Cannot use modifySingleCardinalityObject on non-local dataSource')
		const data = this.getAllObjects()
		if (!data?.length) throw new Error('Cannot modify. No object in dataSource')

		delete changeObject._id

		const cleanObject = this._cleanObject(changeObject)
		this._modifyObject(data[0]._id, cleanObject)
		this._writeToRedux()
	}

	/**
	 * Will only modify single object in store (will not resolve any dependencies)
	 */
	modifyObjectLocally(objectId, newData) {
		this._modifyObject(objectId, newData)
		this._writeToRedux()
	}

	/**
	 * Will modify a single value and resolve all dependencies
	 */
	p_modifySingleValue({ dataBinding, oldObject, newValue, contextData = {}, logger = this.logger }) {
		return new Promise((resolve, reject) => {
			const rejectWithRevert = (err) => {
				this._modifyObject(oldObject._id, oldObject)
				this._writeToRedux()
				reject(err)
			}

			if (!this.getObjectById(oldObject._id))
				return reject(new Error('Tried to write to a non-existing object'))

			// Cannot allow undefined as this datatype will never be
			// sent to the server/database
			if (isUndefined(newValue)) newValue = null

			let propertyDataType
			let readOnly = false

			// Get metadata
			const propertyMeta = this.propertiesMetaDict[dataBinding.propertyId]
			if (!propertyMeta) return reject(new Error('Unable to find property for write'))

			propertyDataType = propertyMeta.dataType
			readOnly = propertyMeta.readOnly

			// Sanity checks
			if (propertyMeta.isFunction) return reject(new Error('Tried to write to a function value property'))
			if (readOnly && !this.local) return reject(new Error('Tried to write to a read-only property'))

			// Parse value
			let newValueParsed = newValue
			if (newValue !== null) {
				if (
					propertyDataType === e_ObjectClassDataType.INTEGER ||
					propertyDataType === e_ObjectClassDataType.DURATION
				) {
					newValueParsed = parseInt(newValue, 10)
					if (!isInteger(newValueParsed)) newValueParsed = null
				} else if (propertyDataType === e_ObjectClassDataType.FLOAT) {
					if (isString(newValue) && newValue.indexOf(',') !== -1) newValue = newValue.replace(',', '.')
					newValueParsed = parseFloat(newValue)
					if (!isFinite(newValueParsed)) newValueParsed = null
				}
			}

			const newData = { [dataBinding.nodeName]: newValueParsed }
			// Set temporary updated date and by properties (will be overwritten on server)
			newData[e_BuiltInObjectClassPropertyIds.UPDATED_DATE] = new Date().toJSON()
			if (this.storeUpdatedBy) {
				newData[e_BuiltInObjectClassPropertyIds.UPDATED_BY] = this.getCurrentUserId()
			}

			const changedObject = this._modifyObject(oldObject._id, newData)
			const { changedNodeNames: changedNodeNamesDict } = getObjectChanges(oldObject, changedObject)

			const changedNodeNamesList = Object.keys(changedNodeNamesDict)

			if (this.local) {
				const notifyServer = this.reverseDependencies.some((item) => changedNodeNamesDict[item.nodeName])
				if (notifyServer) {
					const affectedDataSources = this.getAffectedDataSourcesFromChangedNodeNames(changedNodeNamesList)
					const invalidatedDataSourceIdDict = this.__appController.invalidateDataSourcesById(
						affectedDataSources,
						{ updateGui: true }
					)
					const selectionDataForServer = this.getDataForPropertyChange(changedNodeNamesList)

					return modifyMultipleObjectsOnServer(
						this.id,
						[changedObject],
						selectionDataForServer,
						changedNodeNamesList
					)
						.then((sideEffects) => {
							if (this.reverseDependencies && this.reverseDependencies.length) {
								logger.debug('Side effects for ' + this.name, { payload: sideEffects })
								this.__appController.writeSideEffects(this, sideEffects, {
									logger,
									invalidatedDataSourceIdDict,
								})
							}

							this._writeToRedux()
							resolve()
						})
						.catch(rejectWithRevert)
				}
				this._writeToRedux()
				resolve()
			} else {
				// Datasource is Filtered. Need to resolve more stuffz.

				// Update object in context if changed
				if (
					contextData[this.id] &&
					contextData[this.id].length &&
					contextData[this.id][0]._id === changedObject._id
				) {
					contextData[this.id] = [changedObject]
				}

				const isQualified = this.isObjectQualified(changedObject, contextData)
				if (!isQualified) this._removeObject(changedObject._id)

				if (propertyMeta && propertyMeta.runtime) {
					// Runtime without dependencies
					if (!this.reverseDependencies.some((dependency) => dependency.propertyId === propertyMeta.id)) {
						// Notify other active dataSources of the change
						return this.__appController
							.p_notifyObjectChange({
								sourceDataSourceId: this.id,
								changedObject: changedObject,
								objectClassId: this.objectClassId,
								// TODO: No need to update any of the dataources in the dependency tree
								dataSourceExceptions: this.reverseDependencies.map((item) => item.dataSourceId),
							})
							.then(() => {
								this._writeToRedux()
								resolve()
							})
					}
				}

				const affectedDataSources = this.getAffectedDataSourcesFromChangedNodeNames(changedNodeNamesList)
				const invalidatedDataSourceIdDict = this.__appController.invalidateDataSourcesById(
					affectedDataSources,
					{ updateGui: true }
				)
				const selectionDataForServer = this.getDataForPropertyChange(changedNodeNamesList)

				modifyMultipleObjectsOnServer(this.id, [changedObject], selectionDataForServer, changedNodeNamesList)
					.then((sideEffects) => {
						let updatedObject = changedObject
						if (sideEffects && sideEffects[this.id]) {
							const ownSideEffects = {
								[this.id]: { ...sideEffects[this.id] },
							}
							delete sideEffects[this.id]
							logger.debug('Got return data from server ' + this.name, { payload: ownSideEffects })

							const dataFromServer = ownSideEffects.data?.[0]
							updatedObject = { ...changedObject, ...dataFromServer }

							this.__appController.writeSideEffects(this, ownSideEffects, {
								logger,
								invalidatedDataSourceIdDict,
								addOrMergeObjects: true,
							})
						}

						if (this.reverseDependencies && this.reverseDependencies.length) {
							logger.debug('Side effects for ' + this.name, { payload: sideEffects })
							this.__appController.writeSideEffects(this, sideEffects, {
								logger,
								invalidatedDataSourceIdDict,
							})
						}

						// Notify other active dataSources of the change
						// No need to notify dependencies - these will be updated anyways
						return this.__appController.p_notifyObjectChange({
							sourceDataSourceId: this.id,
							changedObject: updatedObject,
							objectClassId: this.objectClassId,
							// TODO: No need to update any of the dataources in the dependency tree
							dataSourceExceptions: this.reverseDependencies.map((item) => item.dataSourceId),
						})
					})
					.then(resolve)
					.catch(rejectWithRevert)
			}
		})
	}

	p_modifyFilteredObjects({
		logger = this.logger,
		contextData,
		selectionType,
		staticFilter,
		filterDescriptor,
		propertyValues,
		actionId,
		actionNodeId,
	}) {
		return new Promise((resolve, reject) => {
			if (!this.dataConnector) {
				reject(new Error('Tried to modify filtered objects from a Data Source that is not a connector.'))
			}

			if (!actionId || !actionNodeId)
				reject(new Error('Tried to modify filtered objects without providing an actionId or actionNodeId'))

			if (!this.staticFilter && !this.filterDescriptor) {
				logger.debug('No basefilter defined -> No objects to update')
				resolve()
			}

			if (!propertyValues.length) return reject(new Error('ModifyObject: No modifications specified'))

			let filter
			switch (selectionType) {
				case e_ActionNodeSelectionType.ALL:
					filter = {}
					break
				case e_ActionNodeSelectionType.FILTERED: {
					if (staticFilter) filter = staticFilter
					else
						filter = generateFilterFromGroupNode({
							filterDescriptorNode: filterDescriptor,
							contextData,
							appController: this.__appController,
						})
					break
				}
				default:
					reject(
						new Error(
							`Unable to get filter for p_modifyFilteredObjects - invalid selectionType ${selectionType}`
						)
					)
			}

			// no filter -> no objects -> just return
			if (!filter) {
				logger.debug('No filter defined -> No objects to update')
				resolve()
			}

			const allProperties = this.propertiesMetaDict

			// Sanity check
			propertyValues.forEach((propertyValue) => {
				const propertyMeta = allProperties[propertyValue.propertyId]
				if (propertyMeta && propertyMeta.readOnly)
					throw new Error('Cannot modify readOnly property: ' + propertyMeta.name)
			})

			const newValues = resolvePropertyValues({
				propertyValues: propertyValues,
				propertyDict: allProperties,
				contextData: contextData,
				getDataFromDataValue: this.__appController.getDataFromDataValue,
				getEnumeratedType: this.__appController.getEnumeratedType,
				logger,
			})

			const changedNodeNames = propertyValues.map((propertyValue) => propertyValue.nodeName)

			if (changedNodeNames.includes('_id')) {
				reject(new Error('Unable to modify filtered objects - _id exists among changes'))
			}

			modifyFilteredObjectsOnServer(this.id, filter, newValues, changedNodeNames, {
				contextData,
				actionNodeId,
				actionId,
			})
				.then(({ updatedCount }) => {
					let completeFilter
					if (this.staticFilter) completeFilter = this.staticFilter
					else
						completeFilter = generateFilterFromGroupNode({
							filterDescriptorNode: this.filterDescriptor,
							contextData,
							appController: this.__appController,
						})
					if (Object.keys(filter).length) {
						if (Object.keys(completeFilter).length) completeFilter = { $and: [completeFilter, filter] }
						else completeFilter = filter
					}

					if (updatedCount > 0) logger.debug(`Updated ${updatedCount} objects`)
					else if (updatedCount === 0) logger.debug('No objects updated')

					logger.debug('Filtered objects updated with changes')
					logger.table(newValues, null, { dataSourceId: this.id })

					// Notify other datasources of same objectclass
					return this.__appController.p_notifyObjectChange({
						sourceDataSourceId: this.id,
						filter: completeFilter,
						changes: newValues,
						objectClassId: this.objectClassId,
					})
				})
				.then(resolve)
				.catch(reject)
		})
	}

	p_modifyMultipleObjects({ logger = this.logger, ...options }) {
		if (this.dataConnector) {
			return this.p_modifyFilteredObjects({ logger, ...options })
		}
		return modifyMultipleObjects({ dataSource: this, logger, ...options })
	}

	async p_getAndSetTotalObjectCount() {
		if (this.local) return // not applicable
		// no need to maintain total object count if not in use
		if (!this.localProjection[TOTAL_OBJECT_COUNT]) return

		const count = await getTotalObjectCount(this.id)
		if (count !== this.totalObjectCount) {
			this.setTotalObjectCount(count)
			this._addChangeDescriptor(OBJECT_MODIFIED, { [TOTAL_OBJECT_COUNT]: true })
			this._writeToRedux()
		}
	}

	p_createObject({
		defaultValues,
		contextData,
		setSelectedAfterCreate,
		replaceSingleObject,
		numCopies,
		logger = this.logger,
	}) {
		return createObject({
			dataSource: this,
			defaultValues,
			contextData,
			setSelectedAfterCreate,
			replaceSingleObject,
			numCopies,
			logger,
		})
	}

	/**
	 * Used in Duplicate objects actionnode
	 * Objects are generated and validated in the actionnode
	 * This method inserts objects and resolves sideeffects
	 */
	p_insertNewObjects({ setSelectedAfterCreate, replaceObjects, newObjects, logger = this.logger }) {
		return insertNewObjects({
			dataSource: this,
			setSelectedAfterCreate,
			replaceObjects,
			newObjects,
			logger,
		})
	}

	p_deleteFilteredObjects({
		selectionType,
		staticFilter,
		filterDescriptor,
		contextData,
		logger = this.logger,
		actionNodeId,
		actionId,
	} = {}) {
		return new Promise((resolve, reject) => {
			if (!this.dataConnector) {
				reject(new Error('Tried to delete filtered objects from a Data Source that is not a connector.'))
			}

			if (!actionId || !actionNodeId)
				reject(new Error('Tried to delete filtered objects without providing an actionId or actionNodeId'))

			if (!this.staticFilter && !this.filterDescriptor) {
				logger.debug('No basefilter defined -> No objects to delete')
				resolve()
			}

			let filter
			switch (selectionType) {
				case e_ActionNodeSelectionType.ALL:
					filter = {}
					break
				case e_ActionNodeSelectionType.FILTERED: {
					if (staticFilter) filter = staticFilter
					else
						filter = generateFilterFromGroupNode({
							filterDescriptorNode: filterDescriptor,
							contextData,
							appController: this.__appController,
						})
					break
				}
				default:
					reject(
						new Error(
							`Unable to get filter for p_deleteFilteredObjects - invalid selectionType ${selectionType}`
						)
					)
			}

			// no filter -> no objects -> just return
			if (!filter) {
				logger.debug('No filter defined -> No objects to delete')
				resolve()
			}

			deleteFilteredObjectsOnServer(this.id, filter, { contextData, actionId, actionNodeId })
				.then(({ cascadeDeletions, objectIds, deletedCount }) => {
					let completeFilter
					if (!objectIds) {
						if (this.staticFilter) completeFilter = this.staticFilter
						else
							completeFilter = generateFilterFromGroupNode({
								filterDescriptorNode: this.filterDescriptor,
								contextData,
								appController: this.__appController,
							})
						if (Object.keys(filter).length) {
							if (Object.keys(completeFilter).length) completeFilter = { $and: [completeFilter, filter] }
							else completeFilter = filter
						}
					}

					if (deletedCount > 0) logger.debug(`Deleted ${deletedCount} objects`)
					else if (deletedCount === 0) logger.debug('No objects deleted')

					if (objectIds?.length) {
						logger.debug('Deleted objectIds')
						logger.table(
							objectIds.map((_id) => ({
								_id,
							})),
							null,
							{ dataSourceId: this.id }
						)
					}

					// Cascade deletions
					this.__appController.cascadeDeleteSideEffects(this, cascadeDeletions, logger)

					// Remove objects from datasources of same objectclass
					return this.__appController.p_notifyObjectDeletions({
						sourceDataSourceId: this.id,
						filter: completeFilter,
						objectIds: objectIds,
						objectClassId: this.objectClassId,
					})
				})
				.then(resolve)
				.catch(reject)
		})
	}

	p_deleteObjects({ objectIdsForDeletion = [], logger = this.logger } = {}) {
		return new Promise((resolve, reject) => {
			const data = this.getAllObjects()
			// No data anyway
			if (!data.length) return resolve()

			let objectsForDeletion = []

			if (this.cardinality === ONE) {
				// Ignore input - just delete the one object we have
				objectsForDeletion = [data[0]]
				// if Id of object is not provided as input, add so as to be able to notify of Object deletions,
				if (!objectIdsForDeletion.length) {
					objectIdsForDeletion.push(data[0]._id)
				}

				this._removeObject(data[0]._id)
				if (this.local && this.autoCreate) {
					this._insertObject(objectGenerator(this))

					if (this.reverseDependencies.length) {
						logger.warning(
							'Single Non-persistent DataSource with autoCreate AND dependencies -> ' + this.name
						)
						logger.warning('Unusual design pattern - dependencies will not be updated')
					}

					this._writeToRedux()
					return resolve()
				}
			} else {
				if (!objectIdsForDeletion.length) return resolve()

				objectsForDeletion = objectIdsForDeletion.reduce((objectsForDeletion, objectId) => {
					const object = this.getObjectById(objectId)
					if (object) objectsForDeletion.push(object)
					return objectsForDeletion
				}, [])

				this._removeMultipleObjects(objectIdsForDeletion)
			}

			// Update UI
			this._writeToRedux()

			// No dependencies
			if (this.local && !this.reverseDependencies.length) return resolve()

			// TODO: Implementere resolve på andre avhengige datakilder
			// som er påvirket av slettingen og cascade sletting
			const invalidatedDataSourceIdDict = this.__appController.invalidateDataSourcesById(
				this.reverseDependencies.map((item) => item.dataSourceId),
				{ updateGui: true }
			)

			let dataForServerFilter
			if (this.local) dataForServerFilter = this.getDataForContentChange()

			deleteObjectsOnServer(this.id, objectsForDeletion, dataForServerFilter)
				.then(({ cascadeDeletions, sideEffects }) => {
					// Side effects ->
					this.__appController.writeSideEffects(this, sideEffects, {
						logger,
						invalidatedDataSourceIdDict,
					})

					// Cascade deletions
					this.__appController.cascadeDeleteSideEffects(this, cascadeDeletions, logger)

					// Remove objects from other
					if (!this.local)
						return this.__appController.p_notifyObjectDeletions({
							sourceDataSourceId: this.id,
							objectIds: objectIdsForDeletion,
							objectClassId: this.objectClassId,
						})
					else return Promise.resolve()
				})
				.then(resolve)
				.catch((err) => {
					objectsForDeletion.forEach((item) => {
						this._insertObject(item)
					})
					this._writeToRedux()
					reject(err)
				})
		})
	}

	applyRandomSort() {
		this._setAllObjects(shuffle(this.getAllObjects()))
		this._addChangeDescriptor(SORT_CHANGE)
		this._runBuiltInPropertyCalculation()
		this._writeToRedux()
	}

	applyCustomSorting(sortDescriptorArray, additionalSortDataDict) {
		this._runSorting(sortDescriptorArray, additionalSortDataDict)
		this._runBuiltInPropertyCalculation()
		this._writeToRedux()
	}

	p_insertFileObject(fileObject, contextData = {}) {
		return new Promise((resolve, reject) => {
			if (!this.isFileObjectClass) return reject(new Error('This is not a file data source'))

			let isQualified = true
			if (!this.local) isQualified = this.isObjectQualified(fileObject, contextData)

			if (isQualified) {
				if (this.cardinality === ONE) {
					this._replaceAllObjects([fileObject])
				} else {
					this._insertObject(fileObject)
				}
				// vurdere å resolve avhengigheter

				this._writeToRedux()
			} else {
				logger.warning('The created object does not match the filter of the Data Source it was created in')
			}

			resolve()
		})
	}

	setFileUploadProgress(objectId, progressData) {
		if (!this.isFileObjectClass) return
		if (!this.getObjectById(objectId)) return

		this._modifyObject(objectId, { __uploadProgress: progressData })
		this._writeToRedux()
	}

	removeFailedUploadObject(objectId) {
		if (!this.getObjectById(objectId)) return

		this._removeObject(objectId)
	}

	setFileUploadComplete(fileObject) {
		if (!this.getObjectById(fileObject._id)) return

		this._modifyObject(fileObject._id, fileObject)

		return this.__appController.p_notifyObjectChange({
			sourceDataSourceId: this.id,
			objectClassId: this.objectClassId,
			changedObject: fileObject,
		})
	}

	modifyFileObject(objectId, dataUrl, fileBlob) {
		if (!this.isFileObjectClass) throw new Error('Tried to run modifyFileObject on non-file dataSource')

		if (!this.local) throw new Error('Unable to edit fileContent in persisted datasource')

		const oldObject = this.getObjectById(objectId)
		if (!oldObject) {
			Sentry.captureException(new Error('Could not find fileObject for edit'))
			return
		}
		// if (!oldObject.__file) return console.warn('Cannot change persisted file')

		this._modifyObject(objectId, {
			__file: fileBlob,
			__fileContentLink: dataUrl,
			__mimeType: fileBlob.type,
			__fileSize: fileBlob.size,
		})

		this._writeToRedux()
	}

	p_setDataSourceDisabled(disabled, logger = this.logger) {
		return new Promise((resolve, reject) => {
			if (this.local) return reject(new Error('Cannot disable/enable local data source.'))

			this.dataSourceDisabled = disabled

			setDataSourceDisabledOnServer(this.id, disabled)
				.then(({ result = {}, sideEffects }) => {
					const { data, count } = result
					this._replaceAllObjects(data)
					this.setTotalObjectCount(count)
					this.__appController.writeSideEffects(this, sideEffects, { logger })
					this._writeToRedux()
					resolve()
				})
				.catch((err) => {
					logger.error('Failed to resolve side effects', { err })
					reject(err)
				})
		})
	}

	p_setSubscribeToUpdates(liveUpdate, logger = this.logger) {
		return new Promise((resolve, reject) => {
			if (this.local) return reject(new Error('Cannot disable/enable local data source.'))

			this.liveUpdate = liveUpdate

			setSubscribeToUpdatesOnServer(this.id, liveUpdate)
				.then(({ result = {}, sideEffects }) => {
					const { data, count } = result

					if (data) {
						this._replaceAllObjects(data, { mergeWithExistingObjects: true })
						this.setTotalObjectCount(count)
					}
					if (sideEffects) this.__appController.writeSideEffects(this, sideEffects, { logger })

					if (data || sideEffects) this._writeToRedux()
					resolve()
				})
				.catch((err) => {
					logger.error('Failed to resolve side effects', { err })
					reject(err)
				})
		})
	}

	p_setLimit(limit, logger = this.logger) {
		return new Promise((resolve, reject) => {
			if (this.local) return reject(new Error('Cannot change limit on a local data source.'))

			this.resultLimit = limit

			setLimitOnServer(this.id, limit)
				.then(({ result = {}, sideEffects }) => {
					const { data, count } = result

					if (data) {
						this._replaceAllObjects(data)
						this.setTotalObjectCount(count)
					}
					if (sideEffects) this.__appController.writeSideEffects(this, sideEffects, { logger })

					if (data || sideEffects) this._writeToRedux()
					resolve()
				})
				.catch((err) => {
					logger.error('Failed to resolve side effects', { err })
					reject(err)
				})
		})
	}

	p_setSkip(skip, logger = this.logger) {
		return new Promise((resolve, reject) => {
			if (this.local) return reject(new Error('Cannot change skip on a local data source.'))

			this.skip = skip

			setSkipOnServer(this.id, skip)
				.then(({ result = {}, sideEffects }) => {
					const { data, count } = result

					if (data) {
						this._replaceAllObjects(data)
						this.setTotalObjectCount(count)
					}
					if (sideEffects) this.__appController.writeSideEffects(this, sideEffects, { logger })

					if (data || sideEffects) this._writeToRedux()
					resolve()
				})
				.catch((err) => {
					logger.error('Failed to resolve side effects', { err })
					reject(err)
				})
		})
	}

	setSkipFunctionProperties(skipFunctionProperties, logger = this.logger) {
		this.skipFunctionProperties = skipFunctionProperties

		if (!this.skipFunctionProperties) {
			this._runFormulaRecalculation()
		}

		this._addChangeDescriptor(OBJECT_MODIFIED, { [SKIP_FUNCTION_PROPERTIES]: true })
		this.__appController.notifyAttributeChange(this.id, SKIP_FUNCTION_PROPERTIES, skipFunctionProperties)
		this._writeToRedux()
	}

	p_setDataSourceAttributes({ dataSourceAttributeValues, logger = this.logger, contextData }) {
		if (!dataSourceAttributeValues?.length) return Promise.resolve()

		return new Promise((resolve, reject) =>
			Promise.all(
				dataSourceAttributeValues.map(async (attributeValue) => {
					const value = attributeValue.value
						? this.__appController.getDataFromDataValue(attributeValue.value, contextData)
						: attributeValue.value

					switch (attributeValue.propertyId) {
						case DISABLED: {
							return await this.p_setDataSourceDisabled(value, logger)
						}
						case SUBSCRIBE_TO_UPDATES: {
							return await this.p_setSubscribeToUpdates(value, logger)
						}
						case LIMIT: {
							return await this.p_setLimit(value, logger)
						}
						case SKIP: {
							return await this.p_setSkip(value, logger)
						}
						case SKIP_FUNCTION_PROPERTIES: {
							return this.setSkipFunctionProperties(value, logger)
						}
					}
				})
			)
				.then(resolve)
				.catch(reject)
		)
	}
	/********************************************************
	 * Read Object API - (really push into this)
	 ********************************************************/

	/**
	 * For use by readObjects actionNode
	 */
	p_insertReadObjects({ objects, sideEffects, readObjectOperation, logger = this.logger }) {
		return new Promise((resolve, reject) => {
			if (!this.local) return reject(new Error('Cannot read objects into persistent data source'))

			if (!objects.length) {
				if (readObjectOperation === e_ReadObjectsOperation.REPLACE) {
					this._replaceAllObjects([])
					this._writeToRedux()
				}
				return resolve()
			}

			if (this.cardinality === ONE && objects.length > 1)
				return reject(new Error('Tried to insert multiple objects in a single cardinality data source'))

			if (readObjectOperation === e_ReadObjectsOperation.REPLACE) {
				this._replaceAllObjects(objects)
			} else if (readObjectOperation === e_ReadObjectsOperation.ADD) {
				const data = this.getAllObjects()
				if (this.cardinality === ONE && data.length && data[0]._id !== objects[0]._id)
					return reject(new Error('Cannot add objects to a single cardinality datasource with objects in it'))

				this._addOrMergeObjects(objects)
			} else {
				return reject(
					new Error('Cannot insert read objects - unknown operation type. Must be ADD or REPLACE')
				)
			}

			if (sideEffects && Object.keys(sideEffects).length)
				this.__appController.writeSideEffects(this, sideEffects, {
					logger,
					addOrMergeObjects: readObjectOperation === e_ReadObjectsOperation.ADD,
				})

			this._writeToRedux()
			resolve()
		})
	}

	/**************************************************************************
	 *
	 * Selection API
	 *
	 *************************************************************************/

	setExactlyOneObjectSelectedOptimistically(objectId) {
		this.p_setExactlyOneObjectSelected(objectId)
			.then(() => {})
			.catch(() => {})
	}

	toggleSelectedOptimistically(objectId) {
		if (this.getAllObjects().length === 0) return //Should not happen
		const existingObject = this.getObjectById(objectId)
		if (!existingObject) {
			Sentry.captureException(new Error('Tried to toggle selection on a non existing object'))
			return
		}

		const prevStats = this._getDataStats()
		const newSelectionValue = !existingObject.__SELECTED__

		// Update data
		const newData = this.getAllObjects().map((item) => {
			if (item._id === objectId) {
				const newObject = {
					...item,
					__SELECTED__: newSelectionValue,
					__NOT_SELECTED__: !newSelectionValue,
				}

				this._setObjectById(objectId, newObject)
				return newObject
			}
			return item
		})
		this._setAllObjects(newData)

		// Update dicitonary
		if (newSelectionValue) {
			this.selectionDictionary[objectId] = true
		} else {
			delete this.selectionDictionary[objectId]
		}
		// Update single selected identificator
		if (Object.keys(this.selectionDictionary).length === 1) {
			this.singleSelectedId = Object.keys(this.selectionDictionary)[0]
		} else {
			this.singleSelectedId = null
		}

		// calculate changed attributes
		const newStats = this._getDataStats()
		const changedNodeNames = getAttributeChanges(prevStats, newStats, {
			__SELECTED__: true,
			__NOT_SELECTED__: true,
		})

		this.p_resolveSelectionDependencies()
			.then(() => {})
			.catch(() => {})

		this._addChangeDescriptor(SELECTION_CHANGE, changedNodeNames, { [objectId]: true })
		this._notifyLocalDependencies(SELECTION_CHANGE)
		this._notifyClientFilterDependencies(SELECTION_CHANGE, changedNodeNames)
		this._setDirtyFlag()
		this._writeToRedux()
	}

	setOneSelectionValueOptimistically(objectId, value) {
		if (this.getAllObjects().length === 0) return //Should not happen
		const objectForModification = this.getObjectById(objectId)
		if (!objectForModification) {
			Sentry.captureException(new Error('Tried to set selection on a non existing object'))
			return
		}
		if (objectForModification.__SELECTED__ === value) return // Already set
		this.toggleSelectedOptimistically(objectId)
	}

	p_toggleOneObjectInSelection(objectId, options = {}) {
		if (this.getAllObjects().length === 0) return Promise.resolve()

		if (this.selectionDictionary[objectId]) {
			// selected -> unselect
			return this.p_unselectOneObjectFromSelection(objectId, options)
		} else {
			// unselected -> selected
			return this.p_addOneObjectToSelection(objectId, options)
		}
	}

	p_unselectOneObjectFromSelection(objectId, options = {}) {
		if (!options.logger) options.logger = this.logger

		if (this.getAllObjects().length === 0) return Promise.resolve()

		const runSelection = (objectId) => {
			if (!this.getObjectById(objectId)) {
				options.logger.warning('Tried to unselect a non existing object')
				return Promise.resolve()
			}

			if (!this.selectionDictionary[objectId]) return Promise.resolve() // do nothing

			const prevNumSelectedObjects = Object.keys(this.selectionDictionary).length

			delete this.selectionDictionary[objectId]

			if (prevNumSelectedObjects === 1) {
				// clear single selected
				this.singleSelectedId = null
			}

			const affectedObjectIds = {
				[objectId]: true,
			}

			const newData = this.getAllObjects().map((item) => {
				let newObject = item
				if (item._id === objectId) {
					if (item.__SELECTED__) {
						newObject = {
							...item,
							__SELECTED__: false,
							__NOT_SELECTED__: true,
						}

						this._setObjectById(newObject._id, newObject)
					}
				}
				return newObject
			})
			this._setAllObjects(newData)

			const changedNodeNames = {
				__SELECTED__: true,
				__NOT_SELECTED__: true,
			}

			// Add changed attributes as well
			if (prevNumSelectedObjects === 1) {
				changedNodeNames[HAS_NO_SELECTED_OBJECTS] = true
				changedNodeNames[HAS_SELECTED_OBJECTS] = true
			}

			changedNodeNames[SELECTED_OBJECTS_COUNT] = true

			this._addChangeDescriptor(SELECTION_CHANGE, changedNodeNames, affectedObjectIds)
			this._notifyLocalDependencies(SELECTION_CHANGE)
			this._notifyClientFilterDependencies(SELECTION_CHANGE, changedNodeNames)

			if (!options.noUpdate) this._writeToRedux()
			return this.p_resolveSelectionDependencies(options)
		}

		if (this.pendingSelectionSideEffects) {
			return this._subscribeToSelectionReady(() => runSelection(objectId))
		} else {
			return runSelection(objectId)
		}
	}

	p_addOneObjectToSelection(objectId, options = {}) {
		if (!options.logger) options.logger = this.logger

		if (this.getAllObjects().length === 0) return Promise.resolve()

		const runSelection = (objectId) => {
			if (this.selectionDictionary[objectId]) return Promise.resolve()

			if (!this.getObjectById(objectId)) {
				options.logger.warning('Tried to select a non existing object')
				return Promise.resolve()
			}

			const prevNumSelectedObjects = Object.keys(this.selectionDictionary).length

			this.selectionDictionary[objectId] = true
			if (prevNumSelectedObjects === 0) {
				// single selected
				this.singleSelectedId = objectId
			}

			const affectedObjectIds = {
				[objectId]: true,
			}

			const newData = this.getAllObjects().map((item) => {
				let newObject = item
				if (item._id === objectId) {
					if (!item.__SELECTED__) {
						newObject = {
							...item,
							__SELECTED__: true,
							__NOT_SELECTED__: false,
						}

						this._setObjectById(newObject._id, newObject)
					}
				}
				return newObject
			})
			this._setAllObjects(newData)

			const changedNodeNames = {
				__SELECTED__: true,
				__NOT_SELECTED__: true,
			}

			// Add changed attributes as well
			if (prevNumSelectedObjects === 0) {
				changedNodeNames[HAS_NO_SELECTED_OBJECTS] = true
				changedNodeNames[HAS_SELECTED_OBJECTS] = true
			}

			changedNodeNames[SELECTED_OBJECTS_COUNT] = true

			this._addChangeDescriptor(SELECTION_CHANGE, changedNodeNames, affectedObjectIds)
			this._notifyLocalDependencies(SELECTION_CHANGE)
			this._notifyClientFilterDependencies(SELECTION_CHANGE, changedNodeNames)

			if (!options.noUpdate) this._writeToRedux()
			return this.p_resolveSelectionDependencies(options)
		}

		if (this.pendingSelectionSideEffects) {
			return this._subscribeToSelectionReady(() => runSelection(objectId))
		} else {
			return runSelection(objectId)
		}
	}

	p_setExactlyOneObjectSelected(objectId, options = {}) {
		if (!options.logger) options.logger = this.logger

		if (this.getAllObjects().length === 0) return Promise.resolve()

		const runSelection = (objectId) => {
			if (this.singleSelectedId === objectId) return Promise.resolve()
			if (!this.getObjectById(objectId)) {
				options.logger.warning('Tried to select a non existing object')
				return Promise.resolve()
			}

			const prevNumSelectedObjects = Object.keys(this.selectionDictionary).length

			this.singleSelectedId = objectId
			this.selectionDictionary = { [objectId]: true }

			const affectedObjectIds = {}

			const newData = this.getAllObjects().map((item) => {
				let newObject = item
				if (item._id === objectId) {
					if (!item.__SELECTED__) {
						newObject = {
							...item,
							__SELECTED__: true,
							__NOT_SELECTED__: false,
						}
						this._setObjectById(newObject._id, newObject)
						affectedObjectIds[newObject._id] = true
					}
				} else {
					if (item.__SELECTED__) {
						newObject = {
							...item,
							__SELECTED__: false,
							__NOT_SELECTED__: true,
						}
						this._setObjectById(newObject._id, newObject)
						affectedObjectIds[newObject._id] = true
					}
				}

				return newObject
			})
			this._setAllObjects(newData)

			const changedNodeNames = {
				__SELECTED__: true,
				__NOT_SELECTED__: true,
			}

			// Add changed attributes as well
			if (prevNumSelectedObjects !== 1) {
				if (prevNumSelectedObjects === 0) {
					changedNodeNames[HAS_NO_SELECTED_OBJECTS] = true
					changedNodeNames[HAS_SELECTED_OBJECTS] = true
				}

				changedNodeNames[SELECTED_OBJECTS_COUNT] = true
			}

			this._addChangeDescriptor(SELECTION_CHANGE, changedNodeNames, affectedObjectIds)
			this._notifyLocalDependencies(SELECTION_CHANGE)
			this._notifyClientFilterDependencies(SELECTION_CHANGE, changedNodeNames)

			if (!options.noUpdate) this._writeToRedux()
			return this.p_resolveSelectionDependencies(options)
		}

		if (this.pendingSelectionSideEffects) {
			return this._subscribeToSelectionReady(() => runSelection(objectId))
		} else {
			return runSelection(objectId)
		}
	}

	p_selectFirst() {
		const data = this.getAllObjects()
		if (data.length === 0) return Promise.resolve()
		return this.p_setExactlyOneObjectSelected(data[0]._id)
	}

	p_selectLast() {
		const data = this.getAllObjects()
		if (data.length === 0) return Promise.resolve()
		return this.p_setExactlyOneObjectSelected(last(data)._id)
	}

	p_selectNext() {
		const data = this.getAllObjects()
		if (!this.singleSelectedId) return Promise.resolve()
		if (data.length < 2) return Promise.resolve()
		const currentSelectedIndex = data.indexOf(this.getObjectById(this.singleSelectedId))
		if (currentSelectedIndex === data.length - 1) return Promise.resolve()
		const newIndex = currentSelectedIndex + 1
		return this.p_setExactlyOneObjectSelected(data[newIndex]._id)
	}

	p_selectPrevious() {
		const data = this.getAllObjects()
		if (!this.singleSelectedId) return Promise.resolve()
		if (data.length < 2) return Promise.resolve()
		const currentSelectedIndex = data.indexOf(this.getObjectById(this.singleSelectedId))
		if (currentSelectedIndex === 0) return Promise.resolve()
		const newIndex = currentSelectedIndex - 1
		return this.p_setExactlyOneObjectSelected(data[newIndex]._id)
	}

	p_selectAll() {
		if (this.getAllObjects().length === 0) return Promise.resolve()

		const runSelection = () => {
			if (Object.keys(this.selectionDictionary).length === this.getAllObjects().length)
				return Promise.resolve()

			const prevStats = this._getDataStats()
			const affectedObjectIds = {}

			const newData = this.getAllObjects().map((item) => {
				if (!item.__SELECTED__) {
					this.selectionDictionary[item._id] = true
					const selectedObject = {
						...item,
						__SELECTED__: true,
						__NOT_SELECTED__: false,
					}

					this._setObjectById(selectedObject._id, selectedObject)
					affectedObjectIds[item._id] = true
					return selectedObject
				}

				return item
			})
			this._setAllObjects(newData)

			if (newData.length === 1) {
				this.singleSelectedId = newData[0]._id
			} else {
				this.singleSelectedId = null
			}

			const newStats = this._getDataStats()
			const changedNodeNames = getAttributeChanges(prevStats, newStats, {
				__SELECTED__: true,
				__NOT_SELECTED__: true,
			})

			this._addChangeDescriptor(SELECTION_CHANGE, changedNodeNames, affectedObjectIds)
			this._notifyLocalDependencies(SELECTION_CHANGE)
			this._notifyClientFilterDependencies(SELECTION_CHANGE, changedNodeNames)

			this._writeToRedux()
			return this.p_resolveSelectionDependencies()
		}

		if (this.pendingSelectionSideEffects) {
			return this._subscribeToSelectionReady(runSelection)
		} else {
			return runSelection()
		}
	}

	p_selectNone() {
		if (this.getAllObjects().length === 0) return Promise.resolve()

		const runSelection = () => {
			if (Object.keys(this.selectionDictionary).length === 0) return Promise.resolve() // Already none selected
			const prevStats = this._getDataStats()
			const affectedObjectIds = {}

			const newData = this.getAllObjects().map((item) => {
				if (item.__SELECTED__) {
					const unselectedObject = {
						...item,
						__SELECTED__: false,
						__NOT_SELECTED__: true,
					}

					this._setObjectById(unselectedObject._id, unselectedObject)
					affectedObjectIds[item._id] = true
					return unselectedObject
				}
				return item
			})
			this._setAllObjects(newData)

			this.singleSelectedId = null
			this.selectionDictionary = {}

			const newStats = this._getDataStats()
			const changedNodeNames = getAttributeChanges(prevStats, newStats, {
				__SELECTED__: true,
				__NOT_SELECTED__: true,
			})

			this._addChangeDescriptor(SELECTION_CHANGE, changedNodeNames, affectedObjectIds)
			this._notifyLocalDependencies(SELECTION_CHANGE)
			this._notifyClientFilterDependencies(SELECTION_CHANGE, changedNodeNames)

			this._writeToRedux()
			return this.p_resolveSelectionDependencies()
		}

		if (this.pendingSelectionSideEffects) {
			return this._subscribeToSelectionReady(runSelection)
		} else {
			return runSelection()
		}
	}

	p_filteredDeselection({ staticFilter, filterDescriptor, contextData }) {
		const runSelection = (staticFilter, filterDescriptor, contextData) => {
			const objectsForDeselection = this.getObjectsBySelectionType({
				selectionType: e_ActionNodeSelectionType.FILTERED,
				staticFilter: staticFilter,
				filterDescriptor: filterDescriptor,
				contextData,
			})

			// None to de-selected
			if (
				!objectsForDeselection ||
				!objectsForDeselection.length ||
				!Object.keys(this.selectionDictionary).length
			) {
				return Promise.resolve()
			}

			// remove single
			if (objectsForDeselection.length === 1) {
				return this.p_unselectOneObjectFromSelection(objectsForDeselection[0]._id)
			}

			// many
			const prevStats = this._getDataStats()
			const affectedObjectIds = {}

			const unselectionDict = objectsForDeselection.reduce((dict, item) => {
				dict[item._id] = true
				return dict
			}, {})

			const newData = this.getAllObjects().map((item) => {
				let newItem = item
				if (unselectionDict[item._id]) {
					// de-select if selected
					if (item.__SELECTED__) {
						affectedObjectIds[item._id] = true
						newItem = {
							...item,
							__SELECTED__: false,
							__NOT_SELECTED__: true,
						}

						this._setObjectById(item._id, newItem)
						delete this.selectionDictionary[newItem._id]
					}
				}
				return newItem
			})
			this._setAllObjects(newData)

			// Update single selected identificator
			if (Object.keys(this.selectionDictionary).length === 1) {
				this.singleSelectedId = Object.keys(this.selectionDictionary)[0]
			} else {
				this.singleSelectedId = null
			}

			if (!Object.keys(affectedObjectIds).length) return Promise.resolve() // nothing has changed

			const newStats = this._getDataStats()
			const changedNodeNames = getAttributeChanges(prevStats, newStats, {
				__SELECTED__: true,
				__NOT_SELECTED__: true,
			})

			this._addChangeDescriptor(SELECTION_CHANGE, changedNodeNames, affectedObjectIds)
			this._notifyLocalDependencies(SELECTION_CHANGE)
			this._notifyClientFilterDependencies(SELECTION_CHANGE, changedNodeNames)

			this._writeToRedux()
			return this.p_resolveSelectionDependencies()
		}

		if (this.pendingSelectionSideEffects) {
			return this._subscribeToSelectionReady(() => runSelection(staticFilter, filterDescriptor, contextData))
		} else {
			return runSelection(staticFilter, filterDescriptor, contextData)
		}
	}

	p_filteredSelection({ staticFilter, filterDescriptor, contextData, keepExistingSelection }) {
		const runSelection = (staticFilter, filterDescriptor, contextData) => {
			const objectsForSelection = this.getObjectsBySelectionType({
				selectionType: e_ActionNodeSelectionType.FILTERED,
				staticFilter: staticFilter,
				filterDescriptor: filterDescriptor,
				contextData,
			})

			// None selected
			if (!objectsForSelection || !objectsForSelection.length) {
				if (!keepExistingSelection) return this.p_selectNone()
				else return Promise.resolve() // do nothing
			}

			// Single selection
			if (
				(!keepExistingSelection || !Object.keys(this.selectionDictionary).length) &&
				objectsForSelection.length === 1
			)
				return this.p_setExactlyOneObjectSelected(objectsForSelection[0]._id)

			// More than one
			const prevStats = this._getDataStats()
			const affectedObjectIds = {}

			const selectionDict = objectsForSelection.reduce((dict, item) => {
				dict[item._id] = true
				return dict
			}, {})

			if (!keepExistingSelection) this.selectionDictionary = {}

			const newData = this.getAllObjects().map((item) => {
				let newItem = item
				if (selectionDict[item._id]) {
					// Select if not already selected
					if (!item.__SELECTED__) {
						affectedObjectIds[item._id] = true
						newItem = {
							...item,
							__SELECTED__: true,
							__NOT_SELECTED__: false,
						}

						this._setObjectById(item._id, newItem)
					}

					this.selectionDictionary[newItem._id] = true
				} else {
					//de-select if not keep selection and item is selected
					if (!keepExistingSelection && newItem.__SELECTED__) {
						affectedObjectIds[item._id] = true
						newItem = {
							...item,
							__SELECTED__: false,
							__NOT_SELECTED__: true,
						}

						this._setObjectById(item._id, newItem)
					}
				}

				return newItem
			})
			this._setAllObjects(newData)

			// Update single selected identificator
			if (Object.keys(this.selectionDictionary).length === 1) {
				this.singleSelectedId = Object.keys(this.selectionDictionary)[0]
			} else {
				this.singleSelectedId = null
			}

			if (!Object.keys(affectedObjectIds).length) return Promise.resolve() // nothing has change

			const newStats = this._getDataStats()
			const changedNodeNames = getAttributeChanges(prevStats, newStats, {
				__SELECTED__: true,
				__NOT_SELECTED__: true,
			})

			this._addChangeDescriptor(SELECTION_CHANGE, changedNodeNames, affectedObjectIds)
			this._notifyLocalDependencies(SELECTION_CHANGE)
			this._notifyClientFilterDependencies(SELECTION_CHANGE, changedNodeNames)

			this._writeToRedux()
			return this.p_resolveSelectionDependencies()
		}

		if (this.pendingSelectionSideEffects) {
			return this._subscribeToSelectionReady(() => runSelection(staticFilter, filterDescriptor, contextData))
		} else {
			return runSelection(staticFilter, filterDescriptor, contextData)
		}
	}

	p_resolveSelectionDependencies(options = {}) {
		if (!options.logger) options.logger = this.logger
		const logger = options.logger

		return new Promise((resolve, reject) => {
			// Ikke send dersom ikke noen andre kilder trenger seleksjonen
			const selectionChangeDependencyDescriptor = this.operationDependencyDescriptors.selctionChangeDescriptor
			if (!selectionChangeDependencyDescriptor.enabled) return resolve()

			// Invalidate and update gui afterwards
			// const updateGui = options.noUpdate ? false : true
			this.pendingSelectionSideEffects = true

			const affectedDataSources = this.getAffectedDataSourcesFromSelectionChange()
			const invalidatedDataSourceIdDict = this.__appController.invalidateDataSourcesById(
				affectedDataSources,
				{ updateGui: !options.noUpdate }
			)

			// TODO: Denne kan endres til selectionChangeDependencyDescriptor når server støtter det
			const selectionDataForServer = this.getDataForSelectionChange()

			logger.time('Resolve Selection Dependencies: ' + this.name)
			setSelectionOnServer(this.id, selectionDataForServer)
				.then((sideEffects) => {
					logger.timeEnd('Resolve Selection Dependencies: ' + this.name)
					this.__appController.writeSideEffects(this, sideEffects, {
						...options,
						invalidatedDataSourceIdDict,
					})
					this.pendingSelectionSideEffects = false
					const subs = this.selectionSideEffectsReadySubs
					this.selectionSideEffectsReadySubs = []
					subs.forEach((item) => item())

					resolve()
				})
				.catch((err) => {
					logger.timeEnd('Resolve Selection Dependencies: ' + this.name)
					logger.error('Failed to resolve side effects from selection', { err })
					this.pendingSelectionSideEffects = false
					const subs = this.selectionSideEffectsReadySubs
					this.selectionSideEffectsReadySubs = []
					subs.forEach((item) => item())
					reject(err)
				})
		})
	}

	_subscribeToSelectionReady(promiseFunc) {
		return new Promise((resolve, reject) => {
			this.selectionSideEffectsReadySubs.push(() => {
				promiseFunc().then(resolve).catch(reject)
			})
		})
	}

	/**************************************************************************
	 *
	 * TODO: Uncategorized
	 *
	 *************************************************************************/

	invalidate(invalidatedDataSourceIdDict = {}) {
		this.dataReady = false
		invalidatedDataSourceIdDict[this.id] = true
		if (this.reverseDependencies && this.reverseDependencies.length)
			this.__appController.invalidateDataSourcesById(
				this.reverseDependencies.map((item) => item.dataSourceId),
				{ invalidatedDataSourceIdDict }
			)

		return invalidatedDataSourceIdDict
	}

	/********************************************************
	 * GETTERS
	 ********************************************************/

	// Will return single selected or only object in cardinality 1
	getSingleObject(contextData = {}) {
		if (contextData[this.id] && contextData[this.id].length)
			return this.getObjectById(contextData[this.id][0]._id)
		if (this.singleSelectedId) return this.getObjectById(this.singleSelectedId)
		const data = this.getAllObjects()
		if (this.cardinality === ONE && data.length === 1) return data[0]
		return undefined
	}

	getContextObject(contextData = {}) {
		if (contextData[this.id] && contextData[this.id].length) {
			const contextObject = contextData[this.id][0] || {}
			const dataObject = this.getObjectById(contextObject._id) || {}

			return { ...contextObject, ...dataObject }
		}
	}

	/**
	 * Will return a single primitive value based on nodeName
	 */
	getSingleValue(contextData = {}, nodeName) {
		switch (nodeName) {
			case DATA_READY:
				return this.dataReady
			case OBJECT_COUNT:
				return this.getAllObjects().length
			case SELECTED_OBJECTS_COUNT:
				return Object.keys(this.selectionDictionary).length
			case TOTAL_OBJECT_COUNT:
				return this.totalObjectCount
			case SKIP:
				return this.skip
			case LIMIT:
				return this.resultLimit
			case IS_EMPTY:
				return this.getAllObjects().length === 0
			case IS_NOT_EMPTY:
				return !!this.getAllObjects().length
			case HAS_SELECTED_OBJECTS:
				return !!Object.keys(this.selectionDictionary).length
			case HAS_NO_SELECTED_OBJECTS:
				return !Object.keys(this.selectionDictionary).length
			case DISABLED:
				return this.dataSourceDisabled
			case SUBSCRIBE_TO_UPDATES:
				return this.liveUpdate
			case SKIP_FUNCTION_PROPERTIES:
				return this.skipFunctionProperties
		}

		const singleObject = this.getSingleObject(contextData)
		if (!singleObject) return undefined
		return singleObject[nodeName]
	}

	getUnfilteredData() {
		if (this.conditionalFilter?.length) {
			const data = Object.values(this.unfilteredDataDict)
			return data.map((item, index) => {
				const filteredDataItem = this.getObjectById(item._id)
				item.__NOT_SELECTED__ = !filteredDataItem?.__SELECTED__
				item.__SELECTED__ = !!filteredDataItem?.__SELECTED__

				if (this.cardinality !== ONE) {
					if (index === 0) {
						item.__IS_FIRST_IN_DATA_SOURCE__ = true
					} else {
						item.__IS_FIRST_IN_DATA_SOURCE__ = false
					}

					if (index === data.length - 1) {
						item.__IS_LAST_IN_DATA_SOURCE__ = true
					} else {
						item.__IS_LAST_IN_DATA_SOURCE__ = false
					}

					if (index % 2 === 0) {
						item.__IS_EVEN_IN_DATA_SOURCE__ = true
					} else {
						item.__IS_EVEN_IN_DATA_SOURCE__ = false
					}

					item.__INDEX__ = index

					if (this.displayNameNodeName) item.__NAME__ = item[this.displayNameNodeName]
				}

				return item
			})
		}

		return this.data
	}

	getObjectById(objectId, { checkUnfiltered } = {}) {
		let object = this.dataDict[objectId]
		if (isNil(object) && checkUnfiltered) {
			object = this.unfilteredDataDict[objectId]
		}
		return object
	}

	getAllObjects({ asDictionary } = {}) {
		if (asDictionary) return this.dataDict
		return this.data
	}

	getSelectedObjects() {
		// Vurdere om denne skal pregenereres også
		return this.getAllObjects().filter((item) => item.__SELECTED__)
	}

	getUnselectedObjects() {
		return this.getAllObjects().filter((item) => !item.__SELECTED__)
	}

	getDataFromDataBinding({ contextData = {}, dataBinding, dataBindingContext, options = {} }) {
		let dataForReturn

		if (dataBindingContext) {
			if (!dataBindingContext.contextData) return undefined // no data found from parent
			const parentDataBinding = dataBindingContext.parentDataBinding

			// if find multiple data from parent
			if (isArray(dataBindingContext.contextData)) {
				const pushToArray = (item, array) => {
					if (array.indexOf(item) === -1) array.push(item) //add to array if not a duplicate
				}
				const objectIds = dataBindingContext.contextData.reduce((acc, item) => {
					const objectId = item[parentDataBinding.nodeName]
					// if multi-cardinality reference, objectId can be an array of objectIds
					if (isArray(objectId)) objectId.map((childObjectId) => pushToArray(childObjectId, acc))
					else pushToArray(objectId, acc)
					return acc
				}, [])
				dataForReturn = objectIds.map((id) => this.getObjectById(id))
			} else {
				// if find single data from parent
				const objectId = dataBindingContext.contextData[parentDataBinding.nodeName]
				// if multi-cardinality reference, objectId can be an array of objectIds
				if (isArray(objectId)) {
					/**
					 * Return context data if it exists,
					 * Else return all data from property
					 */

					const contextObjectId = contextData[this.id]?.length
						? contextData[this.id][0]?._id || contextData[this.id][0]
						: undefined

					const getObjectById = (id) => this.getObjectById(id) || { _id: id } // the datasource might not contain the object --> default to object with only _id

					// Return a fresh object based on context - or return context if fresh object is not found
					if (contextObjectId && objectId.includes(contextObjectId))
						dataForReturn = this.getObjectById(contextObjectId) || contextData[this.id][0]
					else dataForReturn = objectId.map(getObjectById)
				} else dataForReturn = this.getObjectById(objectId)
			}
		} else {
			dataForReturn = (() => {
				// Multiple objects
				if (!dataBinding.propertyId) return this.getAllObjects()

				switch (dataBinding.propertyId) {
					case DATA_READY:
						return { [DATA_READY]: this.dataReady }
					case OBJECT_COUNT:
						return { [OBJECT_COUNT]: this.getAllObjects().length }
					case SELECTED_OBJECTS_COUNT:
						return { [SELECTED_OBJECTS_COUNT]: Object.keys(this.selectionDictionary).length }
					case TOTAL_OBJECT_COUNT:
						return { [TOTAL_OBJECT_COUNT]: this.totalObjectCount }
					case SKIP:
						return { [SKIP]: this.skip }
					case LIMIT:
						return { [LIMIT]: this.resultLimit }
					case IS_EMPTY:
						return { [IS_EMPTY]: this.getAllObjects().length === 0 }
					case IS_NOT_EMPTY:
						return { [IS_NOT_EMPTY]: !!this.getAllObjects().length }
					case HAS_SELECTED_OBJECTS:
						return { [HAS_SELECTED_OBJECTS]: !!Object.keys(this.selectionDictionary).length }
					case HAS_NO_SELECTED_OBJECTS:
						return { [HAS_NO_SELECTED_OBJECTS]: !Object.keys(this.selectionDictionary).length }
					case DISABLED:
						return { [DISABLED]: this.dataSourceDisabled }
					case SUBSCRIBE_TO_UPDATES:
						return { [SUBSCRIBE_TO_UPDATES]: this.liveUpdate }
					case SKIP_FUNCTION_PROPERTIES:
						return { [SKIP_FUNCTION_PROPERTIES]: this.skipFunctionProperties }
				}

				if (dataBinding.selectionMode) {
					switch (dataBinding.selectionMode) {
						case e_FilterTargetSelectionMode.ALL:
							return this.getAllObjects()
						case e_FilterTargetSelectionMode.SELECTED:
							return this.getSelectedObjects()
						case e_FilterTargetSelectionMode.UNSELECTED:
							return this.getUnselectedObjects()
					}
				}

				// Return a fresh object based on context - or return context if fresh object is not found
				if (contextData[this.id] && contextData[this.id].length) {
					return (
						this.getObjectById(contextData[this.id][0]?._id) ||
						this.getObjectById(contextData[this.id][0]) ||
						contextData[this.id][0]
					)
				}

				// Single object
				if (this.getAllObjects().length === 0) return undefined
				if (this.cardinality === ONE) return this.getAllObjects()[0]
				if (!this.singleSelectedId) return undefined
				return this.getObjectById(this.singleSelectedId)
			})()
		}

		if (dataBinding.referenceDataBinding) {
			if (!dataForReturn) return undefined

			const dataSource = this.__appController.getDataSource(dataBinding.referenceDataBinding.dataSourceId)

			if (!dataSource) {
				throw new Error(
					'Unable to find dataSource for nested dataBinding with dataSourceId: ' +
						dataBinding.referenceDataBinding.dataSourceId
				)
			}

			dataForReturn = dataSource.getDataFromDataBinding({
				contextData: contextData,
				dataBinding: dataBinding.referenceDataBinding,
				dataBindingContext: {
					parentDataBinding: dataBinding,
					contextData: dataForReturn,
				},
			})
		}

		if (options.getDisplayPropertyData && dataBinding.displayPropertyDataBinding) {
			if (!dataForReturn) return undefined

			const dataSource = this.__appController.getDataSource(
				dataBinding.displayPropertyDataBinding.dataSourceId
			)

			if (!dataSource) {
				throw new Error(
					'Unable to find dataSource for display property dataBinding with dataSourceId: ' +
						dataBinding.displayPropertyDataBinding.dataSourceId
				)
			}

			dataForReturn = dataSource.getDataFromDataBinding({
				contextData: contextData,
				dataBinding: dataBinding.displayPropertyDataBinding,
				dataBindingContext: {
					parentDataBinding: dataBinding,
					contextData: dataForReturn,
				},
			})
		}

		// Handle this where multi-ref / enum is enabled / in use for now
		// if (dataBinding.propertyId && options.getPropertyObjects) {
		// 	const property = this.propertiesMetaDict[dataBinding.propertyId]
		// 	if (
		// 		isPlainObject(dataForReturn) &&
		// 		[MULTI_ENUM, MULTI_REFERENCE, ENUM, REFERENCE].includes(property.dataType)
		// 	) {
		// 		let objectIds = dataForReturn[dataBinding.nodeName] || []

		// 		if (!isArray(objectIds)) objectIds = [objectIds]

		// 		return this.__appController.enrichObjectIdsFromDataBindingProperty(dataBinding, { contextData, objectIds })
		// 	}
		// }

		return dataForReturn
	}

	p_getFilteredObjects({
		selectionType,
		staticFilter,
		filterDescriptor,
		contextData,
		actionId,
		actionNodeId,
	}) {
		return new Promise((resolve, reject) => {
			if (!this.dataConnector) {
				reject(new Error('Tried to read filtered objects from a Data Source that is not a connector.'))
			}

			if (!this.staticFilter && !this.filterDescriptor) {
				logger.debug('No basefilter defined -> No objects to read')
				resolve([])
			}

			let filter
			switch (selectionType) {
				case e_ActionNodeSelectionType.ALL:
					filter = {}
					break
				case e_ActionNodeSelectionType.FILTERED: {
					if (staticFilter) filter = staticFilter
					else
						filter = generateFilterFromGroupNode({
							filterDescriptorNode: filterDescriptor,
							contextData,
							appController: this.__appController,
						})
					break
				}
				default:
					reject(
						new Error(
							`Unable to get filter for p_getFilteredObjects - invalid selectionType ${selectionType}`
						)
					)
			}

			// no filter -> no objects -> just return
			if (!filter) {
				logger.debug('No filter defined -> No objects to read')
				resolve([])
			}

			readFilteredObjectsOnServer(this.id, filter, { contextData, actionId, actionNodeId })
				.then(resolve)
				.catch(reject)
		})
	}

	getObjectsBySelectionType({
		selectionType,
		staticFilter,
		filterDescriptor,
		contextData,
		actionName,
		removeFileData,
	}) {
		return getObjectsBySelectionType({
			dataSource: this,
			selectionType,
			staticFilter,
			filterDescriptor,
			contextData,
			actionName,
			removeFileData,
		})
	}

	getAreSomeObjectsSelected() {
		return !!Object.keys(this.selectionDictionary).length
	}

	getAreAllObjectsSelected() {
		const data = this.getAllObjects()
		return data.length && data.length === Object.keys(this.selectionDictionary).length
	}

	/********************************************************
	 * Utils API
	 ********************************************************/

	isObjectQualified(objectForQualification, contextData) {
		// Check if object is qualified
		let filter = this.staticFilter
		if (!filter && this.filterDescriptor)
			filter = generateFilterFromGroupNode({
				filterDescriptorNode: this.filterDescriptor,
				contextData: contextData,
				appController: this.__appController,
			})

		return filter ? evaluateObjectWithFilterNode(objectForQualification, filter) : false
	}

	// Will just generate a new object based on
	generateNewObject(defaultValueOverrides, contextData = {}) {
		let newObject = objectGenerator(this)

		if (defaultValueOverrides) {
			const shellObject = this.generateShellObject(defaultValueOverrides, contextData)
			newObject = { ...newObject, ...shellObject }
		}

		return newObject
	}

	/**
	 * Will generate an empty object without identificator.
	 * Used for file upload.
	 *
	 * Kan muligens flyttes ut til en utilsfunksjon
	 */
	generateShellObject(defaultValueOverrides, contextData) {
		let shellObject = {}

		if (defaultValueOverrides) {
			const allProperties = this.propertiesMetaDict

			shellObject = defaultValueOverrides.reduce((newObject, propertyValue) => {
				let value = this.__appController.getDataFromDataValue(propertyValue.value, contextData)
				if (isUndefined(value)) return newObject

				const property = allProperties[propertyValue.propertyId]
				if (isUndefined(property)) return newObject

				if (property.dataType === e_ObjectClassDataType.INTEGER && !isNil(value)) value = parseInt(value, 10)
				if (property.dataType === e_ObjectClassDataType.FLOAT && !isNil(value)) value = parseFloat(value)

				newObject[propertyValue.nodeName] = value
				return newObject
			}, shellObject)
		}

		return shellObject
	}

	/**************************************************************************
	 *
	 * SYNC API
	 * Get all data needed for other datasources to resolve filters
	 *
	 *************************************************************************/
	isNeededForDataSync() {
		return !!this.reverseDependencies.length
	}

	getDataSourceConfigForSync() {
		if (this.local) return undefined

		return {
			id: this.id,
			skip: this.skip,
			...(!isUndefined(this.liveUpdate) && { liveUpdate: this.liveUpdate }),
			...(!isUndefined(this.dataSourceDisabled) && { disabled: this.dataSourceDisabled }),
			...(!isUndefined(this.resultLimit) && { resultLimit: this.resultLimit }),
		}
	}

	getDataForSynchronization() {
		return this._getDataFromDataOperationDescriptor(this.operationDependencyDescriptors.syncDescriptor)
	}

	getDataForSelectionChange() {
		// TODO: Optimaliserbar når støtte på server
		return this._getDataFromDataOperationDescriptor(this.operationDependencyDescriptors.syncDescriptor)
	}

	getDataForContentChange() {
		// TODO: Optimaliserbar når støtte på server
		return this._getDataFromDataOperationDescriptor(this.operationDependencyDescriptors.syncDescriptor)
	}

	getDataForPropertyChange(changedNodeNameList) {
		// TODO: Optimaliserbar når støtte på server

		const contentChangeDescriptor = this.operationDependencyDescriptors.contentChangeDescriptor
		if (changedNodeNameList.some((nodeName) => contentChangeDescriptor.projection[nodeName]))
			return this._getDataFromDataOperationDescriptor(this.operationDependencyDescriptors.syncDescriptor)

		return null
	}

	getAffectedDataSourcesFromChangedNodeNames(changedNodeNameList) {
		const propertyChangeDescriptorDictionary =
			this.operationDependencyDescriptors.propertyChangeDescriptorDictionary

		let targetDataSources = {}
		changedNodeNameList.forEach((nodeName) => {
			if (propertyChangeDescriptorDictionary[nodeName]) {
				// Found dependency
				targetDataSources = {
					...targetDataSources,
					...propertyChangeDescriptorDictionary[nodeName].targetDataSources,
				}
			}
		})

		return Object.keys(targetDataSources)
	}

	getAffectedDataSourcesFromSelectionChange() {
		const selectionChangeDependencyDescriptor = this.operationDependencyDescriptors.selctionChangeDescriptor
		if (!selectionChangeDependencyDescriptor.enabled) return []
		return Object.keys(selectionChangeDependencyDescriptor.targetDataSources)
	}

	setSyncrhonizedData({ data, count }) {
		const serverData = data
		if (this.local) return

		this.setTotalObjectCount(count)
		this._replaceAllObjects(serverData, { mergeWithExistingObjects: true })
	}

	setUserProfileImage(userId, profileImage, profileImageExists) {
		if (this.id === '__MECH_CURRENT_USER_DS' || this.objectClassId === '__MECH_USER_OC') {
			if (this.getObjectById(userId)) {
				this._modifyObject(userId, { profileImage, profileImageExists })
			}
		}
	}

	/**************************************************************************
	 *
	 * Server API
	 *
	 * Used by DataService and datasources to request local data.
	 *
	 *************************************************************************/
	svrApi_getSelectedObjectIds() {
		return this.getSelectedObjects().map((dataObject) => dataObject._id)
	}

	svrApi_getFilteredObjectIds() {
		return this.getAllObjects().map((dataObject) => dataObject._id)
	}

	svrApi_getSingleObject(projection, contextObjectId) {
		let dataForReturn = null
		if (this.cardinality === ONE) {
			const data = this.getAllObjects()
			if (data.length) dataForReturn = data[0]
		} else {
			if (contextObjectId) dataForReturn = this.getObjectById(contextObjectId)
			else if (this.singleSelectedId) dataForReturn = this.getObjectById(this.singleSelectedId)
		}

		if (projection && dataForReturn) {
			return Object.keys(projection).reduce(
				(result, nodeName) => {
					if (projection[nodeName]) result[nodeName] = dataForReturn[nodeName]
					return result
				},
				{ _id: dataForReturn._id }
			)
		}

		return dataForReturn
	}

	svrApi_getSingleValue({ contextData, nodeName }) {
		return this.getSingleValue(contextData, nodeName)
	}

	svrApi_getObjectById(objectId) {
		return this.getObjectById(objectId)
	}

	svrApi_getRuntimeObjectValuesDict(selectionType) {
		const runtimeProperties = Object.values(this.propertiesMetaDict).filter((property) => property.runtime)

		if (!runtimeProperties.length) return {}

		let data = this.getAllObjects()
		if (selectionType === e_FilterTargetSelectionMode.SELECTED) {
			data = this.getSelectedObjects()
		} else if (selectionType === e_FilterTargetSelectionMode.UNSELECTED) {
			data = this.getUnselectedObjects()
		}

		const runtimeValuesDict = data.reduce((runtimeValuesDict, dataItem) => {
			const runtimeValues = runtimeProperties.reduce((runtimeValues, property) => {
				const runtimeValue = dataItem[property.nodeName]
				if (!isUndefined(runtimeValue)) {
					runtimeValues[property.nodeName] = runtimeValue
				}
				return runtimeValues
			}, {})
			runtimeValuesDict[dataItem._id] = runtimeValues
			return runtimeValuesDict
		}, {})

		return runtimeValuesDict
	}

	// Denne sender all data uten å hensynta client-side filtering
	svrApi_getFullDataSource() {
		let allData
		if (this.conditionalFilter?.length) allData = this.getUnfilteredData()
		else allData = this.getAllObjects()

		if (this.isFileObjectClass) {
			return allData.map((item) => {
				let objectForServer = item
				// Remove any client file content on un-uploaded files
				if (objectForServer.__file) {
					objectForServer = { ...item }
					delete objectForServer.__file
					delete objectForServer.__fileContentLink
				}
				return objectForServer
			})
		} else {
			return allData
		}
	}

	/**
	 * ServerDataSource API
	 * None of these methods will invoke side effects in other
	 * data sources. This is already handled server side.
	 */

	svrApi_replaceAllData({ data, count }) {
		this._replaceAllObjects(data, { mergeWithExistingObjects: true })
		this.setTotalObjectCount(count)
		this._writeToRedux()
	}

	svrApi_insertObject(objectForInsertion) {
		this.logger.debug('Insert Object from server: ', {
			dataSourceId: this.id,
			payload: objectForInsertion,
		})
		if (!isPlainObject(objectForInsertion)) {
			Sentry.captureException(new Error('Unable to insert object. Not an object'))
			return
		}
		if (!isString(objectForInsertion._id)) {
			Sentry.captureException(new Error('Unable to insert object from server: _id is not a string'))
			return
		}
		this._insertObject(objectForInsertion)
		this._writeToRedux()
	}

	svrApi_insertMultipleObjects(objectListForInsertion) {
		this.logger.debug('Insert Objects from server: ', {
			dataSourceId: this.id,
			payload: objectListForInsertion,
		})
		this._addOrMergeObjects(objectListForInsertion)
		this._writeToRedux()
	}

	svrApi_modifySingleObject(objectId, newData) {
		this.logger.debug('Modify Object from server: ', {
			dataSourceId: this.id,
			payload: { ...newData, _id: objectId },
		})
		this._modifyObject(objectId, newData)
		this._writeToRedux()
	}

	svrApi_deleteMultipleObjects(objectIdArray) {
		this.logger.debug('Delete multiple objects from server', {
			dataSourceId: this.id,
			payload: objectIdArray,
		})
		this._removeMultipleObjects(objectIdArray)
		this._writeToRedux()
	}

	/**************************************************************************
	 *
	 * Metadata
	 *
	 *************************************************************************/

	getPropertyMetaData(propertyId) {
		return this.propertiesMetaDict[propertyId]
	}

	getPropertyMetaDict() {
		return this.propertiesMetaDict
	}

	getPropertiesForDebugTool() {
		const propertySortFunction = (itemA, itemB) => {
			if (itemA.nodeName === '_id') return -1 // -id first
			if (itemB.nodeName === '_id') return 1

			if (itemB.isBuiltIn) return -1 // builtin properties last
			if (itemA.isBuiltIn) return 1

			const itemAValue = itemA.name || ''
			const itemBValue = itemB.name || ''

			return itemAValue.localeCompare(itemBValue, undefined, { numeric: true })
		}

		return cloneDeep(this.propertiesMetadataInUseList)
			.map((item) => {
				let property = { ...item }
				if (property.enumTypeId) {
					const enumeratedType = this.__appController.getEnumeratedType(property.enumTypeId)
					if (enumeratedType && enumeratedType.valueDict) {
						property = { ...property, enumeratedTypeValueDict: enumeratedType.valueDict }
					}
				}
				return property
			})
			.sort(propertySortFunction)
	}

	// Devtools injection of metadata
	setExtendMetadata(extendedMetadata) {
		const objectClassMeta = extendedMetadata.objectClasses.find((entry) => entry.id === this.objectClassId)
		// const dataSourceMeta = extendedMetadata.dataSources?.[this.id]

		// if (dataSourceMeta) {
		// }

		if (objectClassMeta) {
			this.icon = objectClassMeta.icon
		} else {
			// Find the appropriate built-in data source (app variables, user groups, etc.)
			const definitions = [
				builtInCurrentUserGroupsDefinition,
				builtInRuntimeStateDataDefinition,
				builtInUrlParamsDefinition,
				builtInUrlPathDefinition,
			]
			const definition = definitions.find((candidate) => candidate.dataSourceId === this.id)
			if (definition) {
				const dataSource = definition.getDataSource()
				this.icon = dataSource.icon
			}
		}

		// Default to a suitable icon when none present
		if (!this.icon) {
			this.icon = this.isFileObjectClass ? 'mdi mdi-file' : 'mdi mdi-cube-outline'
		}
	}

	/**************************************************************************
	 *
	 * Misc
	 *
	 *************************************************************************/

	destroy() {
		// Nothing here for now.
	}
}

export default ClientDataSource
