/* eslint-disable import/prefer-default-export */

/**
 * This module will generate a filter with mongoDB syntax
 * based on a filterDescriptor and a snapshot of the
 * client side data.
 */
import { isNil, isUndefined, isArray } from 'lodash'

import e_FilterOperator from '@appfarm/common/enums/e_FilterOperator'
import e_FilterTargetSelectionMode from '@appfarm/common/enums/e_FilterTargetSelectionMode'
import e_FilterTargetType from '@appfarm/common/enums/e_FilterTargetType'
import e_Cardinality from '@appfarm/common/enums/e_Cardinality'

const {
	EQUALS,
	NOT_EQUALS,
	LESS_THAN,
	LESS_THAN_OR_EQUAL,
	GREATER_THAN,
	GREATER_THAN_OR_EQUAL,
	EXISTS,
	NOT_EXISTS,
	IN,
	NOT_IN,
	AND,
	OR,
	NOR,
	CONTAINS,
	CONTAINS_ALL,
	CONTAINS_ALL_WORDS,
	CONTAINS_ANY,
	NOT_CONTAINS,
	NOT_CONTAINS_ALL,
	NOT_CONTAINS_ALL_WORDS,
	NOT_CONTAINS_ANY,
	HAS_VALUE,
	HAS_NO_VALUE,
	ALL,
	NOT,
	SIZE,
} = e_FilterOperator

const getValueFromItem = (item, filterDescriptorNode, appController) => {
	if (filterDescriptorNode.targetValueComponentId)
		return appController.getValueFromValueComponentId(
			item[filterDescriptorNode.targetNodeName],
			filterDescriptorNode.targetValueComponentId
		)

	return item[filterDescriptorNode.targetNodeName]
}

const generateFilterForMultiEqualNode = ({ sourceNodeName, targetValue = [] }) => {
	if (targetValue.length === 0) {
		return {
			[`${sourceNodeName}.0`]: { [EXISTS]: false },
		}
	}
	return {
		[sourceNodeName]: {
			[ALL]: targetValue,
			[SIZE]: targetValue.length,
		},
	}
}

const generateFilterForMultiNotEqualNode = ({ sourceNodeName, targetValue = [] }) => {
	if (targetValue.length === 0) return { [`${sourceNodeName}.0`]: { [EXISTS]: true } }

	return {
		[OR]: [
			{
				[sourceNodeName]: {
					[NOT]: {
						[ALL]: targetValue,
					},
				},
			},
			{
				[sourceNodeName]: {
					[NOT]: {
						[SIZE]: targetValue.length,
					},
				},
			},
		],
	}
}

const getDataFromTargetSelectionMode = (
	dataSource,
	targetSelectionMode,
	targetFilterDescriptor,
	contextData
) => {
	switch (targetSelectionMode) {
		case e_FilterTargetSelectionMode.ALL:
			return dataSource.getAllObjects()

		case e_FilterTargetSelectionMode.SELECTED:
			return dataSource.getSelectedObjects()

		case e_FilterTargetSelectionMode.UNSELECTED:
			return dataSource.getUnselectedObjects()

		case e_FilterTargetSelectionMode.FILTERED:
			return dataSource.getObjectsBySelectionType({
				selectionType: e_FilterTargetSelectionMode.FILTERED,
				filterDescriptor: targetFilterDescriptor,
				contextData,
			})

		case e_FilterTargetSelectionMode.CONTEXT: {
			const singlObject = dataSource.getSingleObject(contextData)
			return singlObject ? [singlObject] : []
		}

		default:
			throw new Error('getDataFromTargetSelectionMode: Unknown or unsupported targetSelectionMode')
	}
}

const generateFilterFromEdgeNode = (filterDescriptorNode, contextData = {}, appController, options) => {
	switch (filterDescriptorNode.operator) {
		case EXISTS:
			return { [filterDescriptorNode.sourceNodeName]: { [EXISTS]: true } }

		case NOT_EXISTS:
			return { [filterDescriptorNode.sourceNodeName]: { [EXISTS]: false } }

		case HAS_VALUE:
			return { [filterDescriptorNode.sourceNodeName]: { [HAS_VALUE]: true } }

		case HAS_NO_VALUE:
			return { [filterDescriptorNode.sourceNodeName]: { [HAS_NO_VALUE]: true } }
	}

	// MultiRef/MultiEnum
	if (filterDescriptorNode.isSourceCardinalityMany) {
		const sourceNodeName = filterDescriptorNode.sourceNodeName
		let targetValue = []

		if (filterDescriptorNode.targetType === e_FilterTargetType.CONSTANT) {
			// Static Filter
			targetValue = filterDescriptorNode.targetConstantList
		} else {
			// NOT CONSTANT
			const dataSource = appController.getDataSource(filterDescriptorNode.targetDataSourceId)
			const targetObjects = getDataFromTargetSelectionMode(
				dataSource,
				filterDescriptorNode.targetSelectionMode || e_FilterTargetSelectionMode.CONTEXT,
				filterDescriptorNode.targetFilterDescriptor,
				contextData
			)

			let propertyValues = targetObjects
				.map((dataObject) => getValueFromItem(dataObject, filterDescriptorNode, appController))
				.filter((item) => !isNil(item))

			if (filterDescriptorNode.isTargetPropertyCardinalityMany) {
				propertyValues = propertyValues.reduce((result, arrayProperty) => result.concat(arrayProperty), [])
			}

			// Keep duplicates for EQ/NEQ
			targetValue = propertyValues
		}

		if (filterDescriptorNode.operator === EQUALS) {
			return generateFilterForMultiEqualNode({ sourceNodeName, targetValue })
		} else if (filterDescriptorNode.operator === NOT_EQUALS) {
			return generateFilterForMultiNotEqualNode({ sourceNodeName, targetValue })
		} else {
			// IN/NIN/ALL
			// Remove duplicates
			targetValue = [...new Set(targetValue)]

			return {
				[filterDescriptorNode.sourceNodeName]: { [filterDescriptorNode.operator]: targetValue },
			}
		}
	}

	switch (filterDescriptorNode.operator) {
		case EQUALS:
		case NOT_EQUALS:
		case LESS_THAN:
		case LESS_THAN_OR_EQUAL:
		case GREATER_THAN:
		case GREATER_THAN_OR_EQUAL:
		case CONTAINS:
		case CONTAINS_ALL:
		case CONTAINS_ALL_WORDS:
		case CONTAINS_ANY:
		case NOT_CONTAINS:
		case NOT_CONTAINS_ALL:
		case NOT_CONTAINS_ALL_WORDS:
		case NOT_CONTAINS_ANY:
			return (() => {
				// Constant
				if (filterDescriptorNode.targetType === e_FilterTargetType.CONSTANT) {
					const filterValue =
						filterDescriptorNode.operator === EQUALS
							? filterDescriptorNode.targetConstant
							: { [filterDescriptorNode.operator]: filterDescriptorNode.targetConstant }

					return { [filterDescriptorNode.sourceNodeName]: filterValue }
				}

				let targetValue = undefined

				if (filterDescriptorNode.targetDataValue) {
					targetValue = appController.getDataFromDataValue(filterDescriptorNode.targetDataValue, contextData)
				} else {
					const dataSource = appController.getDataSource(filterDescriptorNode.targetDataSourceId)

					if (
						contextData[filterDescriptorNode.targetDataSourceId] &&
						contextData[filterDescriptorNode.targetDataSourceId].length
					) {
						const targetObject = dataSource.getContextObject(contextData)
						targetValue = targetObject[filterDescriptorNode.targetNodeName]

						if (filterDescriptorNode.targetValueComponentId) {
							targetValue = appController.getValueFromValueComponentId(
								targetValue,
								filterDescriptorNode.targetValueComponentId
							)
						}
					} else {
						// Get single selected
						const targetObject = dataSource.getSingleObject()
						if (targetObject) {
							targetValue = targetObject[filterDescriptorNode.targetNodeName]
							if (filterDescriptorNode.targetValueComponentId) {
								targetValue = appController.getValueFromValueComponentId(
									targetValue,
									filterDescriptorNode.targetValueComponentId
								)
							}
						}
					}
				}

				// Invalid filter - return no objects
				if (isUndefined(targetValue)) return undefined

				const filterValue =
					filterDescriptorNode.operator === EQUALS
						? targetValue
						: { [filterDescriptorNode.operator]: targetValue }
				return { [filterDescriptorNode.sourceNodeName]: filterValue }
			})()

		case IN:
		case NOT_IN:
			return (() => {
				let filterValue = []

				if (filterDescriptorNode.targetType === e_FilterTargetType.REFERENCE) {
					if (filterDescriptorNode.targetDataValue) {
						filterValue = appController.getDataFromDataValue(
							filterDescriptorNode.targetDataValue,
							contextData
						)
						if (isUndefined(filterValue)) filterValue = []
						else if (!isArray(filterValue)) filterValue = [filterValue]
					} else {
						const dataSource = appController.getDataSource(filterDescriptorNode.targetDataSourceId)
						if (dataSource.cardinality === e_Cardinality.ONE) {
							filterValue = dataSource
								.getAllObjects()
								.map((dataObject) => getValueFromItem(dataObject, filterDescriptorNode, appController))
								.filter((item) => !isNil(item))
						} else {
							filterValue = getDataFromTargetSelectionMode(
								dataSource,
								filterDescriptorNode.targetSelectionMode,
								filterDescriptorNode.targetFilterDescriptor,
								contextData
							)
								.map((dataObject) => getValueFromItem(dataObject, filterDescriptorNode, appController))
								.filter((item) => !isNil(item))
						}
					}

					if (filterDescriptorNode.isTargetPropertyCardinalityMany) {
						filterValue = filterValue.reduce((result, arrayProperty) => result.concat(arrayProperty), [])
					}

					// Remove duplicates
					filterValue = [...new Set(filterValue)]
				} else {
					filterValue = filterDescriptorNode.targetConstantList
				}

				return {
					[filterDescriptorNode.sourceNodeName]: { [filterDescriptorNode.operator]: filterValue },
				}
			})()

		default:
			throw new Error(
				'generateFilterFromEdgeNode: Unknown or unsupported operator: ' + filterDescriptorNode.operator
			)
	}
}

export const generateFilterFromGroupNode = ({
	filterDescriptorNode,
	contextData,
	appController,
	options = {},
}) => {
	if (!options.parentFilterDescriptorNode) options.parentFilterDescriptorNode = filterDescriptorNode

	if (filterDescriptorNode.staticFilter) return filterDescriptorNode.staticFilter
	switch (filterDescriptorNode.operator) {
		case AND:
		case OR:
		case NOR:
			return (() => {
				let filterArray = filterDescriptorNode.nodes.map((childFilterDescriptorNode) => {
					if (childFilterDescriptorNode.nodeType === 'edge')
						return generateFilterFromEdgeNode(childFilterDescriptorNode, contextData, appController, options)

					if (childFilterDescriptorNode.nodeType === 'group')
						return generateFilterFromGroupNode({
							filterDescriptorNode: childFilterDescriptorNode,
							contextData,
							appController,
							options,
						})

					throw new Error('generateFilterFromGroupNode: unknown node type. Must be edge or group.')
				})

				// At least one node is undefined - filter is invalid and will give 0 objects
				filterArray = filterArray.filter((item) => item)
				if (!filterArray.length) return undefined

				// Filter will be false - at least one node will eval to false
				if (filterDescriptorNode.operator === AND && filterArray.length < filterDescriptorNode.nodes.length)
					return undefined

				return filterArray.length === 1 ? filterArray[0] : { [filterDescriptorNode.operator]: filterArray }
			})()
		default:
			throw new Error('groupNodeFilterGenerator: Unknown operator on group node')
	}
}
