import { isString, isInteger } from 'lodash'

/**
 * Forslag til hvordan et objekt kan opprettes
 */
const p_createObject = ({ actionNode, contextData, appController, actionNodeLogger }) =>
	new Promise((resolve, reject) => {
		if (!isString(actionNode.dataSourceId))
			return reject(new Error('Data Source is not set in the Create Object Action Node'))

		const dataSource = appController.getDataSource(actionNode.dataSourceId)

		if (!dataSource) throw new Error('Unable to find Data Source in Create Object Action Node')

		if (dataSource.dataSourceDisabled) {
			return reject(new Error('Unable to create Object because Data Source is disabled.'))
		}

		let numCopies = 1
		if (actionNode.numCopies)
			numCopies = appController.getDataFromDataValue(actionNode.numCopies, contextData)

		if (!isInteger(numCopies)) return reject(new Error('Value for Number of Copies is not an integer'))

		if (numCopies === 0) {
			actionNodeLogger.debug('No Objects created')
			return resolve()
		}

		if (numCopies > 1) actionNodeLogger.debug('Creating multiple Objects: ' + numCopies)

		// TODO: Still sends all built-in properties (like af_createdBy) unlike p_persist

		dataSource
			.p_createObject({
				defaultValues: actionNode.defaultValues,
				setSelectedAfterCreate: actionNode.setSelected,
				replaceSingleObject: actionNode.replaceObject,
				numCopies,
				contextData,
				logger: actionNodeLogger,
			})
			.then((newData) => {
				actionNodeLogger.table(newData, null, { dataSourceId: actionNode.dataSourceId })
				resolve()
			})
			.catch(reject)
	})

export default p_createObject
