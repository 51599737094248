import { isNil, isPlainObject } from 'lodash'

import e_FormDataType from '@appfarm/common/enums/e_FormDataType'
import e_HttpRequestMethod from '@appfarm/common/enums/e_HttpRequestMethod'
import e_HttpRequestBodyType from '@appfarm/common/enums/e_HttpRequestBodyType'
import e_HttpRequestBodyContentType from '@appfarm/common/enums/e_HttpRequestBodyContentType'
import e_BuiltInFileObjectClassPropertyIds from '@appfarm/common/enums/e_BuiltInFileObjectClassPropertyIds'

import getBinaryFromUrl from '#utils/getBinaryFromUrl'

const { DELETE, PATCH, POST, PUT } = e_HttpRequestMethod

const generateRequestBody = async ({ actionNode, appController, contextData, actionNodeLogger }) => {
	const config = { data: undefined, headers: {} }

	if (![POST, PUT, PATCH, DELETE].includes(actionNode.method)) return config

	switch (actionNode.bodyType) {
		case e_HttpRequestBodyType.FORM_DATA: {
			const formDataItems = await Promise.all(
				actionNode.bodyFormItems.map(
					(item) =>
						new Promise((resolve, reject) => {
							const value = appController.getDataFromDataValue(item.value, contextData)
							const valuePromise =
								item.dataType === e_FormDataType.FILE ? getBinaryFromUrl(value) : Promise.resolve(value)

							valuePromise
								.then((value) => {
									resolve({
										name: item.name,
										value: value,
									})
								})
								.catch(reject)
						})
				)
			)

			const formData = new FormData()

			formDataItems.forEach((item) => {
				if (item.name && !isNil(item.value)) {
					// TODO: Might want to consider setting filename appropriately here, instead of default `blob` (#3927)
					formData.append(item.name, item.value)
				}
			})

			config.data = formData
			break
		}

		case e_HttpRequestBodyType.X_WWW_FORM_URLENCODED: {
			const formData = actionNode.bodyFormItems.reduce((formData, item) => {
				const value = appController.getDataFromDataValue(item.value, contextData)
				formData[item.name] = value
				return formData
			}, {})

			config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
			config.data = new URLSearchParams(formData)
			break
		}

		case e_HttpRequestBodyType.BINARY:
			throw new Error('Not Implemented')

		default: {
			// raw
			let bodyContent
			const dataSource =
				actionNode.bodyContent?.dataBinding &&
				appController.getDataSource(actionNode.bodyContent.dataBinding.dataSourceId)

			// TODO: should possible be moved to appController.getDataFromDataValue if needed elsewhere,
			//  but done here for now to not introduce async in appController.getDataFromDataValue
			if (
				dataSource?.isFileObjectClass &&
				actionNode.bodyContent?.dataBinding?.nodeName === e_BuiltInFileObjectClassPropertyIds.FILE_RAW
			) {
				const fileObject = appController.getDataFromDataBinding(actionNode.bodyContent, contextData, {
					ignoreReturnDatatypeCheck: true,
				})
				bodyContent = await getBinaryFromUrl(fileObject.__fileContentLink)
			} else {
				bodyContent = appController.getDataFromDataValue(actionNode.bodyContent, contextData, {
					ignoreReturnDatatypeCheck: true,
				})
			}

			if (actionNode.bodyContentType !== e_HttpRequestBodyContentType.CUSTOM)
				config.headers['Content-Type'] = actionNode.bodyContentType || e_HttpRequestBodyContentType.JSON

			if (!actionNode.bodyContentType || actionNode.bodyContentType === e_HttpRequestBodyContentType.JSON) {
				if (isPlainObject(bodyContent) || Array.isArray(bodyContent)) {
					config.data = bodyContent
				} else {
					try {
						config.data = JSON.parse(bodyContent)
					} catch (err) {
						actionNodeLogger.error('Failed to parse body content', { payload: { bodyContent } })
						throw new Error(
							'Unable to parse body content.  Must be valid JSON when conten type is set to ' +
								e_HttpRequestBodyContentType.JSON
						)
					}
				}
			} else {
				config.data = bodyContent
			}
		}
	}

	return config
}

export default generateRequestBody
