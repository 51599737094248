import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import isPlainObject from 'lodash/isPlainObject'
import isNil from 'lodash/isNil'

import { withStyles } from '@material-ui/core/styles'

import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import { makeEvaluateFilter } from '#selectors/filterSelectors'
import { generateFilterFromGroupNode } from '#utils/filterGenerator'
import Autocomplete from '@material-ui/lab/Autocomplete'
import isUndefined from 'lodash/isUndefined'
import { e_MarginType } from '@appfarm/common/enums/e_PropertyTypes'
import { makeGetSortedObjectsWithDidSort } from '#selectors/sortingSelectors'
import UiVirtualizedListBox from './UiVirtualizedListBox'

const styles = (theme) => ({
	root: {
		display: 'block',
	},
	inputRoot: {
		width: '100%',
	},
	placeholderActive: {
		opacity: theme.palette.type === 'light' ? 0.42 : 0.5,
	},
	disabled: {},
})

const getPropertyValueFromDataBinding = (value, propertyIdent) => {
	if (!value) return undefined
	if (value.referenceDataBinding) return value.referenceDataBinding[propertyIdent]
	return value[propertyIdent]
}

class UiSelect extends Component {
	constructor(props) {
		super(props)
		this.state = {
			filterFunction: makeEvaluateFilter(),
			sortFunction: makeGetSortedObjectsWithDidSort(),
		}

		this.onValueChange = this.onValueChange.bind(this)
		this.onValueChangeSelect = this.onValueChangeSelect.bind(this)
		this.onValueChangeAutocomplete = this.onValueChangeAutocomplete.bind(this)

		this.handleOpen = this.handleOpen.bind(this)
		this.handleClose = this.handleClose.bind(this)
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const filterFunction = prevState.filterFunction
		const sortFunction = prevState.sortFunction
		let helperText
		if (nextProps.component.helperText)
			helperText = nextProps.appController.getDataFromDataValue(
				nextProps.component.helperText,
				nextProps.contextData,
				{ getDisplayValue: true }
			)

		let options = []
		let labelUndefined
		const enumeratedTypeId = getPropertyValueFromDataBinding(nextProps.component.value, 'enumeratedTypeId')
		if (enumeratedTypeId) {
			if (nextProps.component.conditionalEnumOptions && nextProps.component.conditionalEnumOptions.length) {
				const conditionalItem = nextProps.component.conditionalEnumOptions.find((item) =>
					nextProps.appController.getDataFromDataValue(item.condition, nextProps.contextData)
				)

				if (conditionalItem) {
					options = nextProps.appController.getEnumeratedTypeOptions({
						enumeratedTypeId: enumeratedTypeId,
						limitEnumeratedTypeValues: true,
						selectableEnumTypeValues: conditionalItem.selectableEnumTypeValues,
					})
				} else {
					options = nextProps.appController.getEnumeratedTypeOptions({
						enumeratedTypeId: enumeratedTypeId,
					})
				}
			} else {
				options = nextProps.appController.getEnumeratedTypeOptions({
					enumeratedTypeId: enumeratedTypeId,
				})
			}
		} else if (nextProps.component.optionsDataBinding?.dataSourceId) {
			let filter
			let hasFilter = false
			if (nextProps.component.filterDescriptor) {
				hasFilter = true
				filter = generateFilterFromGroupNode({
					filterDescriptorNode: nextProps.component.filterDescriptor,
					contextData: nextProps.contextData,
					appController: nextProps.appController,
				})
			}

			if (hasFilter && isUndefined(filter)) {
				options = []
			} else {
				options = nextProps.appController.getObjectSelectOptions({
					dataSourceId: nextProps.component.optionsDataBinding.dataSourceId,
					displayValueDataBinding: nextProps.component.displayValue,
					filter: filter,
					filterFunction: filterFunction,
					sortFunction: sortFunction,
					contextData: nextProps.contextData,
					sortDescriptorArray: nextProps.component.sorting,
					conditionalFilter: nextProps.component.conditionalFilter,
				})
			}
		} else if (nextProps.component.value) {
			const dataBinding = nextProps.component.value?.referenceDataBinding || nextProps.component.value
			// else Select is connected to a boolean
			options = nextProps.appController.getBooleanLabelOptions({
				booleanValue: dataBinding,
				limitBooleanValues: true,
				selectableBooleanValues: [true, false],
			})
			if (nextProps.component.allowNullify)
				labelUndefined = nextProps.appController.getBooleanLabelOptions({
					booleanValue: dataBinding,
					limitBooleanValues: true,
					selectableBooleanValues: [undefined],
				})[0]
		}

		let label
		if (nextProps.component.label) {
			label = nextProps.appController.getDataFromDataValue(nextProps.component.label, nextProps.contextData, {
				getDisplayValue: true,
			})
		}

		let placeholder
		if (nextProps.component.placeholder) {
			placeholder = nextProps.appController.getDataFromDataValue(
				nextProps.component.placeholder,
				nextProps.contextData,
				{
					getDisplayValue: true,
				}
			)
		}

		let singleSelectedOptionValue
		if (nextProps.component.value && isPlainObject(nextProps.ownData)) {
			const nodeName = getPropertyValueFromDataBinding(nextProps.component.value, 'nodeName')
			const value = nextProps.ownData?.[nodeName]
			const valueIsOneOfOptions = options.some((option) => option.value === value)
			if (!isNil(value) && !valueIsOneOfOptions) {
				const dataBinding = nextProps.component.value?.referenceDataBinding || nextProps.component.value
				const displayValue = nextProps.appController.getDisplayValueFromDataBinding({
					dataBinding: dataBinding,
					contextData: nextProps.contextData,
				})
				options.push({
					id: value,
					value,
					name: displayValue,
					hide: true,
				})
			}
		} else if (nextProps.component.optionsDataBinding?.dataSourceId) {
			const ownDataSource = nextProps.appController.getDataSource(
				nextProps.component.optionsDataBinding.dataSourceId
			)
			const selectedOwnData = ownDataSource.getSelectedObjects()

			if (selectedOwnData.length === 1) {
				singleSelectedOptionValue = selectedOwnData[0]._id
				const valueIsOneOfOptions = options.some((option) => option.value === singleSelectedOptionValue)
				if (!valueIsOneOfOptions) {
					const displayValue = selectedOwnData[0].__NAME__
					options.push({
						id: singleSelectedOptionValue,
						value: singleSelectedOptionValue,
						name: displayValue || singleSelectedOptionValue,
						hide: true,
					})
				}
			}
		}

		return {
			filterFunction,
			helperText,
			options,
			singleSelectedOptionValue,
			label,
			placeholder,
			labelUndefined,
		}
	}

	onValueChange(event, value) {
		const props = this.props
		const isEnum = !!getPropertyValueFromDataBinding(props.component.value, 'enumeratedTypeId')
		const newValue = !isNil(value) && value !== '' ? value : undefined
		if (props.component.value) {
			const nodeName = getPropertyValueFromDataBinding(props.component.value, 'nodeName')
			const previousValue = props.ownData ? props.ownData[nodeName] : undefined

			const previousValues = {
				previousValueEnum: isEnum ? previousValue : null,
				previousValueReference: isEnum ? null : previousValue,
			}

			const onValueChangeEvent = props.component.onValueChange
				? () =>
					props.eventHandler(
						props.component.onValueChange,
						null,
						{
							eventType: 'onValueChange',
							eventHandlerValues: previousValues,
						},
						event
					)
				: undefined

			props.appController.modifySingleValue(
				props.component.value,
				props.ownData,
				newValue,
				{},
				onValueChangeEvent
			)
		} else if (props.component.optionsDataBinding?.dataSourceId) {
			const previousSelectedData = props.appController
				.getDataSource(props.component.optionsDataBinding.dataSourceId)
				.getSelectedObjects()

			const previousValues = {
				previousValueEnum: previousSelectedData.length > 0 ? previousSelectedData[0].enum_value : null,
				previousValueReference: previousSelectedData.length > 0 ? previousSelectedData[0]._id : null,
			}

			if (!newValue) {
				// clear
				props.appController.selectNone(props.component.optionsDataBinding.dataSourceId)
			} else {
				props.appController.setSingleObjectSelected(props.component.optionsDataBinding.dataSourceId, newValue)
			}

			if (props.component.onValueChange)
				props.eventHandler(
					props.component.onValueChange,
					null,
					{
						eventType: 'onValueChange',
						eventHandlerValues: previousValues,
					},
					event
				)
		}
	}

	onValueChangeSelect(event) {
		this.onValueChange(event, event.target.value)
	}

	onValueChangeAutocomplete(event, option) {
		this.onValueChange(event, option?.value)
	}

	handleOpen(event) {
		if (this.props.component.onOpen) {
			this.props.eventHandler(
				this.props.component.onOpen,
				null,
				{
					eventType: 'onOpen',
				},
				event
			)
		}
	}

	handleClose(event) {
		if (this.props.component.onClose) {
			this.props.eventHandler(
				this.props.component.onClose,
				null,
				{
					eventType: 'onClose',
				},
				event
			)
		}
	}

	render() {
		const {
			component,
			componentId,
			disabled,
			readOnly,
			error,
			styleProp,
			ownData,
			touchDevice,
			conditionalClassNames,
			classes,
		} = this.props
		const { helperText, options, label, singleSelectedOptionValue, placeholder, labelUndefined } = this.state
		const { marginType = e_MarginType.NORMAL, native, variant, disableUnderline, autocomplete } = component

		let value = ''
		if (component.value && isPlainObject(ownData)) {
			const nodeName = getPropertyValueFromDataBinding(component.value, 'nodeName')
			value = ownData[nodeName]
		} else if (component.optionsDataBinding?.dataSourceId && singleSelectedOptionValue) {
			value = singleSelectedOptionValue
		}

		// Autocomplete does not respect readOnly, work-around is thus to render normal multiselect if readOnly.
		if (autocomplete && !readOnly) {
			value = options.find((option) => option.value === value) || null

			return (
				<Autocomplete
					// hack to clear value from props since simply using value (value = null || '') does not work
					key={`${componentId}${this.props.ownData?._id}`}
					id={componentId}
					options={options}
					includeInputInList
					// disableClearable={!component.allowNullify}
					disabled={disabled}
					value={value}
					onChange={this.onValueChangeAutocomplete}
					onOpen={this.handleOpen}
					onClose={this.handleClose}
					className={classNames(classes.root, 'c' + component.id, conditionalClassNames)}
					style={styleProp}
					ListboxComponent={UiVirtualizedListBox}
					getOptionLabel={(option) => {
						if (option && option.name) return option.name + ''
						else return ''
					}}
					renderInput={(params) => {
						const InputProps = {
							...params.InputProps,
							classes: disabled
								? {
									disabled: classes.disabled,
								}
								: undefined,
							className: classNames(params.InputProps.className, classes.inputRoot),
							// readOnly, // will only set the input field to read only
						}

						const InputLabelProps = {
							...params.InputLabelProps,
						}

						if (placeholder) {
							InputProps.placeholder = placeholder
							InputLabelProps.shrink = true
						}

						if (disableUnderline) {
							InputProps.disableUnderline = true
						}
						return (
							<TextField
								{...params}
								fullWidth
								margin={marginType}
								variant={variant}
								label={label}
								helperText={helperText}
								error={error}
								autoFocus={component.autoFocus}
								inputProps={{
									...params.inputProps,
									tabIndex: component.tabIndex,
								}}
								InputProps={InputProps}
								InputLabelProps={InputLabelProps}
							/>
						)
					}}
				/>
			)
		}

		const ItemComponent = native ? 'option' : MenuItem

		const InputProps = {
			className: classes.inputRoot,
			readOnly,
			inputProps: {
				tabIndex: component.tabIndex,
			},
		}

		const SelectProps = {
			native: native,
			displayEmpty: !!placeholder || !!labelUndefined,
			classes: !!placeholder && isNil(value) ? { root: classes.placeholderActive } : undefined,
			onOpen: this.handleOpen,
			onClose: this.handleClose,
		}

		const InputLabelProps =
			placeholder || labelUndefined ? { shrink: !!placeholder || !!labelUndefined } : undefined

		if (disableUnderline) InputProps.disableUnderline = true

		return (
			<TextField
				id={componentId}
				value={!isNil(value) ? value : ''}
				onChange={this.onValueChangeSelect}
				className={classNames(classes.root, 'c' + component.id)}
				margin={marginType}
				style={styleProp}
				variant={variant}
				label={label}
				helperText={helperText}
				error={error}
				disabled={disabled}
				autoFocus={component.autoFocus}
				InputProps={InputProps}
				InputLabelProps={InputLabelProps}
				SelectProps={SelectProps}
				select
			>
				{ (!!placeholder || component.allowNullify) && (
					<MenuItem key="null-item" value="" dense={!touchDevice} disabled={!component.allowNullify}>
						{ placeholder || labelUndefined?.name || '\xa0' }
					</MenuItem>
				) }
				{ options.map((item) => (
					<ItemComponent key={item.id} value={item.value} dense={native ? undefined : !touchDevice}>
						{ item.name }
					</ItemComponent>
				)) }
			</TextField>
		)
	}
}

UiSelect.propTypes = {
	appController: PropTypes.shape({
		getBooleanLabelOptions: PropTypes.func.isRequired,
		getDataFromDataValue: PropTypes.func.isRequired,
		modifySingleValue: PropTypes.func.isRequired,
		getEnumeratedType: PropTypes.func.isRequired,
		getEnumeratedTypeOptions: PropTypes.func.isRequired,
		getObjectSelectOptions: PropTypes.func.isRequired,
		getDisplayValueFromDataBinding: PropTypes.func.isRequired,
		getDataSource: PropTypes.func.isRequired,
		setSingleObjectSelected: PropTypes.func.isRequired,
		selectNone: PropTypes.func.isRequired,
	}).isRequired,
	component: PropTypes.object.isRequired,
	componentId: PropTypes.string.isRequired,
	disabled: PropTypes.bool.isRequired,
	readOnly: PropTypes.bool,
	error: PropTypes.bool,
	eventHandler: PropTypes.func.isRequired,
	ownData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]), // component can only use object
	contextData: PropTypes.object,
	touchDevice: PropTypes.bool,
	styleProp: PropTypes.object,
	conditionalClassNames: PropTypes.string,
	classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
	return {
		touchDevice: state.appState.userTouchDetected,
	}
}

export default withStyles(styles)(connect(mapStateToProps)(UiSelect))
