import { isInteger, isString } from 'lodash'
import axios from 'axios'

import e_Cardinality from '@appfarm/common/enums/e_Cardinality'
import e_FileUploadSourceType from '@appfarm/common/enums/e_FileUploadSourceType'
import { parseContentDisposition } from '@appfarm/common/utils/contentDisposition'

import { requestFileUpload, runActionNodeOnServer } from '#modules/afClientApi'
import imageResizer from '#utils/imageResizer'

import createFileInput from './createFileInput'
import p_runFileUpload from './p_runFileUpload'
import getMimeTypesFromAcceptedTypes from './utils'

const p_uploadFile = async ({
	actionNode,
	contextData,
	appController,
	actionNodeRunner,
	actionNodeLogger,
}) => {
	/**
	 * Sanity checks
	 */
	if (!actionNode.dataSourceId) throw new Error('Could not upload file - no target datasource')

	const dataSource = appController.getDataSource(actionNode.dataSourceId)
	if (!dataSource) throw new Error('Unable to find target datasource for upload')
	if (!dataSource.isFileObjectClass) throw new Error('Cannot upload file into non-file ObjectClass')

	if (
		dataSource.cardinality === e_Cardinality.ONE &&
		dataSource.getAllObjects().length &&
		!actionNode.replaceObject
	)
		throw new Error('Cannot upload file into datasource with cardinality one - object already exist')

	const MAX_SELECTABLE_FILES =
		dataSource.cardinality === e_Cardinality.ONE ? 1 : actionNode.maxSelectableFiles || 1

	/**
	 * Check if files is selected
	 */
	const eventContext = actionNodeRunner.getEventContext()
	let selectedFiles
	let originalFileName

	if (MAX_SELECTABLE_FILES <= 1 && actionNode.customFileName) {
		originalFileName = appController.getDataFromDataValue(actionNode.customFileName, contextData)
	}

	/**
	 * Get files
	 */

	// URL
	if (actionNode.sourceType === e_FileUploadSourceType.URL) {
		if (actionNode.runOnServer) {
			actionNodeLogger.debug('Running request on server')
			const res = await new Promise((resolve, reject) => {
				const rootActionId = actionNodeRunner.getRootAction().id

				let overrideTimeout = 30000 + 2000
				if (isInteger(actionNode.timeout)) {
					overrideTimeout = actionNode.timeout + 2000
				}

				runActionNodeOnServer(rootActionId, actionNode.id, { contextData }, overrideTimeout)
					.then(({ fileData, originalFileName, mimeType }) => {
						const file = new File([fileData], originalFileName, { type: mimeType })
						resolve({ selectedFiles: [file], originalFileName })
					})
					.catch(reject)
			})
			selectedFiles = res.selectedFiles
			originalFileName = res.originalFileName
		} else {
			const headers = actionNode.requestHeaders?.length
				? actionNode.requestHeaders.reduce((headers, headerMeta) => {
					headers[headerMeta.name] = appController.getDataFromDataValue(headerMeta.value, contextData)
					return headers
				}, {})
				: undefined

			// Create config for axios
			const config = {
				url: appController.getDataFromDataValue(actionNode.url, contextData),
				method: 'get',
				responseType: 'blob',
			}
			if (headers) config.headers = headers

			if (!isString(config.url)) throw new Error('URL must be a string')

			actionNodeLogger.debug('Getting content from config: ' + config.url)

			const res = await new Promise((resolve, reject) => {
				axios
					.request(config)
					.then((response) => {
						const file = originalFileName
							? new File([response.data], originalFileName, { type: response.data.type })
							: response.data

						if (!originalFileName && response.headers['content-disposition']) {
							try {
								const parsed = parseContentDisposition(response.headers['content-disposition'])
								if (parsed.parameters.filename) {
									originalFileName = parsed.parameters.filename
								}
							} catch {
								// No-op
							}
						}

						resolve({ selectedFiles: [file], originalFileName })
					})
					.catch(reject)
			})

			selectedFiles = res.selectedFiles
			originalFileName = res.originalFileName
		}
	} else if (actionNode.sourceType === e_FileUploadSourceType.CUSTOM) {
		// Get file from whatever

		const fileContent = appController.getDataFromDataValue(actionNode.customContent, contextData, {
			ignoreReturnDatatypeCheck: true,
		})
		const fileName = originalFileName || 'default_filename'
		const mimeType =
			appController.getDataFromDataValue(actionNode.customMimeType, contextData) || 'text/plain'

		if (isString(fileContent)) {
			// String
			selectedFiles = [new File([fileContent], fileName, { type: mimeType })]
		} else if (fileContent instanceof File) {
			// File Object can be used directly - Filename and type are given
			selectedFiles = [fileContent]
		} else if (fileContent instanceof Blob) {
			// Create a file object from the blob
			selectedFiles = [new File([fileContent], fileName, { type: mimeType })]
		} else {
			throw new Error('Unsupported content for custom file')
		}
	} else {
		// DEFAULT
		if (eventContext && eventContext.droppedFiles) {
			selectedFiles = eventContext.droppedFiles
			const acceptedMimeTypes = getMimeTypesFromAcceptedTypes(actionNode.acceptedTypes)
			if (actionNode.acceptedTypes?.length) {
				selectedFiles = selectedFiles.filter((file) =>
					acceptedMimeTypes.some((type) => file.type.includes(type.replace('*', '')))
				)
			}
			selectedFiles = selectedFiles.slice(0, MAX_SELECTABLE_FILES)
		} else {
			selectedFiles = await new Promise((resolve, reject) => {
				const fileInput = createFileInput(
					actionNode.acceptedTypes,
					actionNode.useCamera,
					MAX_SELECTABLE_FILES !== 1
				)

				const previousOnfocusEvent = document.body.onfocus
				const cancelFileUpload = (actionId) => {
					// actionId is provided if run from eventActions - should then only cancel if same action is tried to run again.
					if (actionId && actionId !== actionNodeRunner.getRootAction().id) return // no need to cancel

					document.body.onfocus = previousOnfocusEvent
					appController.clearCancelFileuploadFunction()
					reject(new Error('File Import aborted'))
				}

				let rejectTimer = null
				appController.setCancelFileuploadFunction(cancelFileUpload)

				fileInput.onchange = (event) => {
					clearTimeout(rejectTimer)
					document.body.onfocus = previousOnfocusEvent
					appController.clearCancelFileuploadFunction()

					const fileArray = []
					for (let i = 0, l = fileInput.files.length; i < l; i++) {
						fileArray.push(fileInput.files[i])
					}

					resolve(fileArray)
				}

				document.body.onfocus = () => {
					rejectTimer = setTimeout(() => cancelFileUpload(), 500)
				}

				fileInput.click()
			})
		}

		if (originalFileName) {
			const originalFile = selectedFiles[0]
			selectedFiles = [new File([originalFile], originalFileName, { type: originalFile.type })]
		}
	}

	if (selectedFiles.length > MAX_SELECTABLE_FILES)
		throw new Error('Too many files selected. Max ' + MAX_SELECTABLE_FILES)

	if (!selectedFiles.length) return actionNodeLogger.debug('Got no files to upload')

	/**
	 * Process Image files
	 */
	const processedFiles = await Promise.all(
		selectedFiles.map(async (file) => {
			let fileForReturn = file
			let isProcessed = false

			if (file.type.startsWith('image/')) {
				let compression = 1 //  10 / 10

				if (actionNode.useCompression && isInteger(actionNode.compression)) {
					compression = actionNode.compression / 10
				}

				const maxImageSize = isInteger(actionNode.maxImageSize) ? actionNode.maxImageSize : null

				if (maxImageSize || (actionNode.useCompression && compression < 1)) {
					actionNodeLogger.debug('Run image processor')
					fileForReturn = await imageResizer(file, maxImageSize, compression)
					isProcessed = true
				}
			}

			return {
				file: file,
				fileOrBlob: fileForReturn,
				isProcessed,
			}
		})
	)

	/**
	 * Define upload functions
	 */

	const rootActionId = actionNodeRunner.getRootAction().id
	let insertOrUploadSingleFile

	if (dataSource.local) {
		insertOrUploadSingleFile = ({ file, fileOrBlob }) =>
			new Promise((resolve, reject) => {
				let newFileObject = dataSource.generateNewObject(actionNode.defaultValues, contextData)
				newFileObject = {
					...newFileObject,
					__file: fileOrBlob,
					__actionNodeId: actionNode.id, // Need this for upload
					__actionId: rootActionId, // Need this for upload
					originalFileName: file.name || originalFileName || newFileObject._id,
					__mimeType: fileOrBlob.type,
					__fileSize: fileOrBlob.size,
					__uploadComplete: false,
					__uploadProgress: 0,
					__isPublic: actionNode.isPublic,
					__fileContentLink: URL.createObjectURL(fileOrBlob),
				}

				dataSource
					.p_insertFileObject(newFileObject, contextData)
					.then(() => resolve(newFileObject))
					.catch(reject)
			})
	} else {
		insertOrUploadSingleFile = ({ file, fileOrBlob, isProcessed }) =>
			new Promise((resolve, reject) => {
				const payload = isProcessed ? fileOrBlob : file
				const defaultValues = dataSource.generateShellObject(actionNode.defaultValues, contextData)

				requestFileUpload({
					actionId: rootActionId,
					actionNodeId: actionNode.id,
					data: defaultValues,
				})
					.then((uploadPermissionResponse) => {
						return p_runFileUpload({
							fileOrBlob: payload,
							uploadPermissionResponse,
							fileName: file.name || originalFileName,
							defaultValues,
							dataSource,
							logger: actionNodeLogger,
						})
					})
					.then((fileObject) => resolve(fileObject))
					.catch(reject)
			})
	}

	/**
	 * Run actual upload
	 */
	const fileObjects = await Promise.all(processedFiles.map(insertOrUploadSingleFile))

	if (dataSource.cardinality === e_Cardinality.MANY && actionNode.setSelected) {
		const fileIds = fileObjects.map((item) => item._id)
		actionNodeLogger.debug('Selecting file objects')
		await dataSource.p_filteredSelection({ staticFilter: { _id: { $in: fileIds } } })
	}

	actionNodeLogger.debug('Upload OK!')
	actionNodeLogger.table(fileObjects, null, { dataSourceId: dataSource.id })
}

export default p_uploadFile
