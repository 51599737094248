import * as types from './actionTypes'

// Declare which app to be active
export const setActiveApp = (appId) => {
	return {
		type: types.SET_ACTIVE_APP,
		payload: { appId },
	}
}

// Used by AppLoader as part of the sequence of loding an app
export const resetStateForAppChange = () => ({
	type: types.RESET_STATE_BEFORE_APP_CHANGE,
})

export const forceRetryAppLoad = () => ({
	type: types.FORCE_RETRY_APP_LOAD,
	payload: Date.now(),
})

/******************************************************************************
 *
 * Metadata Actions
 *
 *****************************************************************************/

export const setGlobalSettings = (globalSettings) => {
	return {
		type: types.SET_GLOBAL_SETTINGS,
		payload: globalSettings,
	}
}

export const setAppList = (appList) => ({
	type: types.SET_APP_LIST,
	payload: appList,
})
/******************************************************************************
 *
 * Global metadata
 *
 *****************************************************************************/
export const setLatestGlobalChecksums = (checksums) => ({
	type: types.SET_LATEST_GLOBAL_CHECKSUMS,
	payload: checksums,
})

export const setCurrentDeployment = (deployment) => ({
	type: types.SET_CURRENT_DEPLOYMENT,
	payload: deployment,
})

/******************************************************************************
 *
 * Single App stuff
 *
 *****************************************************************************/

export const setLatestChecksums = (checksums) => ({
	type: types.SET_LATEST_CHECKSUMS,
	payload: checksums,
})

export const setWantedChecksums = (checksums) => ({
	type: types.SET_WANTED_CHECKSUMS,
	payload: checksums,
})

export const setApp = ({ app, checksum }) => ({
	type: types.SET_APP,
	payload: { app, checksum },
})

export const setDataModel = ({ dataSources, objectClasses, enumeratedTypes, languageId }) => ({
	type: types.SET_DATA_MODEL,
	payload: {
		dataSources,
		objectClasses,
		enumeratedTypes,
		languageId,
	},
})

export const setLayout = ({ views, checksum, languageId }) => ({
	type: types.SET_LAYOUT,
	payload: { views, checksum, languageId },
})

export const setActions = ({ actions, checksum, languageId }) => ({
	type: types.SET_ACTIONS,
	payload: { actions, checksum, languageId },
})

export const setFunctionsChecksum = (checksum) => ({
	type: types.SET_FUNCTIONS_CHECKSUM,
	payload: { checksum },
})

export const setStylesheetChecksum = (checksum) => ({
	type: types.SET_STYLESHEET_CHECKSUM,
	payload: { checksum },
})

/******************************************************************************
 *
 * Language and translations
 *
 *****************************************************************************/
export const setActiveTranslation = (
	languageId = null,
	appTranslation = null,
	enumsTranslation = null,
	ocTranslation = null
) => ({
	type: types.SET_ACTIVE_TRANSLATION,
	payload: { languageId, appTranslation, enumsTranslation, ocTranslation },
})

/******************************************************************************
 *
 * Stuff below will be reviewed in conjunction with new loader
 *
 *****************************************************************************/

export const replaceTheme = (theme) => ({
	type: types.REPLACE_SINGLE_THEME,
	payload: {
		id: theme.id,
		theme,
	},
})
