import axios from 'axios'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as Sentry from '@sentry/react'

import retry from '@appfarm/common/utils/retry'

import { setLayout } from '#actions/metadataActions'
import translateViews from '#utils/translationUtils/translateViews'

/******************************************************************************
 *
 * Layout and Layout translation
 *
 *****************************************************************************/

const LayoutLoader = ({ activeAppId, activeLanguageId, appTranslation }) => {
	const dispatch = useDispatch()

	const [originalMetadata, setOriginalMetadata] = useState(null)

	const layoutChecksum = useSelector((state) => state.metaData.wantedChecksums.layout)

	// Reset local state on app change
	useEffect(() => {
		setOriginalMetadata(null)
	}, [activeAppId])

	// Translate and apply to satte
	useEffect(() => {
		if (!originalMetadata) return

		if (appTranslation) {
			const translatedViews = translateViews(originalMetadata.views, appTranslation)

			dispatch(
				setLayout({
					...originalMetadata,
					views: translatedViews,
					languageId: activeLanguageId,
				})
			)
		} else {
			dispatch(setLayout(originalMetadata))
		}
	}, [originalMetadata, appTranslation])

	useEffect(() => {
		if (!layoutChecksum) return

		const controller = new AbortController()

		// Note: Long timeout as transfer time is impacted by model size
		// If only layout fails to load, the app will not render (On App Load will execute, On App Loaded will not)
		retry(
			(signal) =>
				axios.get(`/api/v1/apps/${activeAppId}/metadata/layout?v=${layoutChecksum}`, {
					signal,
					timeout: 60000,
				}),
			{
				maxTries: 3,
				retryInterval: 1000,
				signal: controller.signal,
				timeout: 65000,
			}
		)
			.then((result) => {
				if (controller.signal?.aborted) return
				setOriginalMetadata(result.data)
			})
			.catch((err) => {
				if (axios.isCancel(err)) return
				Sentry.captureMessage('Failed to fetch app layout', { extra: { err } })
			})

		return () => controller.abort()
	}, [activeAppId, layoutChecksum])

	return null
}

export default LayoutLoader
