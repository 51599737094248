import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(() => ({
	outerContainer: {
		height: '100%',
		display: 'block',
		margin: '0 auto',
	},
	container: {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		minHeight: 460,
		flexDirection: 'column',
		position: 'relative',
	},
	progress: {
		color: '#bdbdbd',
	},
}))

const LoaderAnimation = ({ children, duration }: React.PropsWithChildren<{ duration?: number }>) => {
	const classes = useStyles()

	return (
		<div className={classes.outerContainer}>
			<div className={classes.container}>
				<CircularProgress className={classes.progress} />
				{ children }
			</div>
		</div>
	)
}

export default LoaderAnimation
