import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'

import { resetConfirmDialog } from '#actions/appStateActions'

const styles = {
	dialogPaper: {
		minWidth: 280,
	},
}

const ConfirmDialog = ({
	dialogOpen,
	headerText,
	bodyText,
	confirmButtonText,
	cancelButtonText,
	dismissOnClickOutside,
	onConfirmFunction,
	onCancelFunction,
	classes,
}) => {
	const handleCloseDialog = (_, reason) => {
		if (!dismissOnClickOutside && reason === 'backdropClick') return
		onCancelFunction()
	}

	const dispatch = useDispatch()

	useEffect(() => {
		return () => dispatch(resetConfirmDialog())
	}, [])

	return (
		<Dialog
			open={dialogOpen}
			disableEscapeKeyDown={!dismissOnClickOutside}
			onClose={handleCloseDialog}
			classes={{ paper: classes.dialogPaper }}
		>
			{ headerText && <DialogTitle>{ headerText }</DialogTitle> }
			{ bodyText && (
				<DialogContent>
					<DialogContentText component="div">{ bodyText }</DialogContentText>
				</DialogContent>
			) }
			<DialogActions>
				{ cancelButtonText && (
					<Button onClick={onCancelFunction} color="primary" variant="text">
						{ cancelButtonText }
					</Button>
				) }
				{ confirmButtonText && (
					<Button onClick={onConfirmFunction} color="primary" variant="text">
						{ confirmButtonText }
					</Button>
				) }
			</DialogActions>
		</Dialog>
	)
}

ConfirmDialog.propTypes = {
	dialogOpen: PropTypes.bool,
	headerText: PropTypes.string,
	bodyText: PropTypes.string,
	confirmButtonText: PropTypes.string,
	cancelButtonText: PropTypes.string,
	dismissOnClickOutside: PropTypes.bool,
	onConfirmFunction: PropTypes.func,
	onCancelFunction: PropTypes.func,
	classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
	dialogOpen: state.appState.confirmDialogOpen,
	headerText: state.appState.confirmDialogHeader,
	bodyText: state.appState.confirmDialogBody,
	confirmButtonText: state.appState.confirmDialogConfirmButton,
	cancelButtonText: state.appState.confirmDialogCancelButton,
	dismissOnClickOutside: state.appState.confirmDialogDismissOnClickOutside,
	onConfirmFunction: state.appState.confirmDialogConfirmCallback,
	onCancelFunction: state.appState.confirmDialogCancelCallback,
})

export default withStyles(styles)(connect(mapStateToProps)(ConfirmDialog))
