import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Router, Switch, Route } from 'react-router-dom'
import loadable from '@loadable/component'

import LuxonUtils from '@date-io/luxon'
import { Settings as luxonSettings } from 'luxon'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import { MuiThemeProvider, createTheme as createMuiTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import {
	getDevToolsEnabled,
	isInDevelopMode,
	getLoadedApp,
	isNoAccessError,
} from '#selectors/metadataSelectors'
import { setUserTouchDetected } from '#actions/appStateActions'
import builtInThemeDefinition, { defaultThemeId } from '@appfarm/common/builtins/builtInThemeDefinition'
import createTheme from '#utils/createTheme'
import AppListPage from './appList/AppListPage'
import ActiveAppLoader from './ActiveAppLoader'
import DeployNotifier from './DeployNotifier'
import UpgradeDialog from './UpgradeDialog'
import ConnectionStateActor from './ConnectionStateActor'
import RuntimeStateProvider from './RuntimeStateProvider'
import PrivateRoute from './authComponents/PrimaryLogin/PrivateRoute'
import MaintenanceMode from './MaintenanceMode'
import LoadableUserAccountDialog from './userAccount/LoadableUserAccountDialog'
import LoadableProfileImageEdit from './profileImageEdit/LoadableProfileImageEdit'
import ConfirmDialog from './ConfirmDialog'
import LoadableScanBarcodeDialog from './scanBarcodeDialog/LoadableScanBarcodeDialog'
import AppSnackbar from './AppSnackbar'
import LoadableUnsplashDialog from './unsplashDialog/LoadableUnsplashDialog'
import NullComponent from './NullComponent'

// Resource Loaders
import CssLoader from './ClientLoader/CssLoader'
import ThemeStylesheetLoader from './ClientLoader/ThemeStylesheetLoader'
import ClientLoader from './ClientLoader'

import accountLocalStorageHandler from '../modules/accountLocalStorageHandler'
import sessionStorageHandler from '../modules/sessionStorageHandler'

import browserHistory from './browserHistory'

import SecondaryLogin from './authComponents/SecondaryLogin/SecondaryLogin'
import PrimaryLoginDialog from './authComponents/PrimaryLogin/PrimaryLoginDialog'

const LoadableDevTools = loadable(() => import('./DevTools/DevTools'), {
	fallback: <NullComponent />,
})

const LOG_ENABLED = window.AF_PARAMS && window.AF_PARAMS.enableClientLog

const AppfarmClient = (props) => {
	useEffect(() => {
		// Detect user touch to set appState.userTouchDetected
		const onUserTouch = () => {
			props.setUserTouchDetected()
			window.removeEventListener('touchstart', onUserTouch, false)
		}

		window.addEventListener('touchstart', onUserTouch, false)

		return () => {
			window.removeEventListener('touchstart', onUserTouch, false)
		}
	}, [])

	useEffect(() => {
		if (props.shouldClearStoragesForUser) {
			accountLocalStorageHandler.clearValues()
			sessionStorageHandler.clearValues()
		}
	}, [props.shouldClearStoragesForUser])

	useEffect(() => {
		if (!props.configStateComplete) {
			return
		}

		if (!props.isLoggedIn || props.isAnonymous) {
			const paths = window.location.pathname.split('/')
			if (props.defaultAppReadableId && window.location.pathname.length <= 1) {
				window.location.href = '/' + props.defaultAppReadableId
			} else if (
				props.loginAppReadableId &&
				(window.location.pathname.length <= 1 ||
					(props.noAppAccess &&
						paths.length > 1 &&
						paths[1] !== props.loginAppReadableId &&
						paths[1] !== 'afa'))
			) {
				let href = '/' + props.loginAppReadableId
				if (window.location.pathname.length > 1)
					href += '?af_path=' + window.location.pathname + window.location.search
				window.location.href = href
			}
		}
	}, [props.configStateComplete, props.noAppAccess])

	const { enableDeployNotifier, maintenanceModeEnabled, configStateComplete, muiTheme, isAnonymous } = props

	if (!configStateComplete) return null

	// Prevent showing login screen before redirect
	// TODO: Is there an easier way to express this if-statement?
	if (!props.isLoggedIn || props.isAnonymous) {
		const paths = window.location.pathname.split('/')
		if (props.defaultAppReadableId && window.location.pathname.length <= 1) return null
		else if (
			props.loginAppReadableId &&
			(window.location.pathname.length <= 1 ||
				(props.noAppAccess &&
					paths.length > 1 &&
					paths[1] !== props.loginAppReadableId &&
					paths[1] !== 'afa'))
		)
			return null
	}

	if (maintenanceModeEnabled)
		return (
			<MuiThemeProvider theme={muiTheme}>
				<CssBaseline />
				<MaintenanceMode />
			</MuiThemeProvider>
		)

	return (
		<MuiThemeProvider theme={muiTheme}>
			<MuiPickersUtilsProvider utils={LuxonUtils} locale={props.locale}>
				<CssBaseline />
				<CssLoader cssUrls={props.cssUrls} />
				<ClientLoader key={props.forceRetryLoadTimestamp} />
				<ThemeStylesheetLoader theme={muiTheme} />
				<Router history={browserHistory}>
					<>
						<Switch>
							<Route path="/afa" component={SecondaryLogin} />
							<PrivateRoute path="/" exact component={AppListPage} />
							<PrivateRoute path="/:appId/:view?/:subPath?" component={ActiveAppLoader} />
						</Switch>
						{ enableDeployNotifier && <DeployNotifier /> }
						<UpgradeDialog />
						<ConnectionStateActor />
						<RuntimeStateProvider />
						<LoadableUserAccountDialog />
						<LoadableProfileImageEdit />
						<ConfirmDialog />
						<LoadableScanBarcodeDialog />
						<LoadableUnsplashDialog />
						<AppSnackbar />
						{ LOG_ENABLED && <LoadableDevTools /> }
						{ isAnonymous && <PrimaryLoginDialog /> }
					</>
				</Router>
			</MuiPickersUtilsProvider>
		</MuiThemeProvider>
	)
}

AppfarmClient.propTypes = {
	cssUrls: PropTypes.array,
	muiTheme: PropTypes.object,
	enableDeployNotifier: PropTypes.bool,
	maintenanceModeEnabled: PropTypes.bool,
	configStateComplete: PropTypes.bool.isRequired,
	isAnonymous: PropTypes.bool,
	isLoggedIn: PropTypes.bool,
	noAppAccess: PropTypes.bool,
	setUserTouchDetected: PropTypes.func.isRequired,
	shouldClearStoragesForUser: PropTypes.bool,
	defaultAppReadableId: PropTypes.string,
	loginAppReadableId: PropTypes.string,
	locale: PropTypes.string, // used to get correct locale for datepickers.
	forceRetryLoadTimestamp: PropTypes.number,
}

const makeMapStateToProps = () => {
	let muiTheme = null
	let cachedThemeHash
	let afTheme
	let cssUrls

	let fallbackTheme
	let cachedDevToolsEnabled

	const defaultAfTheme = builtInThemeDefinition.getDefaultTheme()

	const mapStateToProps = (state) => {
		const { initialUserStatusApplied, loginThemeData, isLoggedIn, isAnonymous } = state.authState

		const devToolsEnabled = LOG_ENABLED && getDevToolsEnabled(state)

		const themeParams = devToolsEnabled ? { devToolsEnabled: true } : undefined

		const showLogin =
			!isLoggedIn || (isAnonymous && (isNoAccessError(state) || window.location.pathname.length <= 1))

		const setMuiThemeFromLoginThemeData = () => {
			if (!loginThemeData) return

			if (loginThemeData.__hash !== cachedThemeHash || cachedDevToolsEnabled !== devToolsEnabled) {
				cachedThemeHash = loginThemeData.__hash
				muiTheme = null
				cssUrls = loginThemeData.cssUrls
			}

			if (!muiTheme) muiTheme = createMuiTheme(createTheme(loginThemeData, themeParams))
		}

		if (showLogin && loginThemeData) {
			// loginpage
			setMuiThemeFromLoginThemeData()
		} else if (state.appState.appIsActive) {
			// active app
			const app = getLoadedApp(state)
			const currentThemeId = state.appState.themeIdOverride || app.themeId || defaultThemeId
			afTheme = state.metaData.themes[currentThemeId]

			if (afTheme) {
				if (afTheme.__hash !== cachedThemeHash || cachedDevToolsEnabled !== devToolsEnabled) {
					muiTheme = null
					cachedThemeHash = afTheme.__hash
					cssUrls = afTheme.cssUrls
				}

				if (!muiTheme) muiTheme = createMuiTheme(createTheme(afTheme, themeParams))
			}
		} else if (loginThemeData) {
			// appList
			setMuiThemeFromLoginThemeData()
		} else {
			// No login theme. Use default theme.
			muiTheme = null
			cssUrls = null
		}

		if (cachedDevToolsEnabled !== devToolsEnabled) {
			cachedDevToolsEnabled = devToolsEnabled
			fallbackTheme = createMuiTheme(createTheme(defaultAfTheme, themeParams))
		}

		const configStateComplete = initialUserStatusApplied

		const getHasAppAccess = () => {
			if (!window.location.pathname) return

			const paths = window.location.pathname.split('/')
			const app = paths.length > 1 && state.metaData?.allApps?.find((item) => paths[1] === item.readableId)

			if (!app) return

			return app.error && app.error.type === 'NoAccess'
		}

		return {
			cssUrls: cssUrls || defaultAfTheme.cssUrls,
			muiTheme: muiTheme || fallbackTheme,
			locale: luxonSettings.defaultLocale,
			enableDeployNotifier: !isInDevelopMode(state),
			maintenanceModeEnabled: state.authState.maintenanceModeEnabled,
			isAnonymous: isAnonymous,
			isLoggedIn: isLoggedIn,
			noAppAccess: getHasAppAccess(),
			defaultAppReadableId: state.authState.defaultAppReadableId,
			loginAppReadableId: state.authState.loginAppReadableId,
			configStateComplete: configStateComplete,
			shouldClearStoragesForUser:
				configStateComplete && state.authState.isAnonymous === false && state.authState.isLoggedIn === false,
			forceRetryLoadTimestamp: state.metaData.forceRetryLoadTimestamp ?? undefined,
		}
	}
	return mapStateToProps
}

const mapDispatchToProps = (dispatch) => {
	return {
		setUserTouchDetected: () => dispatch(setUserTouchDetected()),
	}
}

export default connect(makeMapStateToProps, mapDispatchToProps)(AppfarmClient)
