import * as Sentry from '@sentry/react'
import { getSentryReleaseId } from '@appfarm/common/utils/sentry'

const tracesSampleRate = 0.1
const profilesSampleRate = 0.1

const ignoredErrorMessagesPattern = /^(NS_BINDING_ABORTED|NetworkError|Network Error)/i

export default function initSentry() {
	const release = getSentryReleaseId(__TAG, __BRANCH, __COMMIT)
	const environment = (AF_PARAMS && AF_PARAMS.afEnvironment) || 'unknown'

	Sentry.init({
		dsn: 'https://e8a3c96cd50d6f779f3c454269a597ba@o114898.ingest.sentry.io/274054',
		release,
		environment,
		integrations: [
			Sentry.extraErrorDataIntegration({ captureErrorCause: true }),
			Sentry.browserTracingIntegration(),
			Sentry.browserProfilingIntegration(),
			Sentry.httpClientIntegration({
				// Capture requests with response codes 400-599. We obviously want to know about 5xx errors, but we
				// also want 4xx errors in case they're caused by a bug in our code rather than a user error.
				failedRequestStatusCodes: [[400, 599]],
			}),
			Sentry.reportingObserverIntegration(),
		],
		tracesSampleRate,
		profilesSampleRate,

		// Queue events while disconnected
		transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),

		// Attempt to differentiate between exceptions caused in user-defined code,
		// such as functions or Coded Components or by our Appfarm core code
		beforeSend(event) {
			if (event.message?.match(ignoredErrorMessagesPattern)) {
				return null
			}

			// Sentry may change its stacktrace parsing logic at any time, so err on
			// the side of caution and treat unparsable exceptions as our fault
			const frames = event.exception?.values?.[0]?.stacktrace?.frames
			if (frames?.length) {
				// Do not report events to Sentry if the exception occurred in code
				// fetched from /api/v1/apps/:id/metadata/functions (assumed user-defined)
				// TODO: Likely some user-caused exceptions may still slip through
				const lastFrame = frames[frames.length - 1]
				if (lastFrame?.filename?.includes('metadata/functions')) {
					return null
				}
			}
			return event
		},
	})

	Sentry.setTag('af.commit', __COMMIT)

	if (AF_PARAMS) {
		Sentry.setTag('af.solution.env', AF_PARAMS.afEnvironment)
		Sentry.setTag('af.solution.id', AF_PARAMS.solutionId)
		Sentry.setTag('af.solution.shortName', AF_PARAMS.shortName)

		if (AF_PARAMS.enableClientLog) {
			console.groupCollapsed('Initialized Sentry')
			console.log('Environment: %s', environment)
			console.log('Release: %s', release)
			console.groupEnd()
		}
	}
}
