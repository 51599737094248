import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
import { t } from '@lingui/macro'

const useStyles = makeStyles((theme) => ({
	container: {
		marginTop: 20, // ½ of spinner height
		maxWidth: '100vw',
		position: 'absolute',
		textAlign: 'center',
		top: '50%',
		width: 'max-content',
	},
	'@keyframes enter': {
		'0%': {
			opacity: 0,
			translate: '0 -16px',
		},
		'100%': {
			opacity: 1,
			translate: '0 0',
		},
	},
	content: {
		animation: '$enter 0.15s cubic-bezier(0,0,.2,1)',
		transformOrigin: 'top center',
	},
}))

interface SlowLoadingProps {
	isRetrying: boolean
	onRetry: (...args: any[]) => unknown
}

export default function SlowLoading({ onRetry, isRetrying }: SlowLoadingProps) {
	const classes = useStyles()

	return (
		<div className={classes.container}>
			<div className={classes.content}>
				<p>{ t`This is taking a bit longer than expected` }</p>
				<Button variant="contained" color="primary" onClick={onRetry} disabled={isRetrying}>
					{ isRetrying ? t`Reloading...` : t`Reload` }
				</Button>
			</div>
		</div>
	)
}
