import e_FileMediaType from '@appfarm/common/enums/e_FileMediaType'

const spreadSheetMimeTypes = [
	'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/vnd.oasis.opendocument.spreadsheet',
	'application/x-iwork-numbers-sffnumbers',
]

const docMimeTypes = [
	'application/rtf',
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/vnd.oasis.opendocument.text',
	'application/x-iwork-pages-sffpages',
]

const presentationMimeTypes = [
	'application/vnd.ms-powerpoint',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	'application/vnd.oasis.opendocument.presentation',
	'application/x-iwork-keynote-sffkey',
]

const getMimeTypesFromAcceptedTypes = (acceptedTypes = []) => {
	return acceptedTypes.reduce((mimeTypes, appfarmMediaType) => {
		switch (appfarmMediaType) {
			case e_FileMediaType.CUSTOM_SPREADSHEET:
				return mimeTypes.concat(spreadSheetMimeTypes)
			case e_FileMediaType.CUSTOM_DOC:
				return mimeTypes.concat(docMimeTypes)
			case e_FileMediaType.CUSTOM_PRESENTATION:
				return mimeTypes.concat(presentationMimeTypes)
			default:
				return mimeTypes.concat(appfarmMediaType)
		}
	}, [])
}

export default getMimeTypesFromAcceptedTypes
