import React, { useState, useEffect, useCallback, useRef, useLayoutEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Drawer from '@material-ui/core/Drawer'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'

import { appController } from '#store/dataConnector'
import { e_DrawerType, e_DrawerPosition } from '@appfarm/common/enums/e_PropertyTypes'
import e_ScreenSize from '@appfarm/common/enums/e_ScreenSize'

import { closeDrawer } from '#actions/runtimeStateActions'
import { getIsReadyForOnViewLoad } from '#selectors/appStateSelectors'

import UiKeyboardShortcuts from '../UiKeyboardShortcuts'
import UiComponent from './UiComponent'
import UiAppBar from './UiAppBar'

const useStyles = makeStyles((theme) => ({
	innerTempContainer: {
		height: '100%',
		width: '100%',
		flex: 1,
		color: theme.palette.text.primary,
	},
	innerPersistantContainer: {
		flex: 1,
		color: theme.palette.text.primary,
		'&$horizontal': {
			maxWidth: '100% !important',
		},
		'&$vertical': {
			maxHeight: '100% !important',
		},
	},
	tempPaper: {
		backgroundColor: theme.palette.background.paper,

		'$horizontal > &$defaultSize': {
			[theme.breakpoints.up('sm', false)]: {
				width: 256,
				maxWidth: '100%',
			},
			[theme.breakpoints.down('xs', false)]: {
				width: 'calc(100% - 56px)',
			},
		},
		'$vertical > &$defaultSize': {
			minHeight: 24,
		},

		'$horizontal > &': {
			height: '100%',
			maxHeight: 'unset',
		},
		'$vertical > &': {
			width: '100%',
			maxWidth: 'unset',
		},
	},
	persistantRoot: {
		position: 'absolute',

		transition: theme.transitions.create(['width', 'height'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),

		'$isOpen > &': {
			transition: theme.transitions.create(['height', 'width'], {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},

		'$horizontal > &': {
			height: '100% !important',
			maxHeight: '100%',

			'&$defaultSize': {
				[theme.breakpoints.up('sm', false)]: {
					width: 256,
					maxWidth: '100%',
				},
				[theme.breakpoints.down('xs', false)]: {
					width: 'calc(100% - 56px)',
				},
			},
		},
		'$vertical > &': {
			width: '100% !important',
			maxWidth: '100%',

			display: 'flex',
			flexDirection: 'column',

			'&$defaultSize': {
				minHeight: 24,
			},
		},
	},
	isOpen: {},
	horizontal: {},
	vertical: {},
	horizontalPersistantPaper: {
		position: 'relative',
		backgroundColor: theme.palette.background.paper,
		overflow: 'hidden',
		height: '100%',
		width: '100%',
	},
	verticalPersistantPaper: {
		position: 'relative',
		backgroundColor: theme.palette.background.paper,
		overflow: 'hidden',
		width: '100%',
		height: '100%',
		flex: '1',
	},
	disableBorder: {
		border: 'none',
	},
	persistantOuter: {
		transition: theme.transitions.create(['width', 'height'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),

		'&$isOpen': {
			transition: theme.transitions.create(['height', 'width'], {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},

		'&$vertical': {
			width: '100%',
			height: 0,
		},
		'&$horizontal': {
			height: '100%',
			width: 0,
		},
		'&$vertical:not($isOpen)': {
			minHeight: 0,
		},
		'&$horizontal:not($isOpen)': {
			minWidth: 0,
		},
	},
	defaultSize: {},
	outerContainer: {
		width: (props) => props.outerStyleProp?.width,
		height: (props) => props.outerStyleProp?.height,
	},
}))

const getDrawerVariant = (drawerType, screenSize) => {
	if (!drawerType || drawerType === e_DrawerType.RESPONSIVE)
		return [e_ScreenSize.EXTRA_SMALL, e_ScreenSize.SMALL].includes(screenSize)
			? e_DrawerType.TEMPORARY
			: e_DrawerType.PERSISTENT

	return drawerType
}

const UiDrawer = (props) => {
	const [drawerIsOpen, setDrawerIsOpen] = useState(
		props.isMainDrawer ? appController.getAppVariablesDataSource().getDrawerState() : false
	)

	//set directly readyForViewRender since we don't have any onViewLoad in the drawer
	const readyForViewRender = useSelector(getIsReadyForOnViewLoad)

	const [screenSize, setScreenSize] = useState(
		appController.getAppVariablesDataSource().getClientScreenSize()
	)

	const [outerStyle, setOuterStyle] = useState()

	const isOpen = useSelector((state) => state.runtimeState.openDrawerIds.includes(props.view.id))
	const isTouchDevice = useSelector((state) => state.appState.userTouchDetected)

	useEffect(() => {
		if (!readyForViewRender) return

		if (drawerIsOpen && props.view.onViewLoaded) props.eventHandler(props.view.onViewLoaded)
	}, [drawerIsOpen, readyForViewRender])

	useEffect(() => {
		if (props.isMainDrawer) return

		setDrawerIsOpen(isOpen)
	}, [isOpen, props.isMainDrawer])

	useEffect(() => {
		if (!props.isMainDrawer) return

		const appVarDataSource = appController.getAppVariablesDataSource()
		setDrawerIsOpen(appVarDataSource.getDrawerState())

		const drawerListener = appVarDataSource.subscribeToDrawerState((isOpen) => {
			setDrawerIsOpen(isOpen)
		})

		return drawerListener
	}, [])

	useEffect(() => {
		const appVarDataSource = appController.getAppVariablesDataSource()
		setScreenSize(appVarDataSource.getClientScreenSize())

		const screenSizeListener = appVarDataSource.subscribeToClientScreenSize((size) => {
			setScreenSize(size)
		})

		return screenSizeListener
	}, [])

	const dispatch = useDispatch()

	// handleOpen does nothing (but is required on SwipeableDrawer),
	// as swipe from the screen edge is conflicting with browser navigation on some touch devices
	const handleOpen = useCallback(() => {}, [])

	const handleClose = useCallback(() => {
		setDrawerIsOpen(false)
		if (isOpen) {
			dispatch(closeDrawer(props.view.id))
		} else if (props.isMainDrawer) {
			const appVarDataSource = appController.getAppVariablesDataSource()
			appVarDataSource.setDrawerState(false)
		}
	}, [isOpen])

	const handleBackdropClick = useCallback((event) => {
		props.eventHandler(props.view.onBackdropClick, null, { eventType: 'onBackdropClick' }, event)
	}, [])

	const { drawerPosition = e_DrawerPosition.LEFT, drawerType = e_DrawerType.RESPONSIVE } = props.view

	const variant = getDrawerVariant(drawerType, screenSize)
	const isHorizontal = [e_DrawerPosition.LEFT, e_DrawerPosition.RIGHT].includes(drawerPosition)

	const drawerRef = useRef()

	let offsetSize

	if (props.view?.customSize && props.outerStyleProp)
		offsetSize = isHorizontal ? props.outerStyleProp?.width : props.outerStyleProp?.height
	else offsetSize = isHorizontal ? drawerRef.current?.offsetWidth : drawerRef.current?.offsetHeight

	const paperProps = useMemo(() => {
		if (variant !== e_DrawerType.TEMPORARY || !props.view?.roundedCornersRadius) return

		const style = {}

		if ([e_DrawerPosition.LEFT, e_DrawerPosition.BOTTOM].includes(drawerPosition))
			style.borderTopRightRadius = props.view.roundedCornersRadius

		if ([e_DrawerPosition.RIGHT, e_DrawerPosition.BOTTOM].includes(drawerPosition))
			style.borderTopLeftRadius = props.view.roundedCornersRadius

		if ([e_DrawerPosition.RIGHT, e_DrawerPosition.TOP].includes(drawerPosition))
			style.borderBottomLeftRadius = props.view.roundedCornersRadius

		if ([e_DrawerPosition.LEFT, e_DrawerPosition.TOP].includes(drawerPosition))
			style.borderBottomRightRadius = props.view.roundedCornersRadius

		return { style }
	}, [variant, props.view])

	useLayoutEffect(() => {
		if (variant === e_DrawerType.TEMPORARY) return

		if (props.isMainDrawer ? drawerIsOpen : isOpen && drawerIsOpen) {
			setOuterStyle(isHorizontal ? { width: offsetSize } : { height: offsetSize })
		} else if (!isOpen && !drawerIsOpen) {
			setOuterStyle(undefined)
		}
	}, [isOpen, drawerIsOpen, variant, offsetSize])

	const { view, styleProp, conditionalClassNames } = props

	const classes = useStyles(props)

	if (!view) return null

	const content = (
		<>
			{ view.appBar?.enabled && <UiAppBar component={view.appBar} /> }
			<div
				className={classNames(
					variant === e_DrawerType.TEMPORARY ? classes.innerTempContainer : classes.innerPersistantContainer,
					isHorizontal ? classes.horizontal : classes.vertical,
					'c' + view.id,
					conditionalClassNames
				)}
				style={styleProp}
				ref={drawerRef}
			>
				{ view.children.map((component) => (
					<UiComponent key={component.id} component={component} />
				)) }
			</div>
			{ drawerIsOpen &&
				props.keyboardEventHandlers?.map((keyboardEventHandler) => (
					<UiKeyboardShortcuts
						key={keyboardEventHandler.id}
						viewId={view.id}
						keyboardEventHandler={keyboardEventHandler}
						eventHandler={props.eventHandler}
					/>
				)) }
		</>
	)

	if (variant === e_DrawerType.TEMPORARY) {
		const DrawerComponent = isTouchDevice ? SwipeableDrawer : Drawer
		const onBackdropClickHandlers = view.onBackdropClick
			? {
				onBackdropClick: handleBackdropClick,
				onEscapeKeyDown: handleBackdropClick,
			}
			: {}
		const swipableProps = isTouchDevice ? { disableSwipeToOpen: true, onOpen: handleOpen } : {}

		return (
			<DrawerComponent
				open={!!(readyForViewRender && drawerIsOpen)}
				classes={{
					root: isHorizontal ? classes.horizontal : classes.vertical,
					paper: classNames(classes.tempPaper, classes.outerContainer, {
						[classes.defaultSize]: !view.customSize,
						['c' + view.id + '-o']: view.customSize,
					}),
				}}
				PaperProps={paperProps}
				onClose={handleClose}
				variant={variant}
				anchor={drawerPosition}
				{...onBackdropClickHandlers}
				{...swipableProps}
			>
				{ content }
			</DrawerComponent>
		)
	}

	return (
		<div
			className={classNames(classes.persistantOuter, isHorizontal ? classes.horizontal : classes.vertical, {
				[classes.isOpen]: drawerIsOpen,
			})}
			style={outerStyle}
		>
			<Drawer
				open={!!(readyForViewRender && drawerIsOpen)}
				classes={{
					root: classNames(classes.persistantRoot, classes.outerContainer, {
						[classes.defaultSize]: !view.customSize,
						['c' + view.id + '-o']: view.customSize,
					}),
					paper: classNames(
						isHorizontal ? classes.horizontalPersistantPaper : classes.verticalPersistantPaper,
						{ [classes.disableBorder]: view.disableBorder }
					),
				}}
				variant={variant}
				anchor={drawerPosition}
				ref={drawerRef}
			>
				{ content }
			</Drawer>
		</div>
	)
}

UiDrawer.propTypes = {
	view: PropTypes.object.isRequired,
	isMainDrawer: PropTypes.bool,
	keyboardEventHandlers: PropTypes.array,
	eventHandler: PropTypes.func.isRequired,
	styleProp: PropTypes.object,
	outerStyleProp: PropTypes.object,
	history: PropTypes.object.isRequired,
	conditionalClassNames: PropTypes.string,
}

export default React.memo(withRouter(UiDrawer))
