/* eslint-disable global-require */

export {
	REPLACE_DATA_IN_DATASOURCE,

	// Common data actions
	MODIFY_OBJECT,
	INSERT_OBJECT,
	INSERT_MULTIPLE_OBJECTS,
	DELETE_MULTIPLE_OBJECTS,
} from '@appfarm/common/constants/clientActionTypes'

export const REPLACE_SINGLE_THEME = 'THEME/REPLACE_SINGLE_THEME'
export const SET_ACTIVE_THEME = 'THEME/SET_ACTIVE_THEME'

export const VALIDATE_RESOURCE = 'VALIDATE_RESOURCE'

/******************************************************************************
 *
 * Metadata
 *
 *****************************************************************************/

export const SET_LATEST_GLOBAL_CHECKSUMS = 'SET_LATEST_GLOBAL_CHECKSUMS'

export const RESET_STATE_BEFORE_APP_CHANGE = 'RESET_STATE_BEFORE_APP_CHANGE'

export const FORCE_RETRY_APP_LOAD = 'FORCE_RETRY_APP_LOAD'

export const SET_GLOBAL_SETTINGS = 'SET_GLOBAL_SETTINGS'
export const SET_APP_LIST = 'SET_APP_LIST'
export const SET_LATEST_CHECKSUMS = 'SET_LATEST_CHECKSUMS'
export const SET_WANTED_CHECKSUMS = 'SET_WANTED_CHECKSUMS'
export const SET_APP = 'SET_APP'
export const SET_DATA_MODEL = 'SET_DATA_MODEL'
export const SET_ENUMERATED_TYPES = 'SET_ENUMERATED_TYPES'
export const SET_ACTIONS = 'SET_ACTIONS'
export const SET_LAYOUT = 'SET_LAYOUT'
export const SET_FUNCTIONS_CHECKSUM = 'SET_FUNCTIONS_CHECKSUM'
export const SET_STYLESHEET_CHECKSUM = 'SET_STYLESHEET_CHECKSUM'

export const SET_ACTIVE_TRANSLATION = 'SET_ACTIVE_TRANSLATION'

/**
 * Local action types
 */
export const SET_ACTIVE_APP = 'META/SET_ACTIVE_APP'
export const SET_APP_NOT_FOUND = 'META/SET_APP_NOT_FOUND'
export const SET_CURRENT_DEPLOYMENT = 'META/SET_CURRENT_DEPLOYMENT'

/**
 * Used after logout to clear sensitive data
 */
export const RESET_CLIENT = 'RESET_CLIENT'

/**
 * Auth
 */
export const SET_AUTH_SATE_VALUE = 'AUTH/SET_AUTH_STATE_VALUE'
export const SET_MULTIPLE_AUTH_SATE_VALUES = 'AUTH/SET_MULTIPLE_AUTH_SATE_VALUES'

/**
 * Runtime
 */
export const SET_RUNTIME_STATE_VALUE = 'RUNTIME/SET_RUNTIME_STATE_VALUE'
export const SET_RUNTIME_STATE_OBJECT_VALUE = 'RUNTIME/SET_RUNTIME_STATE_OBJECT_VALUE'
export const TOGGLE_RUNTIME_STATE_BOOLEAN_VALUE = 'RUNTIME/TOGGLE_RUNTIME_STATE_BOOLEAN_VALUE'
export const OPEN_DIALOG = 'RUNTIME/OPEN_DIALOG'
export const CLOSE_DIALOG = 'RUNTIME/CLOSE_DIALOG'
export const OPEN_DRAWER = 'RUNTIME/OPEN_DRAWER'
export const CLOSE_DRAWER = 'RUNTIME/CLOSE_DRAWER'
export const OPEN_POPOVER = 'RUNTIME/OPEN_POPOVER'
export const CLOSE_POPOVER = 'RUNTIME/CLOSE_POPOVER'
export const ADD_KEYBOARD_SHORTCUT = 'RUNTIME/ADD_KEYBOARD_SHORTCUT'
export const REMOVE_KEYBOARD_SHORTCUT = 'RUNTIME/REMOVE_KEYBOARD_SHORTCUT'

/**
 * AppState
 */
export const SET_APP_STATE_VALUE = 'SET_APP_STATE_VALUE'
export const SET_MULTIPLE_APP_STATE_VALUES = 'SET_MULTIPLE_APP_STATE_VALUES'

// FOR REVIEW
export const INVALIDATE_RESOURCE = 'INVALIDATE_RESOURCE'
