import isNil from 'lodash/isNil'
import e_AggregationFunction from '@appfarm/common/enums/e_AggregationFunction'
import { min, max, sum, average, median } from '#utils/mathFunctions'

const aggregateData = (dataArray, nodeName, aggergationFunction, skipNil) => {
	if (aggergationFunction === e_AggregationFunction.COUNT) return dataArray.length

	let cleanData = dataArray
	if (nodeName) cleanData = dataArray.map((item) => item[nodeName])

	if (skipNil) cleanData = cleanData.filter((value) => !isNil(value))

	if (cleanData && (cleanData.length === 0 || typeof cleanData[0] === 'number')) {
		switch (aggergationFunction) {
			case e_AggregationFunction.SUM:
				return sum(cleanData)
			case e_AggregationFunction.AVERAGE:
				return average(cleanData)
			case e_AggregationFunction.MEDIAN:
				return median(cleanData)
			case e_AggregationFunction.MIN:
				return min(cleanData)
			case e_AggregationFunction.MAX:
				return max(cleanData)
			default:
				throw new Error('Unsupported aggregation function')
		}
	} else {
		console.warn('AggergateData: Unable to aggregate data. Data: ', cleanData)
		return NaN
	}
}

export default aggregateData
